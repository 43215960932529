<template>
    <div class="pa-6">
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="title_heading mb-5">จองคิวรถพยาบาล</div>
        <div>
            <v-card class="card_line_oa card_all_register_open_notify">
                <v-form ref="form" @submit.prevent="saveData()">
                    <div class="title_sub_heading mb-2">ข้อมูลผู้รับบริการ</div>
                    <div class="card_detail_register_open_notify">
                        <div class="mb-2">ชื่อ-สกุล <span class="error--text">*</span></div>
                        <v-text-field class="input_line_oa" onpaste="return false" v-model="full_name"
                            :rules="[v => !!v || 'กรุณากรอกชื่อ-สกุล']" placeholder="กรุณากรอกชื่อ-สกุล" outlined
                            required></v-text-field>
                    </div>
                    <div class="card_detail_register_open_notify">
                        <div class="mb-2">เบอร์โทร <span class="error--text">*</span></div>
                        <v-text-field class="input_line_oa" type="tel" @keypress="isNumberOnly($event)"
                            onpaste="return false" maxlength="10" :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']" v-model="tel"
                            placeholder="กรุณากรอกเบอร์โทร" outlined required></v-text-field>
                    </div>
                    <div class="card_detail_register_open_notify">
                        <div class="mb-2">รับที่ <span
                                class="error--text">*</span><small>กรุณาข้อมูลสถานที่รถพยาบาลไปรับ</small></div>
                        <v-radio-group v-model="drop_off_check" row>
                            <v-radio label="ขึ้นรถที่โรงพยาบาลหนองป่าครั่ง" value="0"></v-radio>
                            <v-radio label="รับที่บ้าน" value="1"></v-radio>
                        </v-radio-group>
                    </div>
                    <div v-if="drop_off_check == '1'" class="card_detail_register_open_notify">
                        <div class="mb-2">ที่อยู่</div>
                        <v-text-field class="input_line_oa" onpaste="return false" outlined
                            v-model="pick_up_at"></v-text-field>
                    </div>
                    <div class="card_detail_register_open_notify">
                        <div class="mb-2">ท่านเป็นผู้ป่วยติดเตียงหรือไม่ ? <span
                                class="error--text">*</span><small>กรุณาข้อมูลสถานที่รถพยาบาลไปรับ</small></div>
                        <v-radio-group v-model="patient" row
                            @change="getCheckAmbulanceQueue(), service_data = null, service_data_be = null, status_check = false">
                            <v-radio label="ไม่ใช่" value="1"></v-radio>
                            <v-radio label="ใช่" value="0"></v-radio>
                        </v-radio-group>
                    </div>
                    <div class="title_sub_heading mb-2">ข้อมูลการไปส่ง</div>
                    <div class="card_detail_register_open_notify">
                        <div class="mb-2">วันที่รับบริการ <span class="error--text">*</span></div>
                        <v-menu ref="menu_birthday" v-model="register.menu_birthday" :close-on-content-click="false"
                            :return-value.sync="service_data" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field class="input_line_oa" v-model="service_data_be" v-bind="attrs"
                                    v-on="on" :rules="[v => !!v || 'กรุณาเลือกวัน / เดือน / ปี ที่ให้รถไปรับ']"
                                    :min="mindate" locale="th" placeholder="กรุณาเลือกวัน / เดือน / ปี ที่ให้รถไปรับ"
                                    append-icon="mdi-calendar-month-outline" outlined required>
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="service_data" no-title scrollable locale="th" :min="mindate"
                                :allowed-dates="allowedDates" :events="birthdayBE()">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="selectBirthday(), getRound()">
                                    ตกลง
                                </v-btn>
                                <v-btn text color="primary" @click="register.menu_birthday = false">
                                    ยกเลิก
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </div>
                    <div v-if="status_check === true" class="card_detail_register_open_notify">
                        <div class="mb-2">รอบ <span class="error--text">*</span></div>
                        <v-select :items="round_list" item-text="name_round" item-value="id" v-model="round_id"
                            :rules="[v => !!v || 'กรุณาเลือกรอบเวลาการไปรับ']" placeholder="กรุณาเลือกรอบเวลาการไปรับ"
                            outlined required></v-select>
                    </div>
                    <div v-if="status_check === true" class="card_detail_register_open_notify">
                        <div class="mb-2">จุดหมาย <span class="error--text">*</span></div>
                        <v-select :items="dropoffList" item-text="name_drop_off" item-value="id" v-model="drop_off"
                            :rules="[v => !!v || 'กรุณาเลือกสถานพยาบาลที่ต้องการให้รถไปส่ง']"
                            placeholder="กรุณาเลือกสถานพยาบาลที่ต้องการให้รถไปส่ง" outlined required></v-select>
                    </div>
                    <div v-if="status_check === true" class="card_detail_register_open_notify">
                        <div class="mb-2">ผู้ติดตาม จำนวน (คน)</div>
                        <v-text-field class="input_line_oa" type="tel" @keypress="isNumberOnly($event)" v-model="follower"
                            onpaste="return false" maxlength="2" placeholder="กรุณากรอกจำนวนผู้ติดตาม ( ถ้ามี )"
                            outlined></v-text-field>
                    </div>
                    <div v-if="status_check === true" class="card_detail_register_open_notify">
                        <div class="mb-2">หมายเหตุ</div>
                        <v-text-field class="input_line_oa" onpaste="return false" outlined v-model="note"></v-text-field>
                    </div>
                    <v-btn class="white--text button_register_open_notify" type="submit" height="43"
                        width="100%">คลิกเพื่อจองรถพยาบาล</v-btn>
                </v-form>
            </v-card>
        </div>

    </div>
</template>

<script>
import liff from '@line/liff'
import { isNumberOnly } from '@/helpers/numberformats.js'
import { monthList } from '@/helpers/dateformats.js'
import ambulancequeue from '@/api/line_oa/ambulancequeue/ambulancequeue.js'
import destination from '@/api/hospital/destination/destination.js'
export default {
    data() {
        return {
            loading_page: false,
            open_add_error: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            register: {
                token_line: null,
                hn: '',
                menu_birthday: false,
                birthday_be: '',
                birthday: '',
            },
            time_list: [
                { id: 1, time: 'รอบเช้า 7.00 น.' },
                { id: 2, time: 'รอบบ่าย 13.00 น.' },
            ],
            destination_list: [
                { id: 1, name: 'โรงพยาบาลนครพิงค์' },
                { id: 2, name: 'โรงพยาบาลสวนดอก' },
                { id: 3, name: 'โรงพยาบาลหนองป่าคลั่ง' },
            ],
            drop_off: null,
            drop_off_check: '0',
            patient: '1',
            service_date: [],
            status_check: false,
            service_data: null,
            service_data_be: null,
            round_list: [],
            full_name: null,
            tel: null,
            pick_up_at: null,
            round_id: null,
            follower: null,
            note: null,
            dropoffList: [],
            date: '',
            mindate: new Date().toISOString().substr(0, 10)
        }
    },
    methods: {
        allowedDates(date) {
            // Convert date to the format used in the closedDates array
            const formattedDate = date.substring(0, 10);

            // Check if the date is in the closedDates array
            return !this.service_date.includes(formattedDate);
        },
        async isNumberOnly(evt) {
            return isNumberOnly(evt)
        },
        birthdayBE() {
            if (this.service_data == '') {
                this.service_data_be = ''
            }
            if (this.service_data) {
                let date_arr = this.service_data.split('-')
                let year = parseInt(date_arr[0]) + 543
                let month = parseInt(date_arr[1])
                month = monthList().find((detail) => { return detail.id == month }).name_short
                this.service_data_be = `${date_arr[2]} ${month} ${year}`
            }
        },
        async selectBirthday() {
            this.$refs.menu_birthday.save(this.service_data)
            let date_arr = this.service_data.split('-')
            let year = parseInt(date_arr[0]) + 543
            let month = parseInt(date_arr[1])
            month = monthList().find((detail) => { return detail.id == month }).name_short
            this.service_data_be = `${date_arr[2]}/${month}/${year}`
            this.register.menu_birthday = false
        },
        async getDetailLineUser() {
            this.loading_page = true
            this.register = {
                token_line: null,
                hn: '',
                menu_birthday: false,
                birthday_be: '',
                birthday: '',
            }
            liff.init({
                liffId: process.env.VUE_APP_BASE_LINE_LIFFID,
            }).then(() => {
                if (liff.isLoggedIn()) {
                    liff.getProfile().then(profile => {
                        this.register.token_line = profile.userId
                        this.loading_page = false
                    })
                } else {
                    liff.login()
                }
            })
        },
        async getCheckAmbulanceQueue() {
            let data = {
                patient: this.patient
            }
            await ambulancequeue.getCheckAmbulanceQueue(data).then((response) => {
                if (response.data.successful === true) {
                    this.service_date = response.data.data
                    this.service_date = this.service_date.map(item =>
                        item.service_date
                    )
                }
            })
        },
        async getRound() {
            let data = {
                service_date: this.service_data,
                patient: this.patient
            }
            await ambulancequeue.getRound(data).then((response) => {
                if (response.data.successful === true) {
                    this.round_list = response.data.data
                    this.status_check = true;
                }
            })
        },
        async getDestination() {
            await destination.getDestination().then((response) => {
                if (response.data.successful === true) {
                    this.dropoffList = response.data.dropoffList
                }
            })
        },
        async saveData() {
            if (this.$refs.form.validate()) {
                let data = {
                    full_name: this.full_name,
                    tel: this.tel,
                    pick_up_at: this.drop_off_check == '1' ? this.pick_up_at : null,
                    patient: this.patient,
                    service_date: this.service_data,
                    around: this.round_id,
                    drop_off: this.drop_off,
                    follower: this.follower,
                    note: this.note,
                    token_line: this.register.token_line,
                }
                await ambulancequeue.saveData(data).then((response) => {
                    if (response.data.successful === true) {
                        this.alert_success = true;
                        this.alert_message_success = 'เพิ่มข้อมูลเรียบร้อย';
                        setTimeout(() => {
                            this.alert_success = false,
                            window.location.reload()
                        }, 3000)
                    }
                }).catch(err => {
                    alert(err);
                })
            }
        },
    },
    mounted() {
        this.getDetailLineUser()
        this.getCheckAmbulanceQueue()
        this.getDestination()
    }
}
</script>

<style scoped>
.title_heading {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #000000;
}

.title_sub_heading {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #4D4D4D;
}

.card_all_register_open_notify {
    padding: 30px 15px;
}

.card_detail_register_open_notify {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #4D4D4D;
}

.button_register_open_notify {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    background: linear-gradient(90deg, #151B54 0%, #4495C1 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px !important;
}

.page_error_all {
    padding-bottom: 15px;
    border-radius: 10px !important;
}

.image_title_open_error {
    width: 50px;
}

.image_title_open_error>img {
    max-width: 100%;
}

.title_open_error {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    color: #414141;
}

.text_detail_error {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #414141;
}
</style>