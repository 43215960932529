<template>
  <v-app>
    <Header/>
    <ContentKnowledgeList/>
  </v-app>
</template>

<script>
import Header from '@/components/line_oa/menu/Header'
import ContentKnowledgeList from '@/components/line_oa/contentknowledge/ContentKnowledgeList'
export default {
  name: 'lineoaContentKnowledge',
  components:{
    Header,
    ContentKnowledgeList
  }
}
</script>