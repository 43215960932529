<template>
  <div>
    <LoginPage/>
  </div>
</template>

<script>
import LoginPage from '@/components/login/LoginComponent'
export default {
  name: 'Login',
  components:{
    LoginPage,
  }
}
</script>