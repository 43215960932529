import RegisterOpenNotify from '@/views/line_oa/notify/RegisterOpenNotify.vue'
import UserInformation from '@/views/line_oa/notify/UserInformation.vue'
import EditUserInformation from '@/views/line_oa/notify/EditUserInformation.vue'


const main = '/lineoa'
export default [
    {
        path: main+'/registeropennotify',
        name: 'lineoaRegisterOpenNotify',
        component: RegisterOpenNotify,
    },
    {
        path: main+'/userinformation',
        name: 'lineoaUserInformation',
        component: UserInformation,
    },
    {
        path: main+'/edituserinformation/:id',
        name: 'lineoaEditUserInformation',
        component: EditUserInformation,
    },
]

