<template>
    <div>
        <!-- Desktop -->
        <header class="header_desktop">
            <div class="header_all d-flex justify-space-between">
                <div class="d-flex">
                    <div class="logo_header d-flex align-center">
                        <v-avatar size="75">
                            <img src="@/assets/images/logo.png">
                        </v-avatar>
                    </div>
                    <!-- แอดมิน -->

                    <div class="d-flex align-end" v-if="user_type === 1">
                        <v-btn @click="tabActive(1)" :class="{ 'active': tab === 1 }" height="70"
                            class="button_menu_header white--text mt-5" text>Dashboard</v-btn>
                        <v-btn @click="tabActive(2)" :class="{ 'active': tab === 2 }" height="70"
                            class="button_menu_header white--text mt-5" text>โรงพยาบาล</v-btn>
                        <v-btn @click="tabActive(3)" :class="{ 'active': tab === 3 }" height="70"
                            class="button_menu_header white--text mt-5" text>ข้อมูลประชาชน</v-btn>
                        <v-btn @click="tabActive(4)" :class="{ 'active': tab === 4 }" height="70"
                            class="button_menu_header white--text mt-5" text>จัดการผู้ใช้งาน</v-btn>
                    </div>
                    <!-- ผู้ใช้งาน -->

                    <div class="d-flex align-end" v-else>
                        <v-btn v-if="permission_id === 1" @click="tabActive(1)" :class="{ 'active': tab === 1 }" height="70"
                            class="button_menu_header white--text mt-5" text>Dashboard</v-btn>
                        <v-btn v-if="permission_id === 2 || permission_id === 3 || permission_id === 4"
                            @click="tabActive(2)" :class="{ 'active': tab === 2 }" height="70"
                            class="button_menu_header white--text mt-5" text>โรงพยาบาล</v-btn>
                        <v-btn v-if="permission_id === 3" @click="tabActive(3)" :class="{ 'active': tab === 3 }" height="70"
                            class="button_menu_header white--text mt-5" text>ข้อมูลประชาชน</v-btn>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="white--text d-flex flex-column justify-center text_menu_header align-end">
                        <div>{{ first_name }} {{ last_name }}</div>
                        <div>{{ position_name }}</div>
                    </div>
                    <div class="image_user_header d-flex align-center">
                        <v-menu key="Large" rounded="lg" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-avatar size="60" v-bind="attrs" v-on="on">
                                    <img :src="profile_picture">
                                </v-avatar>
                            </template>
                            <v-list>
                                <v-list-item @click="tabActive('user_profile')">
                                    <v-list-item-title>
                                        <div class="d-flex align-center menu_user_header">
                                            <v-icon>mdi-account-cog-outline</v-icon>
                                            <div>จัดการข้อมูลส่วนตัว</div>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="logout()">
                                    <v-list-item-title>
                                        <div class="d-flex align-center menu_user_header">
                                            <v-icon>mdi-logout</v-icon>
                                            <div>ออกจากระบบ</div>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
            </div>
            <div class="header_secondary_all d-flex" v-if="secondary_menu_list.length > 0">
                <router-link class="link_secondary_menu_header" :to="secondary_menu.url"
                    v-for="(secondary_menu, index) in secondary_menu_list" :key="index">
                    <v-menu open-on-hover offset-y v-if="secondary_menu.active == 5">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="button_secondary_menu_header"
                                :class="{ 'active_secondary': tab_secondary === secondary_menu.active }" text>
                                ตั้งค่าข้อมูลเกี่ยวกับโรงพยาบาล
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item-content>
                                <v-list-item-title class="ml-3"
                                    style="font-size:18px;font-weight:600;color:#414141;">ข้อมูลจองคิวรถ</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item @click="$router.push('ambulancequeueall')">
                                <v-list-item-title>ตั้งค่าการจองรถพยาบาล</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="$router.push('destinationall')">
                                <v-list-item-title>จุดหมาย</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-btn v-else @click="tab_secondary = secondary_menu.active" class="button_secondary_menu_header"
                        :class="{ 'active_secondary': tab_secondary === secondary_menu.active }" text>
                        {{ secondary_menu.name }}
                    </v-btn>
                </router-link>

            </div>
        </header>
        <!-- Mobile -->
        <header class="header_mobile">
            <v-app-bar-nav-icon @click.stop="drawerActive()" class="drawer_header_mobile"
                color="white"></v-app-bar-nav-icon>
            <div class="logo_header_mobile d-flex justify-center align-center">
                <v-avatar size="75">
                    <img src="@/assets/images/logo.png">
                </v-avatar>
            </div>
            <div class="d-flex align-center">
                <v-menu key="Large" rounded="lg" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="60" v-bind="attrs" v-on="on">
                            <img :src="profile_picture">
                        </v-avatar>
                    </template>
                    <v-list>
                        <v-list-item @click="tabActive('user_profile')">
                            <v-list-item-title>
                                <div class="d-flex align-center menu_user_header_mobile">
                                    <v-icon>mdi-account-cog-outline</v-icon>
                                    <div>จัดการข้อมูลส่วนตัว</div>
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="logout()">
                            <v-list-item-title>
                                <div class="d-flex align-center menu_user_header_mobile">
                                    <v-icon>mdi-logout</v-icon>
                                    <div>ออกจากระบบ</div>
                                </div>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <v-navigation-drawer app width="300px" v-model="drawer" color="#4EACDF">
                <div class="logo_list_header_mobile">
                    <div class="logo_menu_header_mobile">
                        <img src="@/assets/images/logo.png" alt="">
                    </div>
                </div>
                <div class="menu_list_header_mobile">
                    <v-expansion-panels v-model="panel">
                        <v-expansion-panel @click="tabActive(1)">
                            <v-expansion-panel-header>
                                <div>Dashboard</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <router-link class="link_secondary_menu_header_mobile" to="dashboard"></router-link>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel @click="tabActive(2)">
                            <v-expansion-panel-header>
                                <div>โรงพยาบาล</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <router-link class="link_secondary_menu_header_mobile" :to="secondary_menu.url"
                                    v-for="(secondary_menu, index) in secondary_menu_list" :key="index">
                                    <v-btn class="mb-2 pa-3 justify-start" @click="tab_secondary = secondary_menu.active"
                                        :class="{ 'active_secondary': tab_secondary === secondary_menu.active }"
                                        width="100%" text>{{ secondary_menu.name }}</v-btn>
                                </router-link>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel @click="tabActive(3)">
                            <v-expansion-panel-header>
                                <div>ข้อมูลประชาชน</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <router-link class="link_secondary_menu_header_mobile" :to="secondary_menu.url"
                                    v-for="(secondary_menu, index) in secondary_menu_list" :key="index">
                                    <v-btn class="mb-2 pa-3 justify-start" @click="tab_secondary = secondary_menu.active"
                                        :class="{ 'active_secondary': tab_secondary === secondary_menu.active }"
                                        width="100%" text>{{ secondary_menu.name }}</v-btn>
                                </router-link>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel @click="tabActive(4)">
                            <v-expansion-panel-header>
                                <div>จัดการผู้ใช้งาน</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <router-link class="link_secondary_menu_header_mobile" :to="secondary_menu.url"
                                    v-for="(secondary_menu, index) in secondary_menu_list" :key="index">
                                    <v-btn class="mb-2 pa-3 justify-start" @click="tab_secondary = secondary_menu.active"
                                        :class="{ 'active_secondary': tab_secondary === secondary_menu.active }"
                                        width="100%" text>{{ secondary_menu.name }}</v-btn>
                                </router-link>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </v-navigation-drawer>
        </header>
    </div>
</template>

<script>
import before_image from "@/assets/images/user_image_preview.png"
import profile from '@/api/user_profile/profile.js'
export default {
    data() {
        return {
            drawer: null,
            panel: null,
            tab: null,
            tab_secondary: null,
            secondary_menu_list: [],
            profile_picture: before_image,
            first_name: null,
            last_name: null,
            position_name: null,
            permission_id: null,
            user_type: null,
        }
    },
    methods: {
        async setActiveMenu() {
            const name_url = this.$route.name
            this.tab = null
            this.tab_secondary = null
            this.secondary_menu_list = []

            //Dashboard
            if (name_url == 'dashboard' || name_url == 'dashboard-notify-detail') {
                this.tab = 1
                if (this.permission_id == 1 || this.user_type == 1) {
                    this.secondary_menu_list = [
                        {
                            name: '',
                            active: '',
                            url: '',
                        },
                    ]
                }

            }
            // โรงพยาบาล
            if (name_url == 'appointmentAll' || name_url == 'viewListAppointmentAll' || name_url == 'addAppointment' || name_url == 'addAppointmentExcel' || name_url == 'detailAppointment' || name_url == 'editAppointment' || name_url == 'contentKnowledge' || name_url == 'addContentKnowledge' || name_url == 'editContentKnowledge' || name_url == 'detailContentKnowledge' || name_url == 'ambulancequeueall' || name_url == 'destinationall' || name_url == 'ambulancequeuebookall' || name_url == 'ambulancequeuebookadd' || name_url == 'ambulancequeuebookedit') {
                this.tab = 2
                if (this.user_type === 1) {
                    this.secondary_menu_list = [
                        {
                            name: 'คิวนัดหมาย',
                            active: 2,
                            url: '/viewlistappointmentall',
                        },
                        {
                            name: 'จองคิวรถพยาบาล',
                            active: 3,
                            url: '/ambulancequeuebookall',
                        },
                        {
                            name: 'สาระน่ารู้',
                            active: 4,
                            url: '/contentknowledge',
                        },
                        {
                            name: 'ตั้งค่าข้อมูลเกี่ยวกับโรงพยาบาล',
                            active: 5,
                            url: '/#',
                        },
                    ]
                } else {
                    if (this.permission_id == 2) {
                        this.secondary_menu_list = [
                            {
                                name: 'จองคิวรถพยาบาล',
                                active: 3,
                                url: '/ambulancequeuebookall',
                            },
                            {
                                name: 'ตั้งค่าข้อมูลเกี่ยวกับโรงพยาบาล',
                                active: 5,
                                url: '/#',
                            },
                        ]
                    }
                    if (this.permission_id == 3) {
                        this.secondary_menu_list = [
                            {
                                name: 'คิวนัดหมาย',
                                active: 2,
                                url: '/viewlistappointmentall',
                            },
                        ]
                    }
                    if (this.permission_id == 4) {
                        this.secondary_menu_list = [
                            {
                                name: 'สาระน่ารู้',
                                active: 4,
                                url: '/contentknowledge',
                            },
                        ]
                    }
                }

                if (name_url == 'appointmentAll' || name_url == 'viewListAppointmentAll' || name_url == 'addAppointment' || name_url == 'addAppointmentExcel' || name_url == 'detailAppointment' || name_url == 'editAppointment') {
                    this.tab_secondary = 2
                }
                if (name_url == 'ambulancequeuebookall' || name_url == 'ambulancequeuebookadd' || name_url == 'ambulancequeuebookedit') {
                    this.tab_secondary = 3
                }
                if (name_url == 'contentKnowledge' || name_url == 'addContentKnowledge' || name_url == 'editContentKnowledge' || name_url == 'detailContentKnowledge') {
                    this.tab_secondary = 4
                }
                if (name_url == 'ambulancequeueall' || name_url == 'destinationall') {
                    this.tab_secondary = 5
                }
            }
            // ข้อมูลประชาชน
            if (name_url == 'managePeople' || name_url == 'addPeople' || name_url == 'addPeopleExcel' || name_url == 'detailPeople' || name_url == 'editPeople') {
                this.tab = 3
                if (this.user_type === 1) {
                    this.secondary_menu_list = [
                        {
                            name: 'ข้อมูลประชาชน',
                            active: 1,
                            url: '/managepeople',
                        },
                    ]
                } else {
                    if (this.permission_id === 3) {
                        this.secondary_menu_list = [
                            {
                                name: 'ข้อมูลประชาชน',
                                active: 1,
                                url: '/managepeople',
                            },
                        ]
                    }
                }

                if (name_url == 'managePeople' || name_url == 'addPeople' || name_url == 'addPeopleExcel' || name_url == 'detailPeople' || name_url == 'editPeople') {
                    this.tab_secondary = 1
                }
            }
            // จัดการผู้ใช้งาน
            if (name_url == 'manageAdmins' || name_url == 'addAdmin' || name_url == 'editAdmin' || name_url == 'manageUsers' || name_url == 'addUser' || name_url == 'editUser') {
                this.tab = 4
                if (this.user_type == 1) {
                    this.secondary_menu_list = [
                        {
                            name: 'จัดการผู้ดูแลระบบ',
                            active: 1,
                            url: '/manageadmins',
                        },
                        {
                            name: 'จัดการผู้ใช้งาน',
                            active: 2,
                            url: '/manageusers',
                        },
                    ]
                }

                if (name_url == 'manageAdmins' || name_url == 'addAdmin' || name_url == 'editAdmin') {
                    this.tab_secondary = 1
                }
                if (name_url == 'manageUsers' || name_url == 'addUser' || name_url == 'editUser') {
                    this.tab_secondary = 2
                }
            }
        },
        async tabActive(tab) {
            const name_url = this.$route.name
            this.tab = tab
            this.tab_secondary = null
            this.secondary_menu_list = []

            // ข้อมูลส่วนตัว
            if (tab == 'user_profile') {
                this.$router.push('/userprofile')
            }
            //Dashboard
            if (tab == 1) {
                this.$router.push('/dashboard')
            }
            // โรงพยาบาล
            if (tab == 2) {
                if (this.user_type === 1) {
                    this.secondary_menu_list = [
                        {
                            name: 'คิวนัดหมาย',
                            active: 2,
                            url: '/viewlistappointmentall',
                        },
                        {
                            name: 'จองคิวรถพยาบาล',
                            active: 3,
                            url: '/ambulancequeuebookall',
                        },
                        {
                            name: 'สาระน่ารู้',
                            active: 4,
                            url: '/contentknowledge',
                        },
                        {
                            name: 'ตั้งค่าข้อมูลเกี่ยวกับโรงพยาบาล',
                            active: 5,
                            url: '/#',
                        },
                    ]
                } else {
                    if (this.permission_id == 2) {
                        this.secondary_menu_list = [
                            {
                                name: 'จองคิวรถพยาบาล',
                                active: 3,
                                url: '/ambulancequeuebookall',
                            },
                            {
                                name: 'ตั้งค่าข้อมูลเกี่ยวกับโรงพยาบาล',
                                active: 5,
                                url: '/#',
                            },
                        ]
                    }
                    if (this.permission_id == 3) {
                        this.secondary_menu_list = [
                            {
                                name: 'คิวนัดหมาย',
                                active: 2,
                                url: '/viewlistappointmentall',
                            },
                        ]
                    }
                    if (this.permission_id == 4) {
                        this.secondary_menu_list = [
                            {
                                name: 'สาระน่ารู้',
                                active: 4,
                                url: '/contentknowledge',
                            },
                        ]
                    }
                }
                if (name_url == 'appointmentAll' || name_url == 'viewListAppointmentAll' || name_url == 'addAppointment' || name_url == 'addAppointmentExcel' || name_url == 'detailAppointment' || name_url == 'editAppointment') {
                    this.tab_secondary = 2
                }
                if (name_url == 'contentKnowledge' || name_url == 'addContentKnowledge' || name_url == 'editContentKnowledge' || name_url == 'detailContentKnowledge') {
                    this.tab_secondary = 4
                }
            }
            // ข้อมูลประชาชน
            if (tab == 3) {
                if (this.user_type === 1) {
                    this.secondary_menu_list = [
                        {
                            name: 'ข้อมูลประชาชน',
                            active: 1,
                            url: '/managepeople',
                        },
                    ]
                } else {
                    if (this.permission_id === 3) {
                        this.secondary_menu_list = [
                            {
                                name: 'ข้อมูลประชาชน',
                                active: 1,
                                url: '/managepeople',
                            },
                        ]
                    }
                }
                if (name_url == 'managePeople' || name_url == 'addPeople' || name_url == 'addPeopleExcel' || name_url == 'detailPeople' || name_url == 'editPeople') {
                    this.tab_secondary = 1
                }
            }
            // จัดการผู้ใช้งาน
            if (tab == 4) {
                if (this.user_type == 1) {
                    this.secondary_menu_list = [
                        {
                            name: 'จัดการผู้ดูแลระบบ',
                            active: 1,
                            url: '/manageadmins',
                        },
                        {
                            name: 'จัดการผู้ใช้งาน',
                            active: 2,
                            url: '/manageusers',
                        },
                    ]
                }
                if (name_url == 'manageAdmins' || name_url == 'addAdmin' || name_url == 'editAdmin') {
                    this.tab_secondary = 1
                }
                if (name_url == 'manageUsers' || name_url == 'addUser' || name_url == 'editUser') {
                    this.tab_secondary = 2
                }
            }
        },
        async drawerActive() {
            this.drawer = !this.drawer
            this.panel = null
        },
        async profileDetail() {
            this.profile_picture = before_image
            this.first_name = null
            this.last_name = null
            this.user_type = null
            this.permission_id = null
            profile.profileDetail().then((response) => {
                if (response.data.successful === true) {
                    if (response.data.profileDetail.picture) {
                        this.profile_picture = response.data.profileDetail.picture
                    }
                    this.first_name = response.data.profileDetail.first_name
                    this.last_name = response.data.profileDetail.last_name
                    this.position_name = response.data.profileDetail.position_name
                    this.permission_id = response.data.profileDetail.permission_id
                    this.user_type = response.data.profileDetail.user_type
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async logout() {
            this.$store.commit('logOutSuccess')
            this.$router.push('/login')
        }
    },
    created() {
        this.setActiveMenu()
    },
    mounted() {
        this.profileDetail()
        this.$root.$on('refreshProfileHeader', (detail) => {
            if (detail == true) {
                this.profileDetail()
            }
        })
    },
}
</script>

<style scoped>
.header_all {
    background-color: #4EACDF;
}

.logo_header {
    padding: 5px 15px;
}

.button_menu_header {
    font-size: 16px;
    line-height: 18px;
}

.button_menu_header.v-btn {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.text_menu_header {
    font-size: 16px;
    line-height: 18px;
}

.image_user_header {
    padding: 15px;
}

.menu_user_header {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #585858;
}

.active {
    background: #FFFFFF;
    color: black !important;
}

.header_secondary_all {
    background: #FFFFFF;
    padding: 15px;
    border-bottom: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.link_secondary_menu_header {
    color: #9699A7;
    line-height: 21px;
    text-decoration: none;
}

.button_secondary_menu_header {
    font-size: 18px;
    padding: 0 40px !important;
}

.active_secondary {
    background: #F4F6FA;
    color: #4EACDF;
    border-radius: 10px;
}

.header_mobile {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .header_desktop {
        display: none;
    }

    .header_mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #4EACDF;
        padding: 15px;
        position: relative;
    }

    .drawer_header_mobile {
        z-index: 1;
    }

    .logo_header_mobile {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .logo_list_header_mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0 15px;
    }

    .logo_list_header_mobile>.logo_menu_header_mobile {
        width: 100px;
    }

    .logo_list_header_mobile>.logo_menu_header_mobile>img {
        max-width: 100%;
    }

    .menu_list_header_mobile {
        padding: 0 10px;
    }

    .link_secondary_menu_header_mobile {
        text-decoration: none;
    }

    .menu_user_header_mobile {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #585858;
    }
}
</style>