<template>
    <div>
        <header class="header">
            <div class="menu" @click="menu = !menu">
                <v-icon color="white" size="45">mdi-menu</v-icon>
            </div>
            <div class="logo_header d-flex justify-center align-center">
                <v-avatar size="65">
                    <img src="@/assets/images/logo.png">
                </v-avatar>
            </div>
            <div>
                <!-- <v-icon color="white" size="45">mdi-close</v-icon> -->
            </div>
        </header>
         <Transition name="menu_list">
            <div class="px-6 pt-3 menu_list" v-if="menu == true">
                <div class="title_menu mb-2">ข่าวประกาศ</div>
                <div class="d-flex align-center mb-2">
                    <div class="image_list_menu mr-2">
                        <img src="@/assets/images/line_oa/menu/megaphone.jpg">
                    </div>
                    <div class="title_list_menu">
                        ประกาศจากโรงพยาบาล
                    </div>
                </div>
                <div class="d-flex align-center mb-2">
                    <div class="image_list_menu mr-2">
                        <img src="@/assets/images/line_oa/menu/present_user.jpg">
                    </div>
                    <div class="title_list_menu">
                        งานอบรม
                    </div>
                </div>
                <div class="title_menu mb-2">สาระน่ารู้</div>
                <div class="d-flex align-center mb-2" @click="menuLink(1)">
                    <div class="image_list_menu mr-2">
                        <img src="@/assets/images/line_oa/menu/diabetes_and_blood_pressure.jpg">
                    </div>
                    <div class="title_list_menu">
                        เบาหวานและความดันโลหิต
                    </div>
                </div>
                <div class="d-flex align-center mb-2" @click="menuLink(2)">
                    <div class="image_list_menu mr-2">
                        <img src="@/assets/images/line_oa/menu/family.jpg">
                    </div>
                    <div class="title_list_menu">
                        วางแผนครอบครัว
                    </div>
                </div>
                <div class="d-flex align-center mb-2" @click="menuLink(3)">
                    <div class="image_list_menu mr-2">
                        <img src="@/assets/images/line_oa/menu/pregnancy.jpg">
                    </div>
                    <div class="title_list_menu">
                        ฝากครรภ์
                    </div>
                </div>
                <div class="d-flex align-center mb-2" @click="menuLink(4)">
                    <div class="image_list_menu mr-2">
                        <img src="@/assets/images/line_oa/menu/health_kid.jpg">
                    </div>
                    <div class="title_list_menu">
                        สุขภาพเด็กดี
                    </div>
                </div>
                <div class="d-flex align-center mb-2" @click="menuLink(5)">
                    <div class="image_list_menu mr-2">
                        <img src="@/assets/images/line_oa/menu/tooth.jpg">
                    </div>
                    <div class="title_list_menu">
                        ทันตกรรม
                    </div>
                </div>
                <div class="d-flex align-center mb-2" @click="menuLink(6)">
                    <div class="image_list_menu mr-2">
                        <img src="@/assets/images/line_oa/menu/prevent_virus.jpg">
                    </div>
                    <div class="title_list_menu">
                        ส่งเสริมสุขภาพป้องกันโรค
                    </div>
                </div>
                <div class="d-flex align-center mb-2" @click="menuLink(7)">
                    <div class="image_list_menu mr-2">
                        <img src="@/assets/images/line_oa/menu/pharmacy_pill.jpg">
                    </div>
                    <div class="title_list_menu">
                        เภสัชกรรม
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import { base64Encode } from '@/helpers/base64.js'
export default {
    data(){
        return{
            menu:false
        }
    },
    methods:{
        base64Encode(id){
            return base64Encode(id)
        },
        menuLink(category_content_id){
            this.menu = false
            this.$router.push('/lineoa/contentknowledge/'+this.base64Encode(category_content_id))
        },
    }
}
</script>

<style scoped>
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(360deg, #0C183D 0%, #2E84B5 100%);
    padding: 30px;
    position: relative;
    border-bottom: 1px solid #4EACDF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 40px 40px;
}

.menu{
    z-index: 1;
}

.logo_header{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.menu_list{
    position: absolute;
    background: #ffffff;
    z-index: 100;
    margin-top: 10px;
    width: 100%;
    height: 100%;
}

.menu_list-enter-active, .menu_list-leave-active {
  transition: opacity 0.5s;
}

.menu_list-enter, .menu_list-leave-to {
  opacity: 0;
}

.title_menu{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #0F2248;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.image_list_menu{
    width: 10%;
}

.image_list_menu > img{
    max-width: 100%;
}

.title_list_menu{
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #157FB9;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);   
}
</style>