<template>
  <div>
    <Heading heading="เพิ่มข้อมูลประชาชน" sub_heading="ประชาชน" sub_secondary_heading="รายการประชาชน"/>
    <AddPeopleList/>
  </div>
</template>
  
<script>
  import Heading from '@/components/heading/Heading'
  import AddPeopleList from '@/components/manage_people/manage_people/AddPeopleList'
  export default {
    name: 'addPeople',
    components:{
      Heading,
      AddPeopleList
    }
  }
  </script>