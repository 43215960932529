import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    peopleList: (page = '', size = '', search = '') => {
        return HTTP.get(`/people/Peoplelist?page=${page}&size=${size}&search=${search}`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    checkHNPeople: (hn) => {
        return HTTP.get('/people/checkpeople/' + hn, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    addPeople: (data) => {
        return HTTP.post('/people/peopleAdd', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    peopleDetail: (id) => {
        return HTTP.get('/people/Peoplelist_edit/' + id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    editPeople: (id, data) => {
        return HTTP.post('/people/Peoplelist_edit_save/' + id, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    importFilePeople: (data) => {
        return HTTP.post('/people/People_add_excel', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    peopleImportExcelHistoryList: (page = '', size = '') => {
        const data = {
            page: page,
            size: size,
        }
        return HTTP.post(`/people/import/excel/history`, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    peopleCheck: () => {
        return HTTP.get(`/people/have/line`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    }
}