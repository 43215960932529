<template>
    <div>
        <div class="card_heading_data d-flex justify-space-between align-center">
            <div class="card_text_heading_data">รายการคิวนัดหมาย</div>
            <div class="card_search_heading_data">
                <v-text-field class="input_search" v-model="search" @keyup="searchData()" label="ค้นหา"
                    prepend-inner-icon="mdi-magnify" hide-details="auto" solo required></v-text-field>
            </div>
        </div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_data_list">
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="title_table_data_list">
                            วันที่สร้างการแจ้งเตือน
                        </th>
                        <th class="title_table_data_list">
                            จำนวนผู้ป่วยที่นัดหมาย
                        </th>
                        <th class="title_table_data_list">
                            ผู้สร้างการแจ้งเตือน
                        </th>
                        <th class="title_table_data_list">
                            สถานะการส่งการแจ้งเตือน
                        </th>
                        <th class="title_table_data_list">
                            จัดการ
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(detail, index) in data" :key="index">
                        <td class="detail_table_data_list">{{ detail.update_at }}</td>
                        <td class="detail_table_data_list">{{ detail.patient_count }}</td>
                        <td class="detail_table_data_list">{{ detail.first_name }} {{ detail.last_name }}</td>
                        <td class="detail_table_data_list">
                            <v-btn v-if="detail.status == 0" @click="openConfirmSendNotification(base64Encode(detail.id))"
                                class="my-2 mr-2 white--text status_alert_confirm_notify" height="43" color="#37AE46">
                                <v-icon>mdi-check-circle-outline</v-icon>
                                <div class="ml-1">ยืนยันการส่งการแจ้งเตือน</div>
                            </v-btn>
                            <div v-if="detail.status == 2" class="success--text">ส่งการแจ้งเตือนแล้ว</div>
                            <div v-if="detail.status == 1" class="error--text">ยกเลิก</div>
                        </td>
                        <td class="detail_table_data_list">
                            <v-btn class="button_tool_data_list my-2 mr-2"
                                @click="$router.push('/detailappointment/' + base64Encode(detail.id))" height="43"
                                color="#4495C1"><v-icon>mdi-magnify</v-icon>
                                <div>ดูข้อมูล</div>
                            </v-btn>
                            <v-btn v-if="detail.status == 0"
                                @click="$router.push('/editappointment/' + base64Encode(detail.id))"
                                class="button_tool_data_list my-2 mr-2" height="43"
                                color="#FD8F16"><v-icon>mdi-pencil</v-icon>
                                <div>แก้ไข</div>
                            </v-btn>
                            <v-btn v-if="detail.status == 0" @click="openConfirmDelete(base64Encode(detail.id))"
                                class="button_tool_data_list my-2" height="43"
                                color="#B3001B"><v-icon>mdi-close-circle-outline</v-icon>
                                <div>ยกเลิก</div>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-pagination class="pagination_data_list" v-model="page" color="#4EACDF" :length="pages"
                :total-visible="7"></v-pagination>
        </div>

        <v-dialog v-model="open_confirm_notification_all" persistent max-width="600px">
            <v-card class="page_confirm_delete_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-icon size="200" color="success">mdi-help-circle</v-icon>
                            </div>
                            <div class="text_confirm_delete">คุณแน่ใจใช่ไหมที่จะยืนยันการส่งการแจ้งเตือน?</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_delete">
                    <v-btn class="button_text_confirm_delete" @click="sendNotification(1)" height="43" width="40%"
                        color="#4EACDF">ใช่ ฉันต้องการ !</v-btn>
                    <v-btn class="button_text_confirm_delete" @click="sendNotification(0)" height="43" width="40%"
                        color="#414141">ยกเลิก</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog v-model="open_confirm_delete" persistent max-width="600px">
            <v-card class="page_confirm_delete_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">

                            <div class="image_confirm_delete">
                                <img src="@/assets/images/trash_confirm_delete.jpg" alt="">
                            </div>
                            <div class="text_confirm_delete">คุณแน่ใจใช่ไหมที่จะทำการยกเลิก?</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_delete">
                    <v-btn class="button_text_confirm_delete" @click="deleteAppointment()" height="43" width="40%"
                        color="#4EACDF">ใช่ ฉันต้องการยกเลิก !</v-btn>
                    <v-btn class="button_text_confirm_delete" @click="cancelDeleteAppointment()" height="43" width="40%"
                        color="#414141">ยกเลิก</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { base64Encode, base64Decode } from '@/helpers/base64.js'
import appointmentall from '@/api/hospital/appointment/viewlistappointmentall/appointmentall.js'
export default {
    data() {
        return {
            loading_page: true,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            open_confirm_notification_all: false,
            notification_id: null,
            open_confirm_delete: false,
            delete_id: null,
            search: '',
            data: [],
            page: 1,
            size: 15,
            pages: 1,
        }
    },
    methods: {
        base64Encode(id) {
            return base64Encode(id)
        },
        base64Decode(id) {
            return base64Decode(id)
        },
        async appointmentList() {
            this.loading_page = true
            this.data = []
            appointmentall.appointmentList(this.page, this.size, this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.queue_List
                    this.pages = response.data.pages
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async openConfirmSendNotification(id) {
            this.notification_id = id
            this.open_confirm_notification_all = true
        },
        async sendNotification(type) {
            this.open_confirm_notification_all = false
            if (type == 1) {
                this.loading_page = true
                appointmentall.sendNotification(this.base64Decode(this.notification_id)).then((response) => {
                    if (response.data.successful === true) {
                        this.appointmentList()
                        this.alert_message_success = 'ยืนยันการส่งการแจ้งเตือนเรียบร้อยแล้ว'
                        this.alert_success = true
                        setTimeout(() => this.alert_success = false, 5000)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        },
        async openConfirmDelete(id) {
            this.delete_id = id
            this.open_confirm_delete = true
        },
        async deleteAppointment() {
            this.open_confirm_delete = false
            this.loading_page = true
            appointmentall.deleteAppointment(this.base64Decode(this.delete_id)).then((response) => {
                if (response.data.successful === true) {
                    this.page = 1
                    this.size = 4
                    this.pages = 1
                    this.appointmentList()
                    this.alert_message_success = 'ลบคิวนัดหมายเรียบร้อยแล้ว'
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async cancelDeleteAppointment() {
            this.delete_id = null
            this.open_confirm_delete = false
        },
        async searchData() {
            this.data = []
            this.page = 1
            appointmentall.appointmentList(this.page, this.size, this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.queue_List
                    this.pages = response.data.pages
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        }
    },
    mounted() {
        this.appointmentList()
    },
    watch: {
        page() {
            this.appointmentList()
        }
    }
}
</script>

<style scoped>.status_alert_confirm_notify {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    box-shadow: unset;
    border-radius: 10px !important;
}</style>