import { render, staticRenderFns } from "./AboutList.vue?vue&type=template&id=00ad0cea&scoped=true&"
var script = {}
import style0 from "./AboutList.vue?vue&type=style&index=0&id=00ad0cea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00ad0cea",
  null
  
)

export default component.exports