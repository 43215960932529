import ContentKnowledge from '@/views/line_oa/contentknowledge/ContentKnowledge.vue'
import DetailContentKnowledge from '@/views/line_oa/contentknowledge/DetailContentKnowledge.vue'
const main = '/lineoa'
export default [
    {
        path: main+'/contentknowledge/:category_content_id',
        name: 'lineoaContentKnowledge',
        component: ContentKnowledge,
    },
    {
        path: main+'/detailcontentknowledge/:id',
        name: 'lineoaDetailContentKnowledge',
        component: DetailContentKnowledge,
    },
]

