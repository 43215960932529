<template>
  <v-app>
    <Header/>
    <RegisterOpenNotifyList/>
  </v-app>
</template>

<script>
import Header from '@/components/line_oa/menu/Header'
import RegisterOpenNotifyList from '@/components/line_oa/notify/RegisterOpenNotifyList'
export default {
  name: 'lineoaRegisterOpenNotify',
  components:{
    Header,
    RegisterOpenNotifyList
  }
}
</script>