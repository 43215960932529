import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    contentKnowledgeList: (page='',size='',search_category_id='',search='') => {
        return HTTP.get(`/content/list?page=${page}&size=${size}&search_category_id=${search_category_id}&search=${search}`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    deleteContentKnowledge: (id) => {
        return HTTP.post('/content/delete/' + id, null, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    addContentKnowledge: (data) => {
        return HTTP.post('/content/add', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    contentKnowledgeDetail: (id) => {
        return HTTP.get('/content/detail/'+ id , {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    editContentKnowledge: (id,data) => {
        return HTTP.post('/content/edit/'+ id, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
}