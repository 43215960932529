import { render, staticRenderFns } from "./DetailContentKnowledgeList.vue?vue&type=template&id=bd903a60&scoped=true&"
import script from "./DetailContentKnowledgeList.vue?vue&type=script&lang=js&"
export * from "./DetailContentKnowledgeList.vue?vue&type=script&lang=js&"
import style0 from "./DetailContentKnowledgeList.vue?vue&type=style&index=0&id=bd903a60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd903a60",
  null
  
)

export default component.exports