import { HTTP } from "@/axios.js"
export default {
    contentKnowledgeList: (page='',size='',search_category_id='',search='') => {
        return HTTP.get(`/content/list/lineoa?page=${page}&size=${size}&search_category_id=${search_category_id}&search=${search}`)
        .then(response => response)
    },
    contentKnowledgeDetail: (id) => {
        return HTTP.get('/content/detail/lineoa/'+ id)
        .then(response => response)
    },
}