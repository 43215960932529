<template>
    <div>
        <Heading heading="จัดการข้อมูลประชาชน" sub_heading="ประชาชน" dialog_link_name="เพิ่มข้อมูลประชาชน" dialog_link_type="managepeople" />
        <ManagePeopleList />
    </div>
</template>
  
<script>
import Heading from '@/components/heading/Heading'
import ManagePeopleList from '@/components/manage_people/manage_people/ManagePeopleList'
export default {
    name: 'managePeople',
    components: {
        Heading,
        ManagePeopleList
    }
}
</script>