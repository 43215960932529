<template>
  <div>
    <Heading heading="แก้ไขผู้ดูแลระบบ" sub_heading="จัดการผู้ดูแลระบบ" sub_secondary_heading="รายการผู้ดูแลระบบ"/>
    <EditAdminList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import EditAdminList from '@/components/manage_users/manage_admins/EditAdminList'
export default {
  name: 'editAdmin',
  components:{
    Heading,
    EditAdminList
  }
}
</script>