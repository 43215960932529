<template>
<v-app class="background_page">
    <Header/>
    <v-content>
        <v-container class="body_page" fluid>
            <router-view></router-view>
        </v-container>
    </v-content>
</v-app>
</template>

<script>
import Header from '@/components/menu/Header.vue'
export default {
    name:'pageAll',
    components:{
        Header,
    }
}
</script>

<style scoped>
.background_page{
    background:#F3F3F4;
}

.body_page{
    padding: 20px 40px;
}
</style>