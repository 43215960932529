<template>
    <div>
        <Heading heading="Dashboard" sub_heading="รายงานแดชบอร์ด"
            sub_secondary_heading="รายละเอียดผู้ได้รับการแจ้งเตือนนัดหมาย" />
        <DashboardNotifyDetail />
    </div>
</template>
  
<script>
import Heading from '@/components/heading/Heading'
import DashboardNotifyDetail from '@/components/dashboard/DashboardNotifyDetail.vue'
export default {
    name: 'dashboardNotifyDetail',
    components: {
        Heading,
        DashboardNotifyDetail
    }
}
</script>