<template>
  <div>
    <Ambulancecheckqueue />
  </div>
</template>
  
<script>
import Ambulancecheckqueue from '@/components/hospital/ambulancequeue/Ambulancecheckqueue'
export default {
  name: 'ambulancecheckqueue',
  components: {
    Ambulancecheckqueue
  }
}
</script>