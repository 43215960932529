<template>
    <div>
      <Heading heading="Dashboard" sub_heading="รายงานแดชบอร์ด" />
      <Dashboard/>
    </div>
  </template>
  
  <script>
  import Heading from '@/components/heading/Heading'
  import Dashboard from '@/components/dashboard/Dashboard.vue'
  export default {
    name: 'dashboard',
    components:{
      Heading,
      Dashboard
    }
  }
  </script>