<template>
    <div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <!-- รายงานการนัดหมาย -->
        <div class="card_heading_data d-flex justify-space-between align-center">
            <div class="card_text_heading_data mtb-10">รายงานการนัดหมาย</div>
        </div>

        <div class="card_data_list">
            <v-row class="mb-3">
                <v-col xs="12" sm="12" md="4" lg="3" xl="3" cols="12">
                    <v-card class="input_select" elevation="3">
                        <v-card-text>
                            <div class="text_content">
                                <div class="font-weight-bold">การแจ้งนัดหมายทั้งหมด</div>
                            </div>
                            <div class="d-flex justify-space-between">
                                <div class="image_title">
                                    <img src="@/assets/images/dashboard/medical-appointment.png">
                                </div>
                                <div style="display:flex;align-items:center;">
                                    <div class="text_count">{{ formatNumber(count_send_noti) }} <span
                                            class="ml-3">ครั้ง</span></div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col xs="12" sm="12" md="4" lg="3" xl="2" cols="12">
                    <v-card class="input_select" elevation="3">
                        <v-card-text>
                            <div class="text_content">
                                <div class="font-weight-bold">ประชาชนทั้งหมด</div>
                            </div>
                            <div class="d-flex justify-space-between">
                                <div class="image_title">
                                    <img src="@/assets/images/dashboard/youth.png">
                                </div>
                                <div style="display:flex;align-items:center;">
                                    <div class="text_count">{{ formatNumber(count_people) }} <span class="ml-3">คน</span>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col xs="12" sm="12" md="4" lg="6" xl="3" cols="12">
                    <v-card class="input_select" elevation="3">
                        <v-card-text>
                            <div class="text_content">
                                <div class="font-weight-bold">การเปิดรับการแจ้งเตือน</div>
                            </div>
                            <v-row>
                                <v-col cols="6">
                                    <div class="d-flex justify-space-between">
                                        <div class="image_title">
                                            <img src="@/assets/images/dashboard/checked.png">
                                        </div>
                                        <div style="display:flex;align-items:center;">
                                            <div class="text_count">{{ formatNumber(count_people_have_noti) }} <span
                                                    class="ml-3">คน</span></div>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="d-flex justify-space-between">
                                        <div class="image_title">
                                            <img src="@/assets/images/dashboard/cross.png">
                                        </div>
                                        <div style="display:flex;align-items:center;">
                                            <div class="text_count">{{ formatNumber(count_people_not_have_noti) }} <span
                                                    class="ml-3">คน</span></div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col xs="12" sm="12" md="4" lg="3" xl="3" cols="12">
                    <div class="text_title mb-10">
                        <div class="image_title">
                            <img src="@/assets/images/dashboard/month.png">
                        </div>
                        <div class="ml-2 mr-10 text-header-title">สถิติประจำเดือน</div>
                    </div>
                    <v-row>
                        <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-card class="input_select" color="#EBF8FF" elevation="3">
                                <v-card-text>
                                    <div class="text_content">
                                        <div class="font-weight-bold">การแจ้งนัดหมาย</div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="image_title">
                                            <img src="@/assets/images/dashboard/online-appointment.png">
                                        </div>
                                        <div style="display:flex;align-items:center;">
                                            <div class="text_count">{{ formatNumber(searchNoti.count_send_noti) }} <span
                                                    class="ml-3">ครั้ง</span></div>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-card class="input_select" color="#EBF8FF" elevation="3" link
                                @click="link('dashboard-notify-detail')">
                                <v-card-text>
                                    <div class="text_content">
                                        <div class="font-weight-bold">ได้รับการแจ้งเตือน</div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="image_title">
                                            <img src="@/assets/images/dashboard/new-message.png">
                                        </div>
                                        <div style="display:flex;align-items:center;">
                                            <div class="text_count">{{ formatNumber(searchNoti.count_get_noti) }} <span
                                                    class="ml-3">ครั้ง</span></div>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col xs="12" sm="12" md="8" lg="9" xl="9" cols="12">
                    <v-row>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                            <div class="text_title mb-10">
                                <div class="image_title">
                                    <img src="@/assets/images/dashboard/virus.png">
                                </div>
                                <div class="ml-2 mr-10 text-header-title">ผู้ป่วยได้รับการนัดหมาย</div>

                            </div>
                        </v-col>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                            <div class="d-flex" style="justify-content:flex-end;">
                                <div class="mr-2">
                                    <v-select class="input_select" v-model="searchNoti.currentMonthNoti" :items="monthList"
                                        item-text="name" item-value="id" label="เลือกเดือน" @change="CountNotification()"
                                        prepend-inner-icon="mdi-calendar-clock" hide-details="auto" solo
                                        required></v-select>
                                </div>
                                <div>
                                    <v-select class="input_select" v-model="searchNoti.currentYearNoti" :items="yearList"
                                        item-text="name" item-value="id" label="เลือกปี" @change="CountNotification()"
                                        prepend-inner-icon="mdi-calendar-clock" hide-details="auto" solo
                                        required></v-select>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <div style="height: 40vh !important;">
                        <canvas id="myChartNotify"></canvas>
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- รายงานการจองรถพยาบาล -->
        <div class="card_heading_data d-flex justify-space-between align-center">
            <div class="card_text_heading_data mtb-10">รายงานการจองรถพยาบาล</div>
        </div>

        <div class="card_data_list">
            <v-row class="mb-3">
                <v-col xs="12" sm="12" md="6" lg="3" xl="3" cols="12">
                    <v-card class="input_select" elevation="3" height="156">
                        <v-card-text>
                            <div class="text_content">
                                <div class="font-weight-bold">การไปส่งคนไข้ทั้งหมด</div>
                            </div>
                            <div class="d-flex justify-space-between">
                                <div class="image_title">
                                    <img src="@/assets/images/dashboard/passenger.png">
                                </div>
                                <div style="display:flex;align-items:center;">
                                    <div class="text_count">{{ formatNumber(count_que_ambulance) }} <span
                                            class="ml-3">ครั้ง</span>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col xs="12" sm="12" md="6" lg="3" xl="3" cols="12">
                    <v-card class="input_select" elevation="3" height="156">
                        <v-card-text>
                            <div class="text_content">
                                <div class="font-weight-bold">สถิติช่องทางการจอง</div>
                            </div>
                            <v-row>
                                <v-col cols="2">
                                    <div class="image_title">
                                        <img src="@/assets/images/dashboard/booking.png">
                                    </div>
                                </v-col>
                                <v-col cols="10">
                                    <div class="d-flex justify-space-between">
                                        <div class="text_content">
                                            <div class="ml-2 mr-10">จากไลน์</div>
                                        </div>
                                        <div>
                                            <div class="text_count">{{ formatNumber(count_que_am_line) }} <span
                                                    class="ml-3">คน</span></div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="text_content">
                                            <div class="ml-2 mr-10">โดยเจ้าหน้าที่</div>
                                        </div>
                                        <div>
                                            <div class="text_count">{{ formatNumber(count_que_am_admin) }} <span
                                                    class="ml-3">คน</span></div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col xs="12" sm="12" md="6" lg="3" xl="3" cols="12">
                    <v-card class="input_select" elevation="3" height="156">
                        <v-card-text>
                            <div class="text_content">
                                <div class="font-weight-bold">จำนวนผู้ป่วยตามประเภท</div>
                            </div>
                            <v-row>
                                <v-col cols="2">
                                    <div class="image_title">
                                        <img src="@/assets/images/dashboard/hospitalisation.png">
                                    </div>
                                </v-col>
                                <v-col cols="10">
                                    <div class="d-flex justify-space-between">
                                        <div class="text_content">
                                            <div class="ml-2 mr-10">ผู้ป่วยติดเตียง</div>
                                        </div>
                                        <div>
                                            <div class="text_count">{{ formatNumber(people_patient) }} <span
                                                    class="ml-3">คน</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="text_content">
                                            <div class="ml-2 mr-10">ผู้ป่วยปกติ</div>
                                        </div>
                                        <div>
                                            <div class="text_count">{{ formatNumber(people_normal) }} <span
                                                    class="ml-3">คน</span>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col xs="12" sm="12" md="6" lg="3" xl="3" cols="12">
                    <v-card class="input_select" elevation="3">
                        <v-card-text>
                            <div class="text_content">
                                <div class="font-weight-bold">โรงพยาบาลปลายทาง</div>
                            </div>
                            <v-row>
                                <v-col cols="2">
                                    <div class="image_title d-flex align-items-center">
                                        <img src="@/assets/images/dashboard/booking.png">
                                    </div>
                                </v-col>
                                <v-col cols="10">
                                    <div class="d-flex justify-space-between" v-for="item in drop_off_list" :key="item">
                                        <div class="text_content">
                                            <div class="ml-2 mr-10">{{ formatNumber(item.name_drop_off) }}</div>
                                        </div>
                                        <div>
                                            <div class="text_count">{{ formatNumber(item.count_drop_off) }} <span
                                                    class="ml-3">ครั้ง</span></div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col xs="12" sm="12" md="4" lg="3" xl="3" cols="12">
                    <div class="text_title mb-10">
                        <div class="image_title">
                            <img src="@/assets/images/dashboard/month.png">
                        </div>
                        <div class="ml-2 mr-10 text-header-title">สถิติประจำเดือน</div>
                    </div>
                    <v-row>
                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                            <v-card class="input_select" color="#EBF8FF" elevation="3">
                                <v-card-text>
                                    <div class="text_content">
                                        <div class="font-weight-bold">การไปส่งคนไข้</div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="image_title">
                                            <img src="@/assets/images/dashboard/ambulance-lights.png">
                                        </div>
                                        <div>
                                            <div class="text_count" style="display:flex;align-items:center;">{{
                                                formatNumber(searchAmbulance.count_people) }} <span class="ml-3">คน</span>
                                            </div>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                            <v-card class="input_select" color="#EBF8FF" elevation="3">
                                <v-card-text>
                                    <div class="text_content">
                                        <div class="font-weight-bold">สถิติช่องทางการจอง</div>
                                    </div>
                                    <v-row>
                                        <v-col cols="2">
                                            <div class="image_title d-flex align-items-center">
                                                <img src="@/assets/images/dashboard/booking.png">
                                            </div>
                                        </v-col>
                                        <v-col cols="10">
                                            <div class="d-flex justify-space-between">
                                                <div class="text_content">
                                                    <div class="ml-2 mr-10">จากไลน์</div>
                                                </div>
                                                <div>
                                                    <div class="text_count">{{ formatNumber(searchAmbulance.count_line) }}
                                                        <span class="ml-3">คน</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-space-between">
                                                <div class="text_content">
                                                    <div class="ml-2 mr-10">โดยเจ้าหน้าที่</div>
                                                </div>
                                                <div>
                                                    <div class="text_count">{{ formatNumber(searchAmbulance.count_admin) }}
                                                        <span class="ml-3">คน</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col xs="12" sm="12" md="8" lg="9" xl="9" cols="12">
                    <v-row>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                            <div class="text_title mb-10">
                                <div class="image_title">
                                    <img src="@/assets/images/dashboard/hospital-building.png">
                                </div>
                                <div class="ml-2 mr-10 text-header-title">สถิติการไปส่งคนไข้</div>
                            </div>
                        </v-col>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                            <div class="d-flex" style="justify-content:flex-end;">
                                <div class="mr-2">
                                    <v-select class="input_select" v-model="searchAmbulance.currentMonthAmbulance"
                                        :items="monthList" item-text="name" item-value="id" label="เลือกเดือน"
                                        @change="Countambulance()" prepend-inner-icon="mdi-calendar-clock"
                                        hide-details="auto" solo required></v-select>
                                </div>
                                <div>
                                    <v-select class="input_select" v-model="searchAmbulance.currentYearAmbulance"
                                        :items="yearList" item-text="name" item-value="id" label="เลือกปี"
                                        @change="Countambulance()" prepend-inner-icon="mdi-calendar-clock"
                                        hide-details="auto" solo required></v-select>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <div style="height: 40vh !important;">
                        <canvas id="myChartAppointment"></canvas>
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- รายงานเว็บไซต์ -->
        <div class="card_heading_data d-flex justify-space-between align-center">
            <div class="card_text_heading_data mtb-10">รายงานเว็บไซต์</div>
        </div>

        <div class="card_data_list">
            <v-row class="mb-3">
                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <div class="text_title mb-10">
                        <div class="image_title">
                            <img src="@/assets/images/dashboard/article.png">
                        </div>
                        <div class="ml-2 mr-10 text-header-title">สาระน่ารู้</div>
                    </div>
                </v-col>
                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                    <v-row>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                            <div class="d-flex justify-space-between" style="align-items:center;">
                                <div class="image_title">
                                    <img src="@/assets/images/dashboard/open-book.png">
                                </div>
                                <div class="ml-2 mr-10 text-header-title">บทความทั้งหมด</div>
                                <div>
                                    <div class="text_count">{{ formatNumber(count_content) }} <span
                                            class="ml-3">รายการ</span></div>
                                </div>
                            </div>
                        </v-col>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                            <div class="d-flex justify-space-between" style="align-items:center;">
                                <div class="image_title">
                                    <img src="@/assets/images/dashboard/visibility.png">
                                </div>
                                <div class="ml-2 mr-10 text-header-title">ยอดเข้าชมรวม</div>
                                <div>
                                    <div class="text_count">{{ formatNumber(count_view) }} <span class="ml-3">ครั้ง</span>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col xs="12" sm="12" md="6" lg="3" xl="3" cols="12" v-for="(content, index) in content_category_list"
                    :key="index">
                    <v-card class="input_select" elevation="3" height="156">
                        <v-card-text>
                            <div class="text_content">
                                <div class="font-weight-bold">{{ content.category_content_name }}</div>
                            </div>
                            <v-row>
                                <v-col cols="2">
                                    <div class="image_title">
                                        <img v-if="index == 0" src="@/assets/images/dashboard/blood-pressure.png">
                                        <img v-if="index == 1" src="@/assets/images/dashboard/big-family.png">
                                        <img v-if="index == 2" src="@/assets/images/dashboard/pregnant.png">
                                        <img v-if="index == 3" src="@/assets/images/dashboard/chase.png">
                                        <img v-if="index == 4" src="@/assets/images/dashboard/tooth.png">
                                        <img v-if="index == 5" src="@/assets/images/dashboard/medical-protection.png">
                                        <img v-if="index == 6" src="@/assets/images/dashboard/drugs.png">
                                        <img v-if="index == 7" src="@/assets/images/dashboard/journalism.png">
                                    </div>
                                </v-col>
                                <v-col cols="10">
                                    <div class="d-flex justify-space-between">
                                        <div class="text_content">
                                            <div class="ml-2 mr-10">บทความ</div>
                                        </div>
                                        <div>
                                            <div class="text_count">{{ formatNumber(content.count_content) }} <span
                                                    class="ml-3">รายการ</span></div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="text_content">
                                            <div class="ml-2 mr-10">ยอดเข้าชมรวม</div>
                                        </div>
                                        <div>
                                            <div class="text_count mr-5">{{ formatNumber(content.count_view) }} <span
                                                    class="ml-3">ครั้ง</span>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col xs="12" sm="12" md="4" lg="3" xl="3" cols="12">
                    <div class="text_title mb-10">
                        <div class="image_title">
                            <img src="@/assets/images/dashboard/month.png">
                        </div>
                        <div class="ml-2 mr-10 text-header-title">สถิติประจำเดือน</div>
                    </div>
                    <v-row>
                        <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-card class="input_select" color="#EBF8FF" elevation="3">
                                <v-card-text>
                                    <div class="text_content">
                                        <div class="font-weight-bold">ยอดเข้าชม</div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="image_title">
                                            <img src="@/assets/images/dashboard/visibility.png">
                                        </div>
                                        <div>
                                            <div class="text_count" style="display:flex;align-items:center;">{{
                                                formatNumber(searchContent.count_visitor) }} <span class="ml-3">คน</span>
                                            </div>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col xs="12" sm="12" md="8" lg="9" xl="9" cols="12">
                    <v-row>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                            <div class="text_title mb-10">
                                <div class="image_title">
                                    <img src="@/assets/images/dashboard/web-traffic.png">
                                </div>
                                <div class="ml-2 mr-10 text-header-title">ยอดผู้เข้าชมเว็บไซต์</div>
                            </div>
                        </v-col>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                            <div class="d-flex" style="justify-content:flex-end;">
                                <div class="mr-2">
                                    <v-select class="input_select" v-model="searchContent.currentMonthContent"
                                        :items="monthList" item-text="name" item-value="id" label="เลือกเดือน"
                                        @change="Countcontent()" prepend-inner-icon="mdi-calendar-clock" hide-details="auto"
                                        solo required></v-select>
                                </div>
                                <div>
                                    <v-select class="input_select" v-model="searchContent.currentYearContent"
                                        :items="yearList" item-text="name" item-value="id" label="เลือกปี"
                                        @change="Countcontent()" prepend-inner-icon="mdi-calendar-clock" hide-details="auto"
                                        solo required></v-select>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <div style="height: 40vh !important;">
                        <canvas id="myChartVisiter"></canvas>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import ChartNotify from "chart.js/auto";
import ChartAppointment from "chart.js/auto";
import ChartVisiter from "chart.js/auto";
import dashboard from '@/api/dashboard/dashboard.js'
import { base64Encode, base64Decode } from '@/helpers/base64.js'
import { formatNumber } from '@/helpers/numberformats.js'
export default {
    data() {
        return {
            loading_page: false,
            round_list: [
                { id: 1, round: 'รายสัปดาห์' },
                { id: 2, round: 'รายวัน' },
            ],
            myChartNotify: null,
            myChartAppointment: null,
            myChartVisiter: null,
            monthData: 1,
            monthList: [
                { id: 1, name: 'มกราคม' },
                { id: 2, name: 'กุมภาพันธ์' },
                { id: 3, name: 'มีนาคม' },
                { id: 4, name: 'เมษายน' },
                { id: 5, name: 'พฤษภาคม' },
                { id: 6, name: 'มิถุนายน' },
                { id: 7, name: 'กรกฎาคม' },
                { id: 8, name: 'สิงหาคม' },
                { id: 9, name: 'กันยายน' },
                { id: 10, name: 'ตุลาคม' },
                { id: 11, name: 'พฤศจิกายน' },
                { id: 12, name: 'ธันวาคม' },
            ],
            yearList: [
                { id: 2023, name: '2566' },
                { id: 2022, name: '2565' },
                { id: 2021, name: '2564' },
                { id: 2020, name: '2563' },
            ],
            searchNoti: {
                currentYearNoti: null,
                currentMonthNoti: new Date(),
                count_send_noti: null,
                count_get_noti: null,
                people_list: [],
            },
            searchAmbulance: {
                currentYearAmbulance: null,
                currentMonthAmbulance: new Date(),
                count_people: null,
                count_line: null,
                count_admin: null,
                ambulance_list: [],
            },
            searchContent: {
                currentYearContent: null,
                currentMonthContent: new Date(),
                count_visitor: null,
                day_list: [],
            },
            dayList: null,
            countPeopleList: null,

            ambulancedayList: null,
            ambulancecountPeopleList: null,

            contentdayList: null,
            contentcountPeopleList: null,

            count_send_noti: null,
            count_people: '',
            count_people_have_noti: null,
            count_people_not_have_noti: null,
            count_que_ambulance: null,
            count_que_am_line: null,
            count_que_am_admin: null,
            people_patient: null,
            people_normal: null,
            drop_off_list: [],
            count_content: null,
            count_view: null,
            content_category_list: [],

        }
    },
    methods: {
        link(link) {
            this.$router.push(link);
        },
        async DashboardList() {
            this.loading_page = true
            dashboard.getDashboard().then((response) => {
                if (response.data.successful === true) {
                    this.count_send_noti = response.data.count_send_noti
                    this.count_people = response.data.count_people
                    this.count_people_have_noti = response.data.count_people_have_noti
                    this.count_people_not_have_noti = response.data.count_people_not_have_noti
                    this.count_que_ambulance = response.data.count_que_ambulance
                    this.count_que_am_line = response.data.count_que_am_line
                    this.count_que_am_admin = response.data.count_que_am_admin
                    this.people_patient = response.data.people_patient
                    this.people_normal = response.data.people_normal
                    this.count_content = response.data.count_content
                    this.count_view = response.data.count_view
                    this.drop_off_list = response.data.drop_off_list
                    this.content_category_list = response.data.content_category_list
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async CountNotification() {
            this.loading_page = true
            this.searchNoti.people_list = []
            let dayList = []
            let countPeopleList = []
            dashboard.getcountnotification(this.searchNoti.currentYearNoti, this.searchNoti.currentMonthNoti).then((response) => {
                if (response.data.successful === true) {
                    this.searchNoti.count_send_noti = response.data.count_send_noti
                    this.searchNoti.count_get_noti = response.data.count_get_noti
                    this.searchNoti.people_list = response.data.people_list
                    // ผู้ป่วยได้รับการนัดหมาย
                    for (let index = 0; index < this.searchNoti.people_list.length; index++) {
                        dayList.push(parseFloat(this.searchNoti.people_list[index].day))
                        countPeopleList.push(parseFloat(this.searchNoti.people_list[index].count_people))
                    }
                    this.dayList = dayList;
                    this.countPeopleList = countPeopleList;
                    // สถิติการไปส่งคนไข้
                    this.chart_notify()
                    this.loading_page = false

                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async Countambulance() {
            this.loading_page = true
            this.searchAmbulance.ambulance_list = []
            this.searchAmbulance.count_people = []
            let dayList = []
            let countPeopleList = []
            dashboard.getcountambulance(this.searchAmbulance.currentYearAmbulance, this.searchAmbulance.currentMonthAmbulance).then((response) => {
                if (response.data.successful === true) {
                    this.searchAmbulance.count_people = response.data.count_people
                    this.searchAmbulance.count_line = response.data.count_line
                    this.searchAmbulance.count_admin = response.data.count_admin
                    this.searchAmbulance.ambulance_list = response.data.ambulance_list
                    // สถิติการไปส่งคนไข้
                    for (let index = 0; index < this.searchAmbulance.ambulance_list.length; index++) {
                        dayList.push(parseFloat(this.searchAmbulance.ambulance_list[index].day))
                        countPeopleList.push(parseFloat(this.searchAmbulance.ambulance_list[index].count_people))
                    }
                    this.ambulancedayList = dayList;
                    this.ambulancecountPeopleList = countPeopleList;
                    this.chart_appointment()
                    this.loading_page = false

                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async Countcontent() {
            this.loading_page = true
            this.searchContent.day_list = []
            let dayList = []
            let countPeopleList = []
            dashboard.getcontent(this.searchContent.currentYearContent, this.searchContent.currentMonthContent).then((response) => {
                if (response.data.successful === true) {
                    this.searchContent.count_visitor = response.data.count_visitor
                    this.searchContent.day_list = response.data.day_list
                    // สถิติการไปส่งคนไข้
                    for (let index = 0; index < this.searchContent.day_list.length; index++) {
                        dayList.push(parseFloat(this.searchContent.day_list[index].day))
                        countPeopleList.push(parseFloat(this.searchContent.day_list[index].count_people))
                    }
                    this.contentdayList = dayList
                    this.contentcountPeopleList = countPeopleList
                    this.chart_visiter()
                    this.loading_page = false

                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        getMonth(i) {
            switch (i) {
                case '01': return 'มกราคม';
                case '02': return 'กุมภาพันธ์';
                case '03': return 'มีนาคม';
                case '04': return 'เมษายน';
                case '05': return 'พฤษภาคม';
                case '06': return 'มิถุนายน';
                case '07': return 'กรกฎาคม';
                case '08': return 'สิงหาคม';
                case '09': return 'กันยายน';
                case '10': return 'ตุลาคม';
                case '11': return 'พฤศจิกายน';
                case '12': return 'ธันวาคม';
            }
        },
        base64Encode(id) {
            return base64Encode(id)
        },
        base64Decode(id) {
            return base64Decode(id)
        },
        formatNumber(evt) {
            return formatNumber(evt)
        },
        chart_notify() {
            const data = {
                labels: this.dayList,
                datasets: [{
                    label: 'ผู้ป่วยได้รับการนัดหมาย',
                    data: this.countPeopleList,
                    fill: false,
                    borderColor: '#4EACDF',
                    tension: 0.1
                }]
            };
            const config = {
                type: 'line',
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true
                        },
                        y: {
                            stacked: true
                        }
                    }
                },
                data: data,
            };
            if (this.ChartNotify) {
                this.ChartNotify.destroy();
            }
            const ctx = document.getElementById('myChartNotify');
            this.ChartNotify = new ChartNotify(ctx, config)
        },
        chart_appointment() {
            const data = {
                labels: this.ambulancedayList,
                datasets: [{
                    label: 'ผู้ป่วยได้รับการนัดหมาย',
                    data: this.ambulancecountPeopleList,
                    fill: false,
                    borderColor: '#151B54',
                    tension: 0.1
                }]
            };
            const config = {
                type: 'line',
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true
                        },
                        y: {
                            stacked: true
                        }
                    }
                },
                data: data,
            };
            if (this.ChartAppointment) {
                this.ChartAppointment.destroy();
            }
            const ctx = document.getElementById('myChartAppointment');
            this.ChartAppointment = new ChartAppointment(ctx, config)
        },
        chart_visiter() {
            const data = {
                labels: this.contentdayList,
                datasets: [{
                    label: 'ผู้ป่วยได้รับการนัดหมาย',
                    data: this.contentcountPeopleList,
                    fill: false,
                    borderColor: '#1A977E',
                    tension: 0.1
                }]
            };
            const config = {
                type: 'line',
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true
                        },
                        y: {
                            stacked: true
                        }
                    }
                },
                data: data,
            };
            if (this.ChartVisiter) {
                this.ChartVisiter.destroy();
            }
            const ctx = document.getElementById('myChartVisiter');
            this.ChartVisiter = new ChartVisiter(ctx, config)
        },
    },
    mounted() {
        this.DashboardList()
        this.CountNotification()
        this.Countambulance()
        this.Countcontent()
    },
    created() {
        const currentDate = new Date();
        this.searchNoti.currentYearNoti = currentDate.getFullYear();
        this.searchNoti.currentMonthNoti = currentDate.getMonth() + 1;
        this.searchAmbulance.currentYearAmbulance = currentDate.getFullYear();
        this.searchAmbulance.currentMonthAmbulance = currentDate.getMonth() + 1;
        this.searchContent.currentMonthContent = currentDate.getMonth() + 1;
        this.searchContent.currentYearContent = currentDate.getFullYear();
    },

    watch: {
        page() {
            this.DashboardList()
            this.CountNotification()
            this.Countambulance()
            this.Countcontent()
        }
    }
}
</script>
<style scoped>
.button_heading {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 21px;
    color: #FFFFFF !important;
    background: linear-gradient(90deg, #4597C3 0%, #49A1D0 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    padding: 0 25px !important;
}

.button_text_heading {
    margin-left: 5px;
}

.card_data_list {
    background: #FFFFFF !important;
}

.text-header-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 20.25%)
}

.text_title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: center;
}

.text_content {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #151B54;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.text_count {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: baseline;
}

.text_count>span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #686868;
}

.image_title {
    width: 60px;
}

.image_title>img {
    max-width: 100%;
}

.input_detail_title {
    border-radius: 10px;
    max-width: 30% !important;
}

@media only screen and (max-width: 800px) {
    .button_heading {
        width: 100%;
    }
}
</style>