import { render, staticRenderFns } from "./ContentKnowledgeList.vue?vue&type=template&id=5da1ce23&scoped=true&"
import script from "./ContentKnowledgeList.vue?vue&type=script&lang=js&"
export * from "./ContentKnowledgeList.vue?vue&type=script&lang=js&"
import style0 from "./ContentKnowledgeList.vue?vue&type=style&index=0&id=5da1ce23&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5da1ce23",
  null
  
)

export default component.exports