import UserProfile from '@/views/user_profile/UserProfile.vue'

export default [
    {
        path: '/userprofile',
        name: 'userProfile',
        component: UserProfile,
    },
]

