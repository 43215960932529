<template>
    <div>
        <div class="card_heading_page_detail_data d-flex align-center">
            <div class="card_text_heading_data">รถพยาบาล</div>
        </div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_data_list">
            <v-row>
                <v-col cols="12" xs="12" md="12" lg="6" xl="6">
                    <v-row>
                        <v-col cols="12" xs="12" md="12" lg="4" xl="4">
                            <div class="text_title">
                                <div class="image_title">
                                    <img src="@/assets/images/ambulancequeue/calendar.png">
                                </div>
                                <div class="ml-2 mr-10">จำนวนรถพยาบาล</div>
                            </div>
                        </v-col>
                        <v-col cols="12" xs="12" md="12" lg="8" xl="8">
                            <div class="text_title">
                                <div class="ml-5 mr-2 " @click="ambulanceList.ambulance_count > 1 ? ambulanceList.ambulance_count = parseFloat(ambulanceList.ambulance_count) - 1 : '',updateAmbulance()">
                                    <div class="image_title">
                                        <img src="@/assets/images/ambulancequeue/minus 3.png">
                                    </div>
                                </div>
                                <b>{{ambulanceList.ambulance_count}}</b>
                                <div class="ml-5 mr-2" @click="ambulanceList.ambulance_count = parseFloat(ambulanceList.ambulance_count) + 1, updateAmbulance()">
                                    <div class=" image_title">
                                        <img src="@/assets/images/ambulancequeue/add 1.png">
                                    </div>
                                </div>
                                <div class="ml-3 mr-2">
                                    <b>คัน</b>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" xs="12" md="12" lg="6" xl="6" style="border-left: 1px solid #DADADA;">
                    <div class="text_title">
                        <div class="image_title">
                            <img src="@/assets/images/ambulancequeue/calendar 2.png">
                        </div>
                        <div class="ml-2 mr-10">จำนวนผู้ป่วยสูงสุดต่อรอบ</div>
                    </div>
                    <br>
                    <v-row>
                        <v-col cols="12" md="8" lg="8" xl="8">
                            <div class="text_title">
                                <div class="ml-2 " style="font-size:16px;color:#1E1E1E;margin-right: 50px;">ผู้ป่วยติดเตียง</div>
                                <div class="ml-5 mr-2 " @click="ambulanceList.bedridden_patient > 1 ? ambulanceList.bedridden_patient = parseFloat(ambulanceList.bedridden_patient) - 1 : '', updateAmbulance()">
                                    <div class="image_title">
                                        <img src="@/assets/images/ambulancequeue/minus 3.png">
                                    </div>
                                </div>
                                <div style="font-size:16px;color:#1E1E1E">{{ambulanceList.bedridden_patient}}</div>
                                <div class="ml-5 mr-2" @click="ambulanceList.bedridden_patient = parseFloat(ambulanceList.bedridden_patient) + 1, updateAmbulance()">
                                    <div class=" image_title">
                                        <img src="@/assets/images/ambulancequeue/add 1.png">
                                    </div>
                                </div>
                                <div class="ml-3 mr-2" style="font-size:16px;color:#1E1E1E">
                                    คนต่อคัน
                                </div>
                            </div>
                            <div class="text_title">
                                <div class="ml-2 " style="font-size:16px;color:#1E1E1E;margin-right: 70px;">ผู้ป่วยปกติ</div>
                                <div class="ml-5 mr-2 " @click="ambulanceList.patient > 1 ? ambulanceList.patient = parseFloat(ambulanceList.patient) - 1 : '', updateAmbulance()">
                                    <div class="image_title">
                                        <img src="@/assets/images/ambulancequeue/minus 3.png">
                                    </div>
                                </div>
                                <div style="font-size:16px;color:#1E1E1E">{{ambulanceList.patient}}</div>
                                <div class="ml-5 mr-2" @click="ambulanceList.patient = parseFloat(ambulanceList.patient) + 1, updateAmbulance()">
                                    <div class=" image_title">
                                        <img src="@/assets/images/ambulancequeue/add 1.png">
                                    </div>
                                </div>
                                <div class="ml-3 mr-2" style="font-size:16px;color:#1E1E1E">
                                    คนต่อคัน
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" xs="12" md="12" lg="12" xl="12">
                    <div class="float:right;" style="font-size:16px;color:#7E7E7E;float: right;">อัพเดทล่าสุด โดย {{ambulanceList.user_edit != null ? ambulanceList.first_name+' '+ambulanceList.last_name : '-'}} {{ambulanceList.update_at != null ? `${ambulanceList.update_at.split('-')[2].split(' ')[0]}/${ambulanceList.update_at.split('-')[1]}/${parseInt(ambulanceList.update_at.split('-')[0])+543} ${ambulanceList.update_at.split('-')[2].split(' ')[1]}` : '-'}}</div>
                </v-col>
            </v-row>
        </div>

        <div class="card_heading_page_detail_data d-flex align-center">
            <div class="card_text_heading_data">รอบการรับ-ส่ง</div>
        </div>
        <div class="card_data_list">
            <v-row>
                <v-col cols="12" xs="12" md="12" lg="3" xl="3" style="border-right: 1px solid #DADADA;" v-if="status == 1">
                    <div class="text_title mb-8">
                        <div class="image_title">
                            <img src="@/assets/images/ambulancequeue/day-off 1.png">
                        </div>
                        <div class="ml-2 mr-10">เพิ่มรอบการรับ - ส่ง </div>
                    </div>
                    <v-form ref="form" @submit.prevent="saveData()">
                        <div class="detail_title">
                            <div>ชื่อรอบ <span class="error--text">*</span></div>
                            <v-text-field class="input_detail_title" :rules="[v => !!v || 'กรุณากรอกชื่อรอบ']" v-model="name_round"
                                label="กรุณากรอกชื่อรอบ" solo required></v-text-field>
                        </div>
                        <div class="detail_title">
                            <div>เวลาไปส่ง <span class="error--text">*</span></div>
                            <v-text-field class="input_detail_title" :rules="[v => !!v || 'กรุณากรอกเวลาการไปส่ง']" v-model="delivery_time"
                                label="กรุณากรอกเวลาการไปส่ง" solo required></v-text-field>
                        </div>
                        <div class="detail_title">
                            <div>เวลาไปรับ <span class="error--text">*</span></div>
                            <v-text-field class="input_detail_title" :rules="[v => !!v || 'กรุณากรอกเวลาการไปรับ']" v-model="pick_up_time"
                                label="กรุณากรอกเวลาการไปรับ" solo required></v-text-field>
                        </div>
                        <div class="text-center">
                            <v-btn class="white--text button_save" type="submit" height="43" width="50%"
                                color="#37AE46">บันทึก</v-btn>
                        </div>
                    </v-form>
                </v-col>
                <v-col cols="12" xs="12" md="12" lg="3" xl="3" style="border-right: 1px solid #DADADA;" v-else>
                    <div class="text_title mb-8">
                        <div class="image_title">
                            <img src="@/assets/images/ambulancequeue/day-off 1.png">
                        </div>
                        <div class="ml-2 mr-10">แก้ไขรอบการรับ - ส่ง </div>
                    </div>
                    <v-form ref="form1" @submit.prevent="updateData()">
                        <div class="detail_title">
                            <div>ชื่อรอบ <span class="error--text">*</span></div>
                            <v-text-field class="input_detail_title" :rules="[v => !!v || 'กรุณากรอกชื่อรอบ']" v-model="pickuproundList_edit.name_round"
                                label="กรุณากรอกชื่อรอบ" solo required></v-text-field>
                        </div>
                        <div class="detail_title">
                            <div>เวลาไปส่ง <span class="error--text">*</span></div>
                            <v-text-field class="input_detail_title" :rules="[v => !!v || 'กรุณากรอกเวลาการไปส่ง']" v-model="pickuproundList_edit.delivery_time"
                                label="กรุณากรอกเวลาการไปส่ง" solo required></v-text-field>
                        </div>
                        <div class="detail_title">
                            <div>เวลาไปรับ <span class="error--text">*</span></div>
                            <v-text-field class="input_detail_title" :rules="[v => !!v || 'กรุณากรอกเวลาการไปรับ']" v-model="pickuproundList_edit.pick_up_time"
                                label="กรุณากรอกเวลาการไปรับ" solo required></v-text-field>
                        </div>
                        <div class="text-center">
                            <v-btn class="white--text button_save" type="submit" height="43" width="50%"
                                color="#37AE46">บันทึก</v-btn>
                        </div>
                    </v-form>
                </v-col>
                <v-col cols="12" xs="12" md="12" lg="9" xl="9">
                    <div class="text_title mb-8">
                        <div class="ml-2 mr-10">รายการรอบการ รับ - ส่ง</div>
                    </div>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="title_table_data_list text-center">
                                    ลำดับ
                                </th>
                                <th class="title_table_data_list">
                                    ชื่อรอบ
                                </th>
                                <th class="title_table_data_list text-center">
                                    เวลาไปส่ง
                                </th>
                                <th class="title_table_data_list text-center">
                                    เวลาไปรับ
                                </th>
                                <th class="title_table_data_list text-center">
                                    ผู้ดำเนินการ
                                </th>
                                <th class="title_table_data_list">
                                    จัดการ
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in pickuproundList" :key="item">
                                <td class="detail_table_data_list text-center">{{index+1}}</td>
                                <td class="detail_table_data_list">{{item.name_round}}</td>
                                <td class="detail_table_data_list text-center">{{item.delivery_time}}</td>
                                <td class="detail_table_data_list text-center">{{item.pick_up_time}}</td>
                                <td class="detail_table_data_list text-center">{{item.first_name}} {{item.last_name}}</td>
                                <td>
                                    <v-btn class="button_tool_data_list mr-2 my-3" height="43" @click="getPickUpDetail(item.id)"
                                        color="#FD8F16"><v-icon>mdi-pencil</v-icon>
                                        <div>แก้ไข</div>
                                    </v-btn>
                                    <v-btn class="button_tool_data_list" @click="openConfirmDelete(item.id)" height="43"
                                        color="#B3001B"><v-icon>mdi-delete</v-icon>
                                        <div>ลบ</div>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-pagination class="pagination_data_list" v-model="page" color="#4EACDF"
                        :length="pages"></v-pagination>
                </v-col>
            </v-row>
            <v-dialog v-model="open_confirm_delete" persistent max-width="600px">
                <v-card class="page_confirm_delete_all">
                    <v-card-title></v-card-title>
                    <v-card-text>

                        <v-row>
                            <v-col cols="12">

                                <div class="image_confirm_delete">
                                    <img src="@/assets/images/trash_confirm_delete.jpg" alt="">
                                </div>
                                <div class="text_confirm_delete">คุณแน่ใจใช่ไหมที่จะทำการลบข้อมูล?</div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions class="layout_button_text_confirm_delete">
                        <v-btn class="button_text_confirm_delete" height="43" width="40%" color="#4EACDF" @click="deletePickUp()">ใช่ ฉันต้องการลบ 
                            !</v-btn>
                        <v-btn class="button_text_confirm_delete" @click="cancelDeleteUser()" height="43" width="40%"
                            color="#414141">ยกเลิก</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { base64Encode, base64Decode } from '@/helpers/base64.js'
import ambulancequeue from '@/api/hospital/ambulancequeue/ambulancequeue.js'
export default {
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            open_confirm_notification_all: false,
            notification_id: null,
            open_confirm_delete: false,
            delete_id: null,
            search: '',
            data: [],
            page: 1,
            size: 15,
            pages: 1,
            ambulanceList: [],
            pickuproundList: [],
            pickuproundList_edit: {},
            name_round: null,
            delivery_time: null,
            pick_up_time: null,
            status: 1,
        }
    },
    methods: {
        base64Encode(id) {
            return base64Encode(id)
        },
        base64Decode(id) {
            return base64Decode(id)
        },
        async openConfirmDelete(id) {
            this.delete_id = id
            this.open_confirm_delete = true
        },
        async cancelDeleteUser() {
            this.delete_id = null
            this.open_confirm_delete = false
        },
        async getAmbulance(){
             await ambulancequeue.getAmbulance().then((response) => {
                    if (response.data.successful === true) {
                        this.ambulanceList = response.data.ambulanceList
                    }
             })
        },
        async getPickUp(){
             await ambulancequeue.getPickUp().then((response) => {
                    if (response.data.successful === true) {
                        this.pickuproundList = response.data.pickuproundList
                    }
             })
        },
        async getPickUpDetail(id){
             await ambulancequeue.getPickUpDetail(id).then((response) => {
                    if (response.data.successful === true) {
                        this.pickuproundList_edit = response.data.pickuproundList_edit
                        this.status = 2
                    }
             })
        },
        async updateAmbulance(){
            let id = this.ambulanceList.id;
            let data = {
                ambulance_count: this.ambulanceList.ambulance_count,
                bedridden_patient: this.ambulanceList.bedridden_patient,
                patient: this.ambulanceList.patient
            }
             await ambulancequeue.updateAmbulance(id, data).then((response) => {
                    if (response.data.successful === true) {
                       this.getAmbulance()
                    }
             })
        },
       async saveData(){
            if(this.$refs.form.validate()){
            let data = {
                name_round: this.name_round,
                delivery_time: this.delivery_time,
                pick_up_time: this.pick_up_time
            }
             await ambulancequeue.saveData(data).then((response) => {
                    if (response.data.successful === true) {
                        this.name_round = null
                        this.delivery_time = null
                        this.pick_up_time = null
                        this.alert_success = true;
                        this.alert_message_success = 'เพิ่มข้อมูลเรียบร้อย';
                         setTimeout(() => this.alert_success = false, 3000)
                       this.getPickUp()
                    }
             })
            }            
        },
       async updateData(){
            if(this.$refs.form1.validate()){
                 let id = this.pickuproundList_edit.id;
            let data = {
                name_round: this.pickuproundList_edit.name_round,
                delivery_time: this.pickuproundList_edit.delivery_time,
                pick_up_time: this.pickuproundList_edit.pick_up_time
            }
             await ambulancequeue.updateData(id, data).then((response) => {
                    if (response.data.successful === true) {
                        this.name_round = null
                        this.delivery_time = null
                        this.pick_up_time = null
                        this.status = 1
                         this.alert_success = true;
                        this.alert_message_success = 'แก้ไขข้อมูลเรียบร้อย';
                         setTimeout(() => this.alert_success = false, 3000)
                       this.getPickUp()
                    }
             })
            }            
        },
       async deletePickUp(){
             await ambulancequeue.deletePickUp(this.delete_id).then((response) => {
                    if (response.data.successful === true) {
                        this.alert_success = true;
                        this.alert_message_success = 'ลบข้อมูลเรียบร้อย';
                         setTimeout(() => this.alert_success = false, 3000)
                       this.getPickUp()
                       this.cancelDeleteUser()
                    }
             })         
        }
    },
    mounted() {
        // this.userList()
        this.getAmbulance()
        this.getPickUp()
    },
    // watch: {
    //     page() {
    //         this.userList()
    //     }
    // }
}
</script>

<style scoped>
.card_data_list {
    background: #FFFFFF !important;
}

.text_title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: center;
}

.image_title {
    width: 34px;
}

.image_title>img {
    max-width: 100%;
}</style>