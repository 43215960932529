<template>
  <div>
    <div class="d-flex justify-space-between pb-6 heading_all">
      <div class="d-flex align-center">
        <div class="heading">{{ heading }}</div>
        <div class="line_heading mx-4"></div>
        <div class="sub_heading">{{ sub_heading }}</div>
        <div class="sub_secondary_heading" v-if="sub_secondary_heading"><v-icon class="d-inline-block"
            size="30">mdi-circle-small</v-icon>{{ sub_secondary_heading }}</div>
      </div>
      <div v-if="button_link_url && button_link_name">
        <v-btn v-if="!button_link_redirect" class="button_heading" height="62"
          @click="$router.push(button_link_url)"><v-icon>mdi-plus</v-icon>
          <div class="button_text_heading">{{ button_link_name }}</div>
        </v-btn>
        <v-btn v-if="button_link_redirect" class="button_heading" height="62"
          @click="link_redirect(button_link_url)"><v-icon>mdi-plus</v-icon>
          <div class="button_text_heading">{{ button_link_name }}</div>
        </v-btn>
      </div>
      
      <!-- คิวนัดหมาย -->
      <div v-if="dialog_link_type && dialog_link_type == 'viewlistappointmentall'">
        <v-btn class="button_heading" height="62" @click="dialog_select_manage_people = true"><v-icon>mdi-plus</v-icon>
          <div class="button_text_heading">{{ dialog_link_name }}</div>
        </v-btn>
      </div>
      
      <v-dialog v-if="dialog_link_type && dialog_link_type == 'viewlistappointmentall'"
        v-model="dialog_select_manage_people" persistent max-width="600px">
        <v-card class="main_card_dialog">
          <div class="d-flex justify-space-between">
            <v-card-title>
              <div class="title_dialog">{{ dialog_link_name }}</div>
            </v-card-title>
            <v-btn icon class="ma-4" @click="dialog_select_manage_people = false">
              <v-icon color="#000000" size="30">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-card class="card_all_detail_dialog d-flex align-center" height="100%"
                  @click="$router.push('/addappointment')">
                  <v-card-text class="all_detail_dialog d-flex flex-column justify-space-between">
                    <div class="image_select_dialog">
                      <img src="@/assets/images/form_add.png" alt="กรอกข้อมูล">
                    </div>
                    <div class="title_detail_dialog">กรอกข้อมูล</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="card_all_detail_dialog d-flex align-center" height="100%"
                  @click="$router.push('/addappointmentexcel')">
                  <v-card-text class="all_detail_dialog d-flex flex-column justify-space-between">
                    <div class="image_select_dialog">
                      <img src="@/assets/images/form_add_excel.png" alt="ดึงข้อมูลจากไฟล์ excel">
                    </div>
                    <div class="title_detail_dialog">ดึงข้อมูลจากไฟล์ excel</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- ประชาชน -->
      <div v-if="dialog_link_type && dialog_link_type == 'managepeople'">
        <v-btn class="button_heading" height="62" @click="dialog_select_manage_people = true"><v-icon>mdi-plus</v-icon>
          <div class="button_text_heading">{{ dialog_link_name }}</div>
        </v-btn>
      </div>
      <v-dialog v-if="dialog_link_type && dialog_link_type == 'managepeople'" v-model="dialog_select_manage_people"
        persistent max-width="600px">
        <v-card class="main_card_dialog">
          <div class="d-flex justify-space-between">
            <v-card-title>
              <div class="title_dialog">{{ dialog_link_name }}</div>
            </v-card-title>
            <v-btn icon class="ma-4" @click="dialog_select_manage_people = false">
              <v-icon color="#000000" size="30">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-card class="card_all_detail_dialog d-flex align-center" height="100%"
                  @click="$router.push('/addpeople')">
                  <v-card-text class="all_detail_dialog d-flex flex-column justify-space-between">
                    <div class="image_select_dialog">
                      <img src="@/assets/images/form_add.png" alt="กรอกข้อมูล">
                    </div>
                    <div class="title_detail_dialog">กรอกข้อมูล</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="card_all_detail_dialog d-flex align-center" height="100%"
                  @click="$router.push('/addpeopleexcel')">
                  <v-card-text class="all_detail_dialog d-flex flex-column justify-space-between">
                    <div class="image_select_dialog">
                      <img src="@/assets/images/form_add_excel.png" alt="ดึงข้อมูลจากไฟล์ excel">
                    </div>
                    <div class="title_detail_dialog">ดึงข้อมูลจากไฟล์ excel</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: ['heading', 'sub_heading', 'sub_secondary_heading', 'button_link_url', 'button_link_name', 'button_link_redirect', 'dialog_link_name', 'dialog_link_type',],
  data() {
    return {
      dialog_select_manage_people: false,
    }
  },
  methods: {
    async link_redirect(link) {
      window.location.href = link
    }
  }
}
</script>

<style scoped>
.heading {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}

.line_heading {
  border: 1px solid rgba(121, 118, 118, 0.5);
  height: 30px;
}

.sub_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #7E7E7E;
}

.sub_secondary_heading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #7E7E7E;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_heading {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 21px;
  color: #FFFFFF !important;
  background: linear-gradient(90deg, #151B54 0%, #4495C1 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding: 0 25px !important;
}

.button_text_heading {
  margin-left: 5px;
}

.title_dialog {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #414141;
}

.main_card_dialog {
  border-radius: 10px !important;
}

.card_all_detail_dialog {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
}

.card_all_detail_dialog:hover {
  background: #4EACDF;
}

.card_all_detail_dialog:hover>>>.title_detail_dialog {
  color: #FFFFFF;
}

.all_detail_dialog {
  height: 100%;
}

.title_detail_dialog {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #4D4A4A;
  text-align: center;
  height: 50%;
}

.image_select_dialog {
  margin: 0 auto 10px;
}

.image_select_dialog>img {
  max-width: 100%;
}

@media only screen and (max-width: 800px) {
  .heading_all {
    flex-direction: column;
  }

  .heading_all>div:nth-child(2) {
    margin-top: 15px;
  }

  .heading {
    font-size: 20px;
  }

  .sub_heading {
    font-size: 14px;
  }

  .sub_secondary_heading {
    font-size: 14px;
  }

  .button_heading {
    width: 100%;
  }
}
</style>