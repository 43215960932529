<template>
    <div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <!-- รายงานการนัดหมาย -->
        <div class="card_heading_data d-flex justify-space-between align-center">
            <div class="card_text_heading_data mtb-10">รายการแจ้งเตือน</div>
            <div class="card_search_heading_data">
                <v-text-field class="input_search" v-model="search" @keyup="searchData()" label="ค้นหา"
                    prepend-inner-icon="mdi-magnify" hide-details="auto" solo required></v-text-field>
            </div>
        </div>

        <div class="card_data_list">
            <v-row class="mb-3">
                <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="title_table_data_list">
                                    #
                                </th>
                                <th class="title_table_data_list">
                                    วันที่สร้างการแจ้งเตือน
                                </th>
                                <th class="title_table_data_list">
                                    ชื่อ-สกุล
                                </th>
                                <th class="title_table_data_list">
                                    วันนัดหมาย
                                </th>
                                <th class="title_table_data_list">
                                    เวลา
                                </th>
                                <th class="title_table_data_list">
                                    แพทย์ผู้นัดหมาย
                                </th>
                                <th class="title_table_data_list">
                                    หมายเหตุ
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="items, index in data" :key="index">
                                <td class="detail_table_data_list">{{ index + 1 }}</td>
                                <td class="detail_table_data_list">{{ items.date_send_noti }}</td>
                                <td class="detail_table_data_list">{{ items.name }}</td>
                                <td class="detail_table_data_list">{{ items.appointment_date }}</td>
                                <td class="detail_table_data_list">{{ items.meeting_time }}</td>
                                <td class="detail_table_data_list">{{ items.medic }}</td>
                                <td class="detail_table_data_list">{{ items.note }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-pagination class="pagination_data_list" v-model="page" color="#4EACDF" :length="pages"
                        :total-visible="7"></v-pagination>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { base64Encode, base64Decode } from '@/helpers/base64.js'
import { formatNumber } from '@/helpers/numberformats.js'
import dashboard from '@/api/dashboard/dashboard.js'
export default {
    data() {
        return {
            loading_page: false,
            data: [],
            page: 1,
            size: 50,
            pages: 1,
            search: '',
        }
    },
    methods: {
        link(link) {
            this.$router.push(link);
        },
        base64Encode(id) {
            return base64Encode(id)
        },
        base64Decode(id) {
            return base64Decode(id)
        },
        formatNumber(evt) {
            return formatNumber(evt)
        },
        async searchData() {
            this.data = []
            dashboard.getdetailnotification(this.page, this.size, this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.data
                    this.pages = response.data.pages
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            });
        }
    },
    mounted() {
        this.searchData()
    },
    created() {

    },
    watch: {
        page() {
            this.searchData()
        }
    }
}
</script>
<style scoped>
.button_heading {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 21px;
    color: #FFFFFF !important;
    background: linear-gradient(90deg, #4597C3 0%, #49A1D0 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    padding: 0 25px !important;
}

.button_text_heading {
    margin-left: 5px;
}

.card_data_list {
    background: #FFFFFF !important;
}

.text-header-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 20.25%)
}

.text_title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: center;
}

.text_content {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #151B54;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.text_count {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: baseline;
}

.text_count>span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #686868;
}

.image_title {
    width: 60px;
}

.image_title>img {
    max-width: 100%;
}

.input_detail_title {
    border-radius: 10px;
    max-width: 30% !important;
}

@media only screen and (max-width: 800px) {
    .button_heading {
        width: 100%;
    }
}
</style>