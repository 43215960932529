<template>
  <v-app>
    <Header/>
    <AboutList/>
  </v-app>
</template>

<script>
import Header from '@/components/line_oa/menu/Header'
import AboutList from '@/components/line_oa/about/AboutList'
export default {
  name: 'lineoaAbout',
  components:{
    Header,
    AboutList
  }
}
</script>