import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    getCheckAmbulanceQueue(data) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/queueambulance/queueambulanceCheck`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    getRound(data) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/queueambulance/check/round`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    getDetail(id) {
      return new Promise((resolve, reject) => {
          HTTP.get(`/queueambulance/${id}`, {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
            },
          })
            .then((response) => {
              const result = response;
              return resolve(result);
            })
            .catch((error) => {
              return reject(error);
            });
        });
  },
    saveData(data) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/queueambulance/queueambulanceSave`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    updateData(data) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/queueambulance/edit`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    // getCheckAmbulanceQueue: (data) => {
    //     return HTTP.post(`/queueambulance/queueambulanceCheck`, data)
    //     .then(response => response)
    // },
}