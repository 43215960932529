<template>
  <div>
    <Heading heading="เพิ่มผู้ใช้งาน" sub_heading="จัดการผู้ใช้งาน" sub_secondary_heading="รายการผู้ใช้งาน"/>
    <AddUserList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import AddUserList from '@/components/manage_users/manage_users/AddUserList'
export default {
  name: 'addUser',
  components:{
    Heading,
    AddUserList
  }
}
</script>