<template>
  <div>
    <Heading heading="แก้ไขผู้ใช้งาน" sub_heading="จัดการผู้ใช้งาน" sub_secondary_heading="รายการผู้ใช้งาน"/>
    <EditUserList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import EditUserList from '@/components/manage_users/manage_users/EditUserList'
export default {
  name: 'editUser',
  components:{
    Heading,
    EditUserList
  }
}
</script>