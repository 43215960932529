<template>
  <div>
    <Heading heading="ตั้งค่าการจองรถพยาบาล" sub_heading="ตั้งค่าการจองรถพยาบาล" />
    <AmbulancequeueList />
  </div>
</template>
  
<script>
import Heading from '@/components/heading/Heading'
import AmbulancequeueList from '@/components/hospital/ambulancequeue/Ambulancequeueall'
export default {
  name: 'ambulancequeueList',
  components: {
    Heading,
    AmbulancequeueList
  }
}
</script>