import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    adminList: (page='',size='',search='') => {
        return HTTP.get(`/users/adminlist?page=${page}&size=${size}&search=${search}`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    changeStatusAdmin: (id) => {
        return HTTP.post('/users/status/admin/' + id, null, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    deleteAdmin: (id) => {
        return HTTP.post('/users/admin/delete/' + id, null, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    addAdmin: (data) => {
        return HTTP.post('/users/admin/add', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    adminDetail: (id) => {
        return HTTP.get('/users/admin/detail/'+ id , {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    editAdmin: (id,data) => {
        return HTTP.post('/users/admin/edit/'+ id, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
}