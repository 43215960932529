<template>
    <div>        
        <div class="card_heading_data d-flex justify-space-between align-center">
            <div class="card_text_heading_data">รายการสาระน่ารู้</div>
            <div class="d-flex card_search_filter_heading_data">
                <div>
                    <v-select class="input_select" v-model="search_category_id" @change="searchCategory()" label="เลือกหมวด" :items="category_list" item-text="category_content_name" item-value="id"  prepend-inner-icon="mdi-filter-outline" hide-details="auto" solo required></v-select>
                </div>
                <div>
                    <v-text-field class="input_search" v-model="search" @keyup="searchData()" label="ค้นหา" prepend-inner-icon="mdi-magnify" hide-details="auto" solo required></v-text-field>
                </div>
            </div>
        </div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_data_list">
             <v-row class="mb-3">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" v-for="(detail,index) in data" :key="index">
                    <div class="card_all_content_knowledge">
                        <div class="card_image_content_knowledge">
                            <img :src="detail.content_picture" alt="">
                            <!-- <div class="d-flex justify-center align-center white--text py-1 px-2 view_count_content_knowledge">
                                <v-icon size="20" color="white">mdi-eye</v-icon>
                                <div class="ml-1">ยอดวิว 4,054</div>
                            </div> -->
                            <div class="d-flex tool_content_knowledge_all">
                                <v-btn class="tool_content_knowledge_detail mr-2" @click="link_redirect('/editcontentknowledge/'+base64Encode(detail.id))" height="40" color="#FD8F16" depressed><v-icon size="30">mdi-pencil</v-icon></v-btn>
                                <v-btn class="tool_content_knowledge_detail" @click="openConfirmDelete(base64Encode(detail.id))" height="40" color="#B3001B" depressed><v-icon size="30">mdi-delete</v-icon></v-btn>
                            </div>
                        </div>
                        <div class="pa-3 card_detail_all_content_knowledge" @click="$router.push('/detailcontentknowledge/'+base64Encode(detail.id))">
                            <v-tooltip bottom color="#4EACDF">
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="card_title_content_knowledge" v-bind="attrs" v-on="on">{{ detail.content_name }}</div>
                                </template>
                                <span>{{ detail.content_name }}</span>
                            </v-tooltip>
                            <div class="card_category_content_knowledge my-3">หมวด : {{ detail.category_content_name }}</div>
                            <div class="card_created_user_date_content_knowledge d-flex align-center mb-2">
                                <v-icon color="#414141">mdi-account</v-icon>
                                <div>ผู้สร้าง : {{ detail.created_by }}</div>
                            </div>
                            <div class="card_created_user_date_content_knowledge d-flex align-center">
                                <v-icon color="#414141">mdi-calendar</v-icon>
                                <div>วันที่เผยแพร่ : {{ thaiDateShortMonth(detail.content_start_date) }} - {{ thaiDateShortMonth(detail.content_end_date) }}</div>
                            </div>
                        </div>
                    </div>

                </v-col>
             </v-row>
            <v-pagination class="pagination_data_list" v-model="page" color="#4EACDF" :length="pages"></v-pagination>
        </div>
        <v-dialog v-model="open_confirm_delete" persistent max-width="600px">
            <v-card class="page_confirm_delete_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                        
                            <div class="image_confirm_delete">
                                <img src="@/assets/images/trash_confirm_delete.jpg" alt="">
                            </div>
                            <div class="text_confirm_delete">คุณแน่ใจใช่ไหมที่จะทำการลบข้อมูล?</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_delete">
                    <v-btn class="button_text_confirm_delete" @click="deleteContentKnowledge()" height="43" width="40%" color="#4EACDF">ใช่ ฉันต้องการลบ !</v-btn>
                    <v-btn class="button_text_confirm_delete" @click="cancelDeleteContentKnowledge()" height="43" width="40%" color="#414141">ยกเลิก</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>      

    </div>
</template>

<script>
import { base64Encode, base64Decode } from '@/helpers/base64.js'
import { thaiDateShortMonth } from '@/helpers/dateformats.js'
import contentknowledge from '@/api/hospital/contentknowledge/contentknowledge/contentknowledge.js'
import category from '@/api/hospital/contentknowledge/category/category.js'
export default {
    data(){
        return{
            loading_page: true,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            open_confirm_delete: false,
            delete_id: null,
            category_list:[],
            search_category_id: '',
            search: '',
            data: [],
            page:1,
            size: 15,
            pages:1,
        }
    },
    methods:{
        base64Encode(id){
            return base64Encode(id)
        },
        base64Decode(id){
            return base64Decode(id)
        },
        thaiDateShortMonth(date){
            return thaiDateShortMonth(date)
        },
        async selectDataAllAndContentKnowledgeList(){
            this.loading_page = true
            this.category_list = []
            await category.categoryList().then((response) => {
                if (response.data.successful === true) {
                    this.category_list = response.data.contentCatgoryList
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
            this.data = []
            await contentknowledge.contentKnowledgeList(this.page,this.size,this.search_category_id,this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.contentList
                    this.pages = response.data.pages
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async link_redirect(link){
            window.location.href = link
        },
        async openConfirmDelete(id){
            this.delete_id = id
            this.open_confirm_delete = true
        },
        async deleteContentKnowledge(){
            this.open_confirm_delete = false
            this.loading_page = true
            contentknowledge.deleteContentKnowledge(this.base64Decode(this.delete_id)).then((response) => {
                if (response.data.successful === true) {
                    this.page = 1
                    this.size = 8
                    this.pages = 1
                    this.selectDataAllAndContentKnowledgeList()
                    this.alert_message_success = 'ลบรายการสาระน่ารู้เรียบร้อยแล้ว'
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async cancelDeleteContentKnowledge(){
            this.delete_id = null
            this.open_confirm_delete = false
        },
        async searchCategory(){
            this.data = []
            this.page = 1
            contentknowledge.contentKnowledgeList(this.page,this.size,this.search_category_id,this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.contentList
                    this.pages = response.data.pages
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async searchData(){
            this.data = []
            this.page = 1
            contentknowledge.contentKnowledgeList(this.page,this.size,this.search_category_id,this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.contentList
                    this.pages = response.data.pages
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        }
    },
    mounted(){
        this.selectDataAllAndContentKnowledgeList()
    },
    watch:{
        page(){
            this.selectDataAllAndContentKnowledgeList()
        }
    }
}
</script>

<style scoped>
.card_all_content_knowledge{
    border: 1px solid #469AC8;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
}

.card_image_content_knowledge{
    position: relative;
    height: 200px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

.card_image_content_knowledge > img{
    max-width: 100%;
}

.view_count_content_knowledge{
    background: #151B54;
    border-radius: 10px;
    position: absolute;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    top: 5%;
    left: 5%;
}

.tool_content_knowledge_all{
    position: absolute;
    bottom: 5%;
    right: 5%;
}

.tool_content_knowledge_detail{
  min-width: unset!important;
  padding: 0 10px!important;
  color: #FFFFFF!important;
  border-radius: 10px!important;
}

.card_detail_all_content_knowledge{
    cursor: pointer;
}

.card_title_content_knowledge{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #151B54;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card_category_content_knowledge{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #414141;
}

.card_created_user_date_content_knowledge{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #414141;
}
</style>