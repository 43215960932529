<template>
    <div>
      <Heading heading="จองคิวรถพยาบาล" sub_heading="ตารางการจองรถพยาบาล" button_link_url="ambulancequeuebookadd" button_link_name="เพิ่มคิวจองรถพยาบาล"/>
      <Ambulancequeuebook/>
    </div>
  </template>
  
  <script>
  import Heading from '@/components/heading/Heading'
  import Ambulancequeuebook from '@/components/hospital/ambulancequeue/Ambulancequeuebook.vue'
  export default {
    name: 'ambulancequeuebook',
    components:{
      Heading,
      Ambulancequeuebook
    }
  }
  </script>