<template>
    <v-app>
      <Header/>
      <HealthyList/>
    </v-app>
  </template>
  
  <script>
  import Header from '@/components/line_oa/menu/Header'
  import HealthyList from '@/components/line_oa/about/HealthyList'
  export default {
    name: 'lineoaHealthy',
    components:{
      Header,
      HealthyList
    }
  }
  </script>