<template>
    <div>        
        <div class="card_heading_page_detail_data d-flex align-center">
            <div class="card_text_heading_data">หัวข้อ : {{ content_name }}</div>
        </div>
        <div class="card_data_list new_card_data_list">
          <v-container>
            <v-row>
                <v-col cols="12">
                  <div class="image_content_knowledge">
                    <img v-if="imagePreviewURL" :src="imagePreviewURL">
                  </div>
                  <div class="d-flex title_content_knowledge px-4">
                    <div class="d-flex align-center"><v-icon color="#151B54">mdi-calendar-blank-outline</v-icon><div class="ml-1">วันที่เผยแพร่ {{ publish_date }}</div></div>
                    <div class="d-flex align-center"><v-icon color="#151B54">mdi-eye</v-icon><div class="ml-1">ยอดวิว 4,054 ครั้ง</div></div>
                    <div class="d-flex align-center"><v-icon color="#4C4C4C">mdi-account</v-icon><div class="ml-1">ผู้สร้าง {{ created_by }} หมวด : {{ category_content_name }}</div></div>
                  </div>

                  <div class="short_description">
                    {{ content_shot_detail }}
                  </div>
                  <div class="description" v-html="content_detail"></div>
                  <div class="text-center">
                      <v-btn class="white--text button_edit_save" @click="link_redirect('/editcontentknowledge/'+$route.params.id)" height="43" width="15%" color="#FD8F16">แก้ไขสาระน่ารู้</v-btn>
                  </div>
                </v-col>
            </v-row>
          </v-container>
        </div>

    </div>
</template>

<script>
import before_image from "@/assets/images/contentknowledge/image_preview_content_knowledge.png"
import { base64Decode } from '@/helpers/base64.js'
import { thaiDateFullMonth } from '@/helpers/dateformats.js'
import contentknowledge from '@/api/hospital/contentknowledge/contentknowledge/contentknowledge.js'
export default {
    data(){
        return{
            loading_page: true,
            imagePreviewURL: before_image,
            content_name: null,
            content_shot_detail: null,
            publish_date: null,
            created_by: null,
            category_content_name: null, 
            content_detail: null
        }
    },
    methods:{
        base64Decode(id){
          return base64Decode(id)
        },
        thaiDateFullMonth(date){
            return thaiDateFullMonth(date)
        },
        async contentKnowledgeDetail(){
          this.loading_page = true

          this.imagePreviewURL = before_image
          this.content_name = null
          this.content_shot_detail = null
          this.publish_date = null
          this.created_by = null
          this.category_content_name = null
          this.content_detail = null
          contentknowledge.contentKnowledgeDetail(this.base64Decode(this.$route.params.id)).then((response) => {
              if (response.data.successful === true) {
                  if (response.data.contentDetail.content_picture) {
                      this.imagePreviewURL = response.data.contentDetail.content_picture
                  }
                  this.content_name = response.data.contentDetail.content_name
                  this.content_shot_detail = response.data.contentDetail.content_shot_detail
                  this.publish_date = thaiDateFullMonth(response.data.contentDetail.update_at)
                  this.created_by = response.data.contentDetail.created_by
                  this.category_content_name = response.data.contentDetail.category_content_name
                  this.content_detail = response.data.contentDetail.content_detail
                  this.loading_page = false
              }
          }).catch((error) => {
              if (error.response.status === 401) {
                  this.$store.commit('logOutSuccess')
                  this.$router.push('/login')
              }
          })
      },
      async link_redirect(link){
          window.location.href = link
      },
    },
    mounted(){
      this.contentKnowledgeDetail()
    }
}
</script>

<style scoped>
.new_card_data_list{
    padding: 60px 70px 30px;
}
.image_content_knowledge{
  text-align: center;
  margin-bottom: 15px;
}

.image_content_knowledge > img{
  max-width: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.title_content_knowledge{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #414141;
  margin-bottom: 30px;
}

.title_content_knowledge > div {
  margin: 0 10px;
}

.short_description{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #414141;
  text-indent: 10%;
  margin-bottom: 30px;
}

.description{
  margin-bottom: 30px;
}

.button_edit_save{
    border: 1px solid #FD8F16;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}
</style>