<template>
  <div>
    <Heading heading="จัดการผู้ใช้งาน" sub_heading="ผู้ดูแลระบบ" button_link_url="addadmin" button_link_name="เพิ่มผู้ดูแลระบบ"/>
    <ManageAdminList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import ManageAdminList from '@/components/manage_users/manage_admins/ManageAdminList'
export default {
  name: 'manageAdmins',
  components:{
    Heading,
    ManageAdminList
  }
}
</script>