<template>
    <div>
      <Heading heading="แก้ไขคิวรถพยาบาล" sub_heading="ตารางการจองรถพยาบาล  •  แก้ไขคิวรถพยาบาล "/>
      <Ambulancequeuebookedit/>
    </div>
  </template>
  
  <script>
  import Heading from '@/components/heading/Heading'
  import Ambulancequeuebookedit from '@/components/hospital/ambulancequeue/Ambulancequeuebookedit.vue'
  export default {
    name: 'ambulancequeuebookedit',
    components:{
      Heading,
      Ambulancequeuebookedit
    }
  }
  </script>