import ManagePeople from '@/views/manage_people/manage_people/ManagePeople.vue'
import AddPeople from '@/views/manage_people/manage_people/AddPeople.vue'
import AddPeopleExcel from '@/views/manage_people/manage_people/AddPeopleExcel.vue'
import DetailPeople from '@/views/manage_people/manage_people/DetailPeople.vue'
import EditPeople from '@/views/manage_people/manage_people/EditPeople.vue'
export default [
    {
        path: '/managepeople',
        name: 'managePeople',
        component: ManagePeople,
    },
    {
        path: '/addpeople',
        name: 'addPeople',
        component: AddPeople,
    },
    {
        path: '/addpeopleexcel',
        name: 'addPeopleExcel',
        component: AddPeopleExcel,
    },
    {
        path: '/detailpeople/:id',
        name: 'detailPeople',
        component: DetailPeople,
    },
    {
        path: '/editpeople/:id',
        name: 'editPeople',
        component: EditPeople,
    },
]

