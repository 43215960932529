<template>
  <div>
    <AppointmentAllList/>
  </div>
</template>

<script>
import AppointmentAllList from '@/components/hospital/appointment/AppointmentAllList'
export default {
  name: 'appointmentAll',
  components:{
    AppointmentAllList
  }
}
</script>