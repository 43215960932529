import Vue from 'vue'
import Router from 'vue-router'
import Login from '../routers/login'
import PageAll from '../views/PageAll.vue'
import ManagePeople from '../routers/manage_people'
import ManageUsers from '../routers/manage_users'
import UserProfile from '../routers/user_profile'
import Hospital from '../routers/hospital'
import PDF_route from '../routers/pdf'
// Line OA
import Notify from '../routers/line_oa/notify/notify'
import About from '../routers/line_oa/about/about'
import ContentKnowledge from '../routers/line_oa/contentknowledge/contentknowledge'
import Ambulancequeue from '../routers/line_oa/ambulancequeue/ambulancequeue'
import Dashboard from '../routers/dashboard'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...Login,
    ...PDF_route,
    {
      path: '/',
      name: 'pageAll',
      component: PageAll,
      children: [
        ...ManagePeople,
        ...ManageUsers,
        ...UserProfile,
        ...Hospital,
        ...Dashboard,
      ]
    },
    // Line OA
    ...Notify,
    ...About,
    ...ContentKnowledge,
    ...Ambulancequeue,
  ]
})
