<template>
  <div>
    <Heading heading="แก้ไขข้อมูลประชาชน" sub_heading="ประชาชน" sub_secondary_heading="รายการประชาชน"/>
    <EditPeopleList/>
  </div>
</template>
  
<script>
  import Heading from '@/components/heading/Heading'
  import EditPeopleList from '@/components/manage_people/manage_people/EditPeopleList'
  export default {
    name: 'editPeople',
    components:{
      Heading,
      EditPeopleList
    }
  }
  </script>