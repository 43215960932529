<template>
    <div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_heading_page_detail_data d-flex align-center">
            <div class="card_text_heading_data">ฟอร์มการจองคิวรถพยาบาล</div>
        </div>
        <div class="card_data_list">
            <v-form ref="form" @submit.prevent="saveData()">
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="text_title">
                            <div class="ml-2 mb-5">ข้อมูลผู้รับบริการ</div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <div class="res_padding">
                            <div class="detail_title">
                                <div>ชื่อ-สกุล <span class="error--text">*</span></div>
                                <v-text-field class="input_detail_title" v-model="full_name"
                                    :rules="[v => !!v || 'กรุณากรอกชื่อ - สกุล ']" placeholder="กรุณากรอกชื่อ - สกุล " solo
                                    required></v-text-field>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <div class="res_padding">
                            <div class="detail_title">
                                <div>เบอร์โทร <span class="error--text">*</span></div>
                                <div class="d-flex otp_tel">
                                    <v-text-field class="input_detail_title" v-model="tel[0]" type="tel" ref="tel_1"
                                        @focus="selectOPTInput" @keyup="focusOPTInput($event, 'tel_1', 'tel_2', 0)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" :rules="[v => !!v || 'กรุณากรอกเบอร์ ']"
                                        placeholder="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="tel[1]" type="tel" ref="tel_2"
                                        @focus="selectOPTInput" @keyup="focusOPTInput($event, 'tel_1', 'tel_3', 1)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" :rules="[v => !!v || 'กรุณากรอกเบอร์ ']"
                                        placeholder="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="tel[2]" type="tel" ref="tel_3"
                                        @focus="selectOPTInput" @keyup="focusOPTInput($event, 'tel_2', 'tel_4', 2)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" :rules="[v => !!v || 'กรุณากรอกเบอร์ ']"
                                        placeholder="X" solo required></v-text-field>
                                    <div class="line_otp_tel">
                                        <v-icon>mdi-minus-thick</v-icon>
                                    </div>
                                    <v-text-field class="input_detail_title" v-model="tel[3]" type="tel" ref="tel_4"
                                        @focus="selectOPTInput" @keyup="focusOPTInput($event, 'tel_3', 'tel_5', 3)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" :rules="[v => !!v || 'กรุณากรอกเบอร์ ']"
                                        placeholder="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="tel[4]" type="tel" ref="tel_5"
                                        @focus="selectOPTInput" @keyup="focusOPTInput($event, 'tel_4', 'tel_6', 4)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" :rules="[v => !!v || 'กรุณากรอกเบอร์ ']"
                                        placeholder="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="tel[5]" type="tel" ref="tel_6"
                                        @focus="selectOPTInput" @keyup="focusOPTInput($event, 'tel_5', 'tel_7', 5)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" :rules="[v => !!v || 'กรุณากรอกเบอร์ ']"
                                        placeholder="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="tel[6]" type="tel" ref="tel_7"
                                        @focus="selectOPTInput" @keyup="focusOPTInput($event, 'tel_6', 'tel_8', 6)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" :rules="[v => !!v || 'กรุณากรอกเบอร์ ']"
                                        placeholder="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="tel[7]" type="tel" ref="tel_8"
                                        @focus="selectOPTInput" @keyup="focusOPTInput($event, 'tel_7', 'tel_9', 7)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" :rules="[v => !!v || 'กรุณากรอกเบอร์ ']"
                                        placeholder="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="tel[8]" type="tel" ref="tel_9"
                                        @focus="selectOPTInput" @keyup="focusOPTInput($event, 'tel_8', 'tel_10', 8)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" :rules="[v => !!v || 'กรุณากรอกเบอร์ ']"
                                        placeholder="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="tel[9]" type="tel" ref="tel_10"
                                        @focus="selectOPTInput" @keyup="focusOPTInput($event, 'tel_9', 'tel_10', 9)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" :rules="[v => !!v || 'กรุณากรอกเบอร์ ']"
                                        placeholder="X" solo required></v-text-field>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <div class="res_padding">
                            <div class="mb-2">รับที่ <span class="error--text">*</span></div>
                            <v-radio-group v-model="drop_off_check" row>
                                <v-radio label="ขึ้นรถที่โรงพยาบาลหนองป่าครั่ง" value="0"></v-radio>
                                <v-radio label="รับที่บ้าน" value="1"></v-radio>
                            </v-radio-group>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <div v-if="drop_off_check == '1'" class="res_padding">
                            <div class="mb-2">รับที่ <span class="error--text">*</span></div>
                            <v-text-field class="input_detail_title" v-model="pick_up_at"
                                :rules="[v => !!v || 'กรุณากรอกสถานะที่ที่รับ']" placeholder="กรุณากรอกสถานะที่ที่รับ" solo
                                required></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="res_padding">
                            <div class="mb-2">ผู้ป่วยเป็นผู้ป่วยติดเตียงหรือไม่ ? <span class="error--text">*</span></div>
                            <v-radio-group v-model="patient" row @change="getCheckAmbulanceQueue(), service_data = null, service_data_be = null, status_check = false">
                                <v-radio label="ไม่ใช่" value="1"></v-radio>
                                <v-radio label="ใช่" value="0"></v-radio>
                            </v-radio-group>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="text_title">
                            <div class="ml-2 mb-5">ข้อมูลการไปส่ง</div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                        <div class="res_padding">
                            <div class="detail_title">
                                <div>วันที่รับบริการ <span class="error--text">*</span></div>
                                <v-menu ref="menu_date" v-model="menu_date" :close-on-content-click="false"
                                    :return-value.sync="service_data" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="input_detail_title" v-model="service_data_be"
                                            :rules="[v => !!v || 'กรุณาเลือกวัน / เดือน / ปี ที่ให้รถไปรับ']"
                                            placeholder="กรุณาเลือกวัน / เดือน / ปี ที่ให้รถไปรับ"
                                            append-icon="mdi-calendar-month-outline" readonly v-bind="attrs" v-on="on" solo
                                            required></v-text-field>
                                    </template>
                                    <v-date-picker v-model="service_data" no-title scrollable locale="th" :min="mindate" :allowed-dates="allowedDates">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="selectDate(), getRound()">
                                            ตกลง
                                        </v-btn>
                                        <v-btn text color="primary" @click="menu_date = false">
                                            ยกเลิก
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="status_check == true" cols="12" sm="12" md="4" lg="4" xl="4">
                        <div class="res_padding">
                            <div class="detail_title">
                                <div>รอบ <span class="error--text">*</span></div>
                                <v-select class="input_detail_title" :items="round_list" item-text="name_round" item-value="id" v-model="round_id"
                                    :rules="[v => !!v || 'กรุณาเลือกรอบเวลาการไปรับ']"
                                    placeholder="กรุณาเลือกรอบเวลาการไปรับ" solo required>
                                </v-select>
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="status_check == true" cols="12" sm="12" md="4" lg="4" xl="4">
                        <div class="res_padding">
                            <div class="detail_title">
                                <div>จุดหมาย <span class="error--text">*</span></div>
                                <v-select class="input_detail_title" :items="dropoffList" item-text="name_drop_off"
                                    item-value="id" :rules="[v => !!v || 'กรุณาเลือกสถานพยาบาลที่ต้องการให้รถไปส่ง']" v-model="drop_off"
                                    placeholder="กรุณาเลือกสถานพยาบาลที่ต้องการให้รถไปส่ง" solo required>
                                </v-select>
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="status_check == true" cols="12" sm="12" md="6" lg="6" xl="6">
                        <div class="res_padding">
                            <div class="detail_title">
                                <div>ผู้ติดตาม จำนวน (คน)</div>
                                <v-text-field class="input_detail_title" @keypress="isNumberOnly($event)" type="tel" maxlength="2"
                                    placeholder="กรุณากรอกจำนวนผู้ติดตาม  สูงสุดไม่เกิน 2 คน " v-model="follower" solo required></v-text-field>
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="status_check == true" cols="12" sm="12" md="6" lg="6" xl="6">
                        <div class="res_padding">
                            <div class="detail_title">
                                <div>หมายเหตุ</div>
                                <v-text-field class="input_detail_title" v-model="note" solo required></v-text-field>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="text-center">
                            <v-btn class="white--text button_save" type="submit" height="43" width="40%"
                                color="#37AE46">บันทึกคิวรถพยาบาล</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </div>
    </div>
</template>

<script src="@/helpers/previewimage.js"></script>
<script>
import { isNumberOnly } from '@/helpers/numberformats.js'
import { monthList } from '@/helpers/dateformats.js'
import ambulancequeue from '@/api/line_oa/ambulancequeue/ambulancequeue.js'
import destination from '@/api/hospital/destination/destination.js'
import liff from '@line/liff'
export default {
    data(){
        return{
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            open_add_success: false,
            first_name: '',
            last_name: '',
            telephone: ['','','','','','','','','',''],
            menu_date:false,
            date_be:'',
            date:'',
            time_list: [
                { id: 1, time: 'รอบเช้า 7.00 น.' },
                { id: 2, time: 'รอบบ่าย 13.00 น.' },
            ],
            destination_list: [
                { id: 1, name: 'โรงพยาบาลนครพิงค์' },
                { id: 2, name: 'โรงพยาบาลสวนดอก' },
                { id: 3, name: 'โรงพยาบาลหนองป่าคลั่ง' },
            ],
            row:0,
            drop_off: null,
            drop_off_check: '0',
            patient: '1',
            service_date: [],
            status_check: false,
            service_data: null,
            service_data_be: null,
            round_list: [],
            full_name: null,
            tel: ['','','','','','','','','',''],
            pick_up_at: null,
            round_id: null,
            follower: null,
            note: null ,
            dropoffList: [],
             mindate: new Date().toISOString().substr(0, 10)
        }
    },
    methods:{
         allowedDates(date) {
      // Convert date to the format used in the closedDates array
      const formattedDate = date.substring(0, 10);
      
      // Check if the date is in the closedDates array
      return !this.service_date.includes(formattedDate);
    },
        async isNumberOnly(evt){
            return isNumberOnly(evt)
        },
        async selectOPTInput(event) {
            event.target.select()
        },
        async focusOPTInput(event,first,last,arr_current) {
            let evt = (event) ? event : window.event
            let charCode = (evt.which) ? evt.which : evt.keyCode

            if(charCode == 9){
                return true
            }
            if (event.key >= 0 && event.key <= 9 && this.tel[arr_current] != '') {
                this.$refs[last].focus()
            }
            if(charCode == 8){
                this.$refs[first].focus()
            }
        },
        
        birthdayBE(){
            if (this.date == '') {
                this.date_be = ''
            }
            if (this.date) {
                let date_arr = this.date.split('-')
                let year = parseInt(date_arr[0]) + 543
                let month = parseInt(date_arr[1])
                month = monthList().find((detail) => { return detail.id == month }).name_short
                this.date_be = `${date_arr[2]} ${month} ${year}`
            }
        },
        async selectDate(){
            this.$refs.menu_date.save(this.service_data)
            let date_arr = this.service_data.split('-')
            let year = parseInt(date_arr[0]) + 543
            let month = parseInt(date_arr[1])
            month = monthList().find((detail) => { return detail.id == month }).name_short
            this.service_data_be = `${date_arr[2]}/${month}/${year}`
            this.menu_date = false
        },
        async getDetailLineUser() {
            this.loading_page = true
            this.register = {
                token_line: null,
                hn: '',
                menu_birthday: false,
                birthday_be: '',
                birthday: '',
            }
            liff.init({
                liffId: process.env.VUE_APP_BASE_LINE_LIFFID,
            }).then(() => {
                if (liff.isLoggedIn()) {
                    liff.getProfile().then(profile => {
                        this.register.token_line = profile.userId
                        this.loading_page = false
                    })
                } else {
                    liff.login()
                }
            })
        },
        async getCheckAmbulanceQueue(){
           let data = {
                patient: this.patient
            }
            await ambulancequeue.getCheckAmbulanceQueue(data).then((response) => {
                    if (response.data.successful === true) {
                        this.service_date = response.data.data
                        this.service_date = this.service_date.map(item => 
                               item.service_date      
                        )
                    }
             })
        },
        async getRound(){
           let data = {
                service_date : this.service_data,
                patient : this.patient
            }
            await ambulancequeue.getRound(data).then((response) => {
                    if (response.data.successful === true) {
                        this.round_list = response.data.data
                        this.status_check = true;
                    }
             })
        },
        async getDestination(){
             await destination.getDestination().then((response) => {
                    if (response.data.successful === true) {
                        this.dropoffList = response.data.dropoffList
                    }
             })
        },
        async saveData(){
          if(this.$refs.form.validate()){
             let data = {
                full_name:  this.full_name,
               tel: `${this.tel[0]}${this.tel[1]}${this.tel[2]}${this.tel[3]}${this.tel[4]}${this.tel[5]}${this.tel[6]}${this.tel[7]}${this.tel[8]}${this.tel[9]}`,
                pick_up_at: this.drop_off_check == '1' ? this.pick_up_at : null,
                patient: this.patient,
                service_date: this.service_data,
                around: this.round_id,
                drop_off: this.drop_off,
                follower: this.follower,
                note: this.note,
                // token_line: this.register.token_line,
                status_from_admin:1
            }
            await ambulancequeue.saveData(data).then((response) => {
                    if (response.data.successful === true) {
                        this.alert_success = true;
                        this.alert_message_success = 'เพิ่มข้อมูลเรียบร้อย';
                         setTimeout(() => {this.alert_success = false; this.$router.push('/ambulancequeuebookall')}, 3000)
                    }
             })
          }
        },
    },
    mounted(){
        // this.getDetailLineUser()
        this.getCheckAmbulanceQueue()
        this.getDestination()
    }
}
</script>

<style scoped>
.res_padding {
    padding-left: 60px;
}

.image_preview>.v-avatar {
    overflow: unset;
}

.upload_image {
    position: absolute;
    top: 0;
    right: 0;
}

.image_title {
    width: 50px;
}

.image_title>img {
    max-width: 100%;
}

.text_title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: center;
}

.detail_title_all {
    display: flex;
    align-items: center;
    padding-left: 60px;
}

.layout_fullname>div:first-child {
    width: 20%;
}

.layout_fullname>div {
    width: 40%;
}

.detail_title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #414141;
    margin-right: 20px;
}

.detail_title:last-child {
    margin-right: 0;
}

.detail_title>div {
    margin-bottom: 5px;
}

.select_detail_title {
    border-radius: 10px;
}

.input_detail_title {
    border-radius: 10px;
}

.layout_contact>div:first-child {
    width: 65%;
}

.layout_contact>div {
    width: 42%;
}

.otp_tel>div {
    margin-right: 6px;
}

.otp_tel>div:last-child {
    margin-right: 0;
}

.otp_tel>>>.v-text-field.v-text-field--solo .v-label,
.otp_tel>>>.v-input__control input {
    text-align: center !important;
    width: 100% !important;
}

.line_otp_tel {
    position: relative;
    top: 13px;
}

.layout_position>div:first-child {
    width: 40%;
}

.layout_position>div {
    width: 60%;
}

.button_save {
    border: 1px solid #37AE46;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}

@media only screen and (max-width: 1000px) {
    .res_padding {
        padding-left: 0;
    }

    .detail_title_all {
        display: block;
        margin-right: 0;
        padding-left: 0;
    }

    .layout_fullname>div:first-child {
        width: 100%;
    }

    .layout_fullname>div {
        width: 100%;
    }

    .layout_contact>div:first-child {
        width: 100%;
    }

    .layout_contact>div {
        width: 100%;
    }

    .layout_position>div:first-child {
        width: 100%;
    }

    .layout_position>div {
        width: 100%;
    }
}
</style>