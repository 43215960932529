<template>
    <div>
        <div class="container">
            <h3 style="margin-bottom: 20px;">ตารางคิวรถพยาบาล</h3>
            <table class="w-100">
                <thead>
                    <tr>
                        <th class="text-center header-table font-14" style="width: 250px;" >วันที่</th>
                        <th class="text-center header-table font-14" style="width: 250px;" v-for ="item in header" :key="item">{{item.name_round}} {{item.delivery_time}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in dataList" :key="item">
                        <td class="text-left body-table font-14">{{item.day_th}}</td>
                        <td class="text-left body-table font-14" v-for="detail in item.que_list" :key="detail">
                            <v-row v-if="detail.que_list.length != 0">
                                <v-col cols="12 font-14" style="border-bottom: 1px solid;" v-for="sub in detail.que_list" :key="sub">
                                    {{sub.full_name}} ({{sub.patient == '0' ? 'ผู้ป่วยติดเตียง' : 'ผู้ป่วยปกติ'}})<br>
                            {{sub.pick_up_at}} <br v-if="sub.pick_up_at">
                            เบอร์โทร: {{sub.tel}} <br>
                            จุดหมาย: {{sub.name_drop_off}}  <br>
                            หมายเหตุ: {{sub.note == null ? '-' : sub.note}}  <br>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12 font-14">
                                    ว่าง
                                </v-col>
                            </v-row>
                        </td>
                    </tr>

                    <!-- <tr>
                        <td class="text-left body-table" >
                            นาย ธรรศ เจริญเกียติปรีชา (ผู้ป่วยปกติ) <br>
                            สถานที่ไปรับ: 123 ม.5 ต.หนองปลาครั่ง อ.เมือง จ.เชียงใหม่ <br>
                            เบอร์โทร: 012-3456789 <br>
                            จุดหมาย: รพ.นครพิงค์  <br>
                            หมายเหตุ: -  <br>
                        </td>
                        <td class="text-left body-table" >
                            นาย ธรรศ เจริญเกียติปรีชา (ผู้ป่วยปกติ) <br>
                            สถานที่ไปรับ: 123 ม.5 ต.หนองปลาครั่ง อ.เมือง จ.เชียงใหม่ <br>
                            เบอร์โทร: 012-3456789 <br>
                            จุดหมาย: รพ.นครพิงค์  <br>
                            หมายเหตุ: -  <br>  
                        </td>
                    </tr>

                    <tr>

                        <td class="text-left body-table" >
                            นาย ธรรศ เจริญเกียติปรีชา (ผู้ป่วยปกติ) <br>
                            สถานที่ไปรับ: 123 ม.5 ต.หนองปลาครั่ง อ.เมือง จ.เชียงใหม่ <br>
                            เบอร์โทร: 012-3456789 <br>
                            จุดหมาย: รพ.นครพิงค์  <br>
                            หมายเหตุ: -  <br>
                        </td>
                        <td class="text-left body-table" >
                            นาย ธรรศ เจริญเกียติปรีชา (ผู้ป่วยปกติ) <br>
                            สถานที่ไปรับ: 123 ม.5 ต.หนองปลาครั่ง อ.เมือง จ.เชียงใหม่ <br>
                            เบอร์โทร: 012-3456789 <br>
                            จุดหมาย: รพ.นครพิงค์  <br>
                            หมายเหตุ: -  <br>  
                        </td>
                    </tr>

                    <tr>
                        <td class="text-left body-table" >จันทร์ 2 พฤษภาคม 2566</td>
                        <td class="text-left body-table" >
                            นาย ธรรศ เจริญเกียติปรีชา (ผู้ป่วยปกติ) <br>
                            สถานที่ไปรับ: 123 ม.5 ต.หนองปลาครั่ง อ.เมือง จ.เชียงใหม่ <br>
                            เบอร์โทร: 012-3456789 <br>
                            จุดหมาย: รพ.นครพิงค์  <br>
                            หมายเหตุ: -  <br>
                        </td>
                        <td class="text-left body-table" >
                            นาย ธรรศ เจริญเกียติปรีชา (ผู้ป่วยปกติ) <br>
                            สถานที่ไปรับ: 123 ม.5 ต.หนองปลาครั่ง อ.เมือง จ.เชียงใหม่ <br>
                            เบอร์โทร: 012-3456789 <br>
                            จุดหมาย: รพ.นครพิงค์  <br>
                            หมายเหตุ: -  <br>  
                        </td>
                    </tr>

                    <tr >
                        <td class="text-left body-table" >จันทร์ 3 พฤษภาคม 2566</td>
                        <td class="text-left body-table" >
                        ว่าง
                        </td>
                        <td class="text-left body-table" >
                            นาย ธรรศ เจริญเกียติปรีชา (ผู้ป่วยปกติ) <br>
                            สถานที่ไปรับ: 123 ม.5 ต.หนองปลาครั่ง อ.เมือง จ.เชียงใหม่ <br>
                            เบอร์โทร: 012-3456789 <br>
                            จุดหมาย: รพ.นครพิงค์  <br>
                            หมายเหตุ: -  <br>  
                        </td>
                    </tr> -->
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { base64Encode, base64Decode } from '@/helpers/base64.js'
import ambulancequeue from '@/api/hospital/ambulancequeue/ambulancequeue.js'
export default {
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            open_confirm_notification_all: false,
            notification_id: null,
            open_confirm_delete: false,
            delete_id: null,
            search: '',
            data: [],
            header: [],
            dataList: [],
            page: 1,
            size: 15,
            pages: 1,
        }
    },
    methods: {
        base64Encode(id) {
            return base64Encode(id)
        },
        base64Decode(id) {
            return base64Decode(id)
        },
         async getAmbulanceQueueList() {
            let data = {
                start_date: this.$route.query.start_date,
                end_date: this.$route.query.end_date
            }
            await ambulancequeue.getAmbulanceQueueList(data).then((response) => {
                if (response.data.successful === true) {
                   this.header = response.data.data.roundList
                   this.dataList = response.data.data.dayList
                    this.loading_page = false;

                }
            })
        },
    },
    mounted(){
        this.getAmbulanceQueueList()
    }
}
</script>

<style scoped>
.text_title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: center;
}

.image_title {
    width: 34px;
}

.image_title>img {
    max-width: 100%;
}

table{
    text-align: center;
    margin: auto;
    border: 1px solid black;
}

h3{
    text-align: center;
}

.w-100{
    width: 100%;
}


.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1540px;
}


.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.header-table{
    padding: 10px;
    background-color: #d5dce4;
    border: 1px solid;
}
.body-table{
    padding: 10px;
    line-height: 35px;
    vertical-align: top;
    border: 1px solid;
}

.font-14{
    font-size: 14px;
}

@media print {
    .header-table {
        background-color: #d5dce4 !important;
    }
}
</style>