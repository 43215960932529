<template>
  <div>
    <Heading heading="รายละเอียดการนัดหมาย" sub_heading="นัดหมายผู้ป่วยมาตรวจที่โรงพยาบาล" sub_secondary_heading="รายละเอียดการนัดหมาย วันที่ 24/11/2565"/>
    <DetailAppointmentList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import DetailAppointmentList from '@/components/hospital/appointment/viewlistappointment/DetailAppointmentList'
export default {
  name: 'detailAppointment',
  components:{
    Heading,
    DetailAppointmentList
  }
}
</script>