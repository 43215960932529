<template>
  <div>
    <Heading heading="เพิ่มข้อมูลคิวนัดหมาย" sub_heading="นัดหมายผู้ป่วยมาตรวจที่โรงพยาบาล" sub_secondary_heading="เพิ่มข้อมูลคิวนัดหมายด้วยอัพโหลดไฟล์ Excel"/>
    <AddAppointmentExcelList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import AddAppointmentExcelList from '@/components/hospital/appointment/viewlistappointment/AddAppointmentExcelList'
export default {
  name: 'addAppointmentExcel',
  components:{
    Heading,
    AddAppointmentExcelList
  }
}
</script>