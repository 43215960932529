
const mutations = {
    logInSuccess(state, data) {
        state.access_token = data.access_token
    },
    logOutSuccess(state) {
        state.access_token = null
    },
}
export default mutations
