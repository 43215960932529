import ManageAdmins from '@/views/manage_users/manage_admins/ManageAdmins.vue'
import AddAdmin from '@/views/manage_users/manage_admins/AddAdmin.vue'
import EditAdmin from '@/views/manage_users/manage_admins/EditAdmin.vue'
import ManageUsers from '@/views/manage_users/manage_users/ManageUsers.vue'
import AddUser from '@/views/manage_users/manage_users/AddUser.vue'
import EditUser from '@/views/manage_users/manage_users/EditUser.vue'

export default [
    {
        path: '/manageadmins',
        name: 'manageAdmins',
        component: ManageAdmins,
    },
    {
        path: '/addadmin',
        name: 'addAdmin',
        component: AddAdmin,
    },
    {
        path: '/editadmin/:id',
        name: 'editAdmin',
        component: EditAdmin,
    },
    {
        path: '/manageusers',
        name: 'manageUsers',
        component: ManageUsers,
    },
    {
        path: '/adduser',
        name: 'addUser',
        component: AddUser,
    },
    {
        path: '/edituser/:id',
        name: 'editUser',
        component: EditUser,
    },
]

