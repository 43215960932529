<template>
  <div>
    <Heading heading="เพิ่มสาระน่ารู้ใหม่" sub_heading="รายการสาระน่ารู้ทั้งหมด" sub_secondary_heading="เพิ่มสาระน่ารู้ใหม่"/>
    <AddContentKnowledgeList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import AddContentKnowledgeList from '@/components/hospital/contentknowledge/AddContentKnowledgeList'
export default {
  name: 'addContentKnowledgeList',
  components:{
    Heading,
    AddContentKnowledgeList
  }
}
</script>