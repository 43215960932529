import Ambulancequeue from '@/views/line_oa/ambulancequeue/Ambulancequeue.vue'
const main = '/lineoa'
export default [
    {
        path: main + '/ambulancequeue',
        name: 'lineoaambulancequeue',
        component: Ambulancequeue,
    },
]

