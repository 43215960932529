<template>
    <div>
      <Heading heading="วันรับจองคิวรถ" sub_heading="วันรับจองคิวรถ" button_link_url="settinghospital" button_link_name="วันรับจองคิวรถ" :button_link_redirect="true"/>
      <SettingHospitalList />
    </div>
  </template>
  
  <script>
  import Heading from '@/components/heading/Heading'
  import SettingHospitalList from '@/components/hospital/settinghospital/SettingHospital'
  export default {
    name: 'settingHospitalList',
    components:{
      Heading,
      SettingHospitalList
    }
  }
  </script>