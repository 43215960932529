import Dashboard from '@/views/dashboard/Dashboard.vue'
import DashboardNotifyDetail from '@/views/dashboard/DashboardNotifyDetail.vue'


export default [
    // dashboard
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
    },
    // dashboard detail
    {
        path: '/dashboard-notify-detail',
        name: 'dashboardnotifydetail',
        component: DashboardNotifyDetail,
    },
]

