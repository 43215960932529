<template>
  <div>
    <Heading heading="เพิ่มข้อมูลประชาชน" sub_heading="ประชาชน" sub_secondary_heading="รายการประชาชน"/>
    <AddPeopleExcelList/>
  </div>
</template>
  
<script>
  import Heading from '@/components/heading/Heading'
  import AddPeopleExcelList from '@/components/manage_people/manage_people/AddPeopleExcelList'
  export default {
    name: 'addPeopleExcel',
    components:{
      Heading,
      AddPeopleExcelList
    }
  }
  </script>