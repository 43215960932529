<template>
  <div>
    <Heading heading="แก้ไขข้อมูลคิวนัดหมาย" sub_heading="นัดหมายผู้ป่วยมาตรวจที่โรงพยาบาล" sub_secondary_heading="แก้ไขข้อมูลคิวนัดหมายด้วยการกรอก"/>
    <EditAppointmentList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import EditAppointmentList from '@/components/hospital/appointment/viewlistappointment/EditAppointmentList'
export default {
  name: 'editAppointment',
  components:{
    Heading,
    EditAppointmentList
  }
}
</script>