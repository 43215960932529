<template>
  <v-app>
    <Header/>
    <DetailContentKnowledgeList/>
  </v-app>
</template>

<script>
import Header from '@/components/line_oa/menu/Header'
import DetailContentKnowledgeList from '@/components/line_oa/contentknowledge/DetailContentKnowledgeList'
export default {
  name: 'lineoaDetailContentKnowledge',
  components:{
    Header,
    DetailContentKnowledgeList
  }
}
</script>