<template>
  <div>
    <Heading heading="เพิ่มข้อมูลคิวนัดหมาย" sub_heading="นัดหมายผู้ป่วยมาตรวจที่โรงพยาบาล" sub_secondary_heading="เพิ่มข้อมูลคิวนัดหมายด้วยการกรอก"/>
    <AddAppointmentList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import AddAppointmentList from '@/components/hospital/appointment/viewlistappointment/AddAppointmentList'
export default {
  name: 'addAppointment',
  components:{
    Heading,
    AddAppointmentList
  }
}
</script>