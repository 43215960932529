<template>
  <div>
    <Heading heading="จัดการผู้ใช้งาน" sub_heading="ผู้ใช้งาน" button_link_url="adduser" button_link_name="เพิ่มผู้ใช้งาน"/>
    <ManageUserList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import ManageUserList from '@/components/manage_users/manage_users/ManageUserList'
export default {
  name: 'manageUsers',
  components:{
    Heading,
    ManageUserList
  }
}
</script>