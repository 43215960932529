<template>
    <div>
      <Heading heading="เพิ่มคิวรถพยาบาล" sub_heading="ตารางการจองรถพยาบาล  •  เพิ่มคิวรถพยาบาล "/>
      <Ambulancequeuebookadd/>
    </div>
  </template>
  
  <script>
  import Heading from '@/components/heading/Heading'
  import Ambulancequeuebookadd from '@/components/hospital/ambulancequeue/Ambulancequeuebookadd.vue'
  export default {
    name: 'ambulancequeuebookadd',
    components:{
      Heading,
      Ambulancequeuebookadd
    }
  }
  </script>