<template>
    <div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_header_upload_excel">
            <div class="card_heading_page_detail_data d-flex align-center">
                <div class="card_text_heading_data">อัพโหลดไฟล์เพิ่มข้อมูลประชาชน</div>
            </div>
            <div class="card_data_list new_card_data_list">
                <v-form ref="form" @submit.prevent="importFilePeople()">
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="5" xl="5">
                            <div class="d-flex">
                                <div class="mr-3">อัพโหลดไฟล์</div>
                                <v-file-input prepend-icon="mdi-microsoft-excel" class="pa-0 ma-0" v-model="excel_path"
                                    @change="onFileChange" placeholder="เลือกไฟล์" hide-details="auto"
                                    accept=".xlsx,.xls"></v-file-input>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-btn class="white--text button_save" type="submit" height="43" color="#37AE46">อัพโหลดไฟล์
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </div>
        </div>
        <div>
            <div class="card_heading_page_detail_data d-flex align-center">
                <div class="card_text_heading_data">ประวัติการอัพโหลดไฟล์</div>
            </div>
            <div class="card_data_list new_card_data_list">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="title_table_data_list">
                                วันที่
                            </th>
                            <th class="title_table_data_list">
                                ผู้อัพโหลดไฟล์
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(detail, index) in data" :key="index">
                            <td class="detail_table_data_list">{{ detail.import_date }}</td>
                            <td class="detail_table_data_list">{{ `${detail.title_name} ${detail.first_name}
                                                            ${detail.last_name}` }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-pagination class="pagination_data_list" v-model="page" color="#4EACDF" :length="pages"></v-pagination>
            </div>
        </div>
        <v-dialog v-model="open_error_excel" persistent max-width="800px">
            <v-card class="page_confirm_success_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-icon size="200" color="error">mdi-close-circle</v-icon>
                            </div>
                            <div class="text_confirm_success">อัพโหลดได้เฉพาะไฟล์ Excel หรือ (.xlsx, .XLSX, .xls, .XLS)
                                เท่านั้น</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_success">
                    <v-btn class="button_text_confirm_success" @click="closeErrorExcel()" height="43" width="40%"
                        color="#4EACDF">ตกลง</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import people from '@/api/manage_people/manage_people/people.js'
export default {
    data() {
        return {
            loading_page: true,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            open_error_excel: false,
            data: [],
            page: 1,
            size: 5,
            pages: 1,
            excel_path: null,
        }
    },
    methods: {
        async onFileChange(payload) {
            const file = payload
            let extall = "xlsx,XLSX,xls,XLS";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
                this.open_error_excel = true
            }
        },
        async closeErrorExcel() {
            this.excel_path = null
            this.open_error_excel = false
        },
        async peopleImportExcelHistoryList() {
            this.loading_page = true
            this.data = []
            people.peopleImportExcelHistoryList(this.page, this.size).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.data
                    this.pages = response.data.pages
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async importFilePeople() {
            if (!this.excel_path) {
                this.alert_message_error = 'กรุณาอัพโหลดไฟล์'
                this.alert_error = true
                setTimeout(() => this.alert_error = false, 5000)
            }
            if (this.excel_path) {
                this.loading_page = true
                let data = new FormData()
                data.append('excel_path', this.excel_path)
                people.importFilePeople(data).then((response) => {
                    if (response.data.successful === true) {
                        this.excel_path = null
                        this.peopleImportExcelHistoryList()
                        this.alert_message_success = 'อัพโหลดไฟล์เพิ่มข้อมูลประชาชนเรียบร้อยแล้ว'
                        this.alert_success = true
                        setTimeout(() => this.alert_success = false, 5000)
                    } else if (response.data.successful === false) {
                        this.excel_path = null
                        this.alert_message_error = 'ไม่สามารถเพิ่มข้อมูลประชาชนได้ กรุณาตรวจสอบข้อมูลในไฟล์ Excel'
                        this.alert_error = true
                        this.loading_page = false
                        setTimeout(() => this.alert_error = false, 5000)
                    }

                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        }
    },
    mounted() {
        this.peopleImportExcelHistoryList()
    },
    watch: {
        page() {
            this.peopleImportExcelHistoryList()
        }
    }
}
</script>

<style scoped>
.card_header_upload_excel {
    margin-bottom: 30px;
}

.new_card_data_list {
    padding: 60px 30px 30px;
}

.button_save {
    border: 1px solid #37AE46;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    width: 50%;
}

@media only screen and (max-width: 400px) {
    .button_save {
        width: auto;
    }
}</style>