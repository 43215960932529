<template>
  <div>
    <Heading heading="จัดการข้อมูลส่วนตัว" sub_heading="จัดการข้อมูลส่วนตัว"/>
    <UserProfileList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import UserProfileList from '@/components/user_profile/UserProfileList'
export default {
  name: 'userProfile',
  components:{
    Heading,
    UserProfileList
  }
}
</script>