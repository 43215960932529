import { HTTP } from "@/axios.js"
export default {
    registerOpenNotify: (data) => {
        return HTTP.post(`/people/regis/notification`, data)
        .then(response => response)
    },
    userInformationList: (data) => {
        return HTTP.post(`/people/regis/get`, data)
        .then(response => response)
    },
    userInformationTel: (id) => {
        return HTTP.get(`/people/regis/get/tel/${id}`)
        .then(response => response)
    },
    editUserInformation: (data) => {
        return HTTP.post(`/people/regis/edit`, data)
        .then(response => response)
    },
    checkPeopledetail: (token) => {
        return HTTP.get(`/people/regis/checkPeopledetail/${token}`)
        .then(response => response)
        .then(response => response)
    },
}