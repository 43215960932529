<template>
    <div>
        <div class="card_heading_data d-flex justify-space-between align-center">
            <div class="card_text_heading_data">รายการประชาชน</div>
            <div class="card_search_heading_data">
                <v-text-field class="input_search" v-model="search" @keyup="searchData()" label="ค้นหา"
                    prepend-inner-icon="mdi-magnify" hide-details="auto" solo required></v-text-field>
            </div>
        </div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_data_list">
            <div class="text-end">
                <vue-excel-xlsx :data="dataCheck" :columns="columns" :file-name="'รายการประชาชน'" :file-type="'xlsx'"
                    :sheet-name="'sheetname'">
                    <v-btn class="button_heading" height="62"><v-icon>mdi-file-excel-outline</v-icon>
                        <div class="button_text_heading">ดาวน์โหลดข้อมูลประชากรที่เชื่อมต่อกับไลน์</div>
                    </v-btn>
                </vue-excel-xlsx>
            </div>
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="title_table_data_list" style="width:15%;">
                            รหัสผู้ป่วย
                        </th>
                        <th class="title_table_data_list" style="width:25%;">
                            ชื่อ - สกุล
                        </th>
                        <th class="title_table_data_list" style="width:15%;">
                            เบอร์โทร
                        </th>
                        <th class="title_table_data_list" style="width:15%;">
                            เชื่อมต่อไลน์
                        </th>
                        <th class="title_table_data_list">
                            จัดการ
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(detail, index) in data" :key="index">
                        <td class="detail_table_data_list">{{ detail.hn }}</td>
                        <td class="detail_table_data_list">{{ detail.pnam }}{{ detail.fname }} {{ detail.lname }}</td>
                        <td class="detail_table_data_list">{{ detail.tel ? detail.tel : '-' }}</td>
                        <td class="detail_table_data_list text-align">
                            <v-icon color="success" v-if="detail.token_line">mdi-check-bold</v-icon>
                            <v-icon color="error" v-if="!detail.token_line">mdi-close</v-icon>

                        </td>
                        <td>
                            <v-btn class="button_tool_data_list my-2 mr-2"
                                @click="$router.push('/detailpeople/' + base64Encode(detail.id))" height="43"
                                color="#4495C1"><v-icon>mdi-magnify</v-icon>
                                <div>ดูข้อมูล</div>
                            </v-btn>
                            <v-btn class="button_tool_data_list my-2"
                                @click="$router.push('/editpeople/' + base64Encode(detail.id))" height="43"
                                color="#FD8F16"><v-icon>mdi-pencil</v-icon>
                                <div>แก้ไข</div>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-pagination class="pagination_data_list" v-model="page" color="#4EACDF" :length="pages"
                :total-visible="7"></v-pagination>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { base64Encode, base64Decode } from '@/helpers/base64.js'
import { thaiDateShortMonth } from '@/helpers/dateformats.js'
import people from '@/api/manage_people/manage_people/people.js'
import VueExcelXlsx from 'vue-excel-xlsx';
Vue.use(VueExcelXlsx);

export default {
    data() {
        return {
            loading_page: true,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            delete_id: null,
            search: '',
            dialog_select_add: false,
            data: [],
            page: 1,
            size: 50,
            pages: 1,
            columns: [
                {
                    label: "รหัสผู้ป่วย",
                    field: "hn",
                },
                {
                    label: "คำนำหน้า",
                    field: "pnam",
                },
                {
                    label: "ชื่อ",
                    field: "fname",
                },
                {
                    label: "นามสกุล",
                    field: "lname",
                },
                {
                    label: "เบอร์โทร",
                    field: "tel",
                },

            ],
            dataCheck: [
                {
                    hn: null,
                    fname: null,
                    lname: null,
                    pnam: null,
                    tel: null,
                },
            ],
        }
    },
    methods: {
        base64Encode(id) {
            return base64Encode(id)
        },
        base64Decode(id) {
            return base64Decode(id)
        },
        thaiDateShortMonth(date) {
            return thaiDateShortMonth(date)
        },
        async peopleList() {
            this.loading_page = true
            this.data = []
            people.peopleList(this.page, this.size, this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.peopleList
                    this.pages = response.data.pages
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async peopleCheck() {
            this.loading_page = true
            people.peopleCheck().then((response) => {
                if (response.data.successful === true) {
                    this.dataCheck = response.data.data
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async searchData() {
            this.data = []
            this.page = 1
            people.peopleList(this.page, this.size, this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.peopleList
                    this.pages = response.data.pages
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        priceFormat(value) {
            return '$ ' + value;
        }
    },
    mounted() {
        this.peopleList()
        this.peopleCheck()
    },
    watch: {
        page() {
            this.peopleList()
            this.peopleCheck()
        }
    }
}
</script>
<style>
.button_heading {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 21px;
    color: #FFFFFF !important;
    background: linear-gradient(90deg, #3c893f 0%, #53cd58 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    padding: 0 25px !important;
}
</style>