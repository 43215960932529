import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    provinceList: () => {
        return HTTP.get(`/address/addressList`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    amphuresList: (id) => {
        return HTTP.get(`/address/amphuresList/` + id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
    districtsList: (id) => {
        return HTTP.get(`/address/districtsList/` + id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
            .then(response => response)
    },
}