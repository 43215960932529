<template>
    <div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_heading_page_detail_data d-flex align-center">
            <div class="card_text_heading_data">ฟอร์มแก้ไขข้อมูลคิวนัดหมาย</div>
        </div>
        <div class="card_data_list">
            <v-form ref="form" @submit.prevent="editAppointment()">
                <div class="mb-3" v-for="(queue, index) in queueList" :key="index">
                    <div class="text_title mb-3">
                        <div class="image_title">
                            <img src="@/assets/images/user_fullname.jpg" alt="">
                        </div>
                        <div class="ml-2 mr-2">คนที่ {{ index + 1 }}</div>
                        <div class="text-center" v-if="index != 0">
                            <v-btn type="button" rounded color="error" dark @click="deleteQueue(queue.no)">
                                <v-icon>mdi-close-circle</v-icon> ลบ
                            </v-btn>
                        </div>
                    </div>
                    <div class="layout_all">
                        <div>
                            <div class="title_detail_all mb-3">ข้อมูลผู้ป่วย</div>
                            <div class="detail_title">
                                <div>เลขผู้ป่วย <span class="error--text">*</span></div>
                                <div class="d-flex otp_hn">
                                    <v-text-field class="input_detail_title" v-model="queue.hn[0]" type="tel" ref="hn_1"
                                        @focus="selectOPTInput" @keyup="focusHNInput($event, 'hn_1', 'hn_2', 0, index)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo
                                        required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="queue.hn[1]" type="tel" ref="hn_2"
                                        @focus="selectOPTInput" @keyup="focusHNInput($event, 'hn_1', 'hn_3', 1, index)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo
                                        required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="queue.hn[2]" type="tel" ref="hn_3"
                                        @focus="selectOPTInput" @keyup="focusHNInput($event, 'hn_2', 'hn_4', 2, index)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo
                                        required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="queue.hn[3]" type="tel" ref="hn_4"
                                        @focus="selectOPTInput" @keyup="focusHNInput($event, 'hn_3', 'hn_5', 3, index)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo
                                        required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="queue.hn[4]" type="tel" ref="hn_5"
                                        @focus="selectOPTInput" @keyup="focusHNInput($event, 'hn_4', 'hn_6', 4, index)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo
                                        required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="queue.hn[5]" type="tel" ref="hn_6"
                                        @focus="selectOPTInput" @keyup="focusHNInput($event, 'hn_5', 'hn_7', 5, index)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo
                                        required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="queue.hn[6]" type="tel" ref="hn_7"
                                        @focus="selectOPTInput" @keyup="focusHNInput($event, 'hn_6', 'hn_8', 6, index)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo
                                        required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="queue.hn[7]" type="tel" ref="hn_8"
                                        @focus="selectOPTInput" @keyup="focusHNInput($event, 'hn_7', 'hn_9', 7, index)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo
                                        required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="queue.hn[8]" type="tel" ref="hn_9"
                                        @focus="selectOPTInput" @keyup="focusHNInput($event, 'hn_8', 'hn_9', 8, index)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo
                                        required></v-text-field>
                                </div>
                            </div>
                            <div class="detail_title">
                                <div class="d-flex align-center mb-4">
                                    <div class="mr-2">ชื่อ-สกุล</div>
                                    <div class="name_tel_data_patient">{{ queue.name ? queue.name : '-' }}</div>
                                </div>
                                <div class="d-flex align-center">
                                    <div class="mr-2">เบอร์โทร</div>
                                    <div class="name_tel_data_patient">{{ queue.tel ? queue.tel : '-' }}</div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="title_detail_all mb-3">ข้อมูลการนัดหมาย</div>
                            <div class="detail_title_all layout_data_appointment">
                                <div class="detail_title">
                                    <div>วันที่ <span class="error--text">*</span></div>
                                    <v-menu ref="menu_appointment_date" v-model="queue.menu_appointment_date"
                                        :close-on-content-click="false" :return-value.sync="queue.appointment_date"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="input_detail_title" v-model="queue.appointment_date_be"
                                                :rules="[v => !!v || 'กรุณาเลือกวันที่']" label="กรุณาเลือกวันที่" readonly
                                                v-bind="attrs" v-on="on" solo required></v-text-field>
                                        </template>
                                        <v-date-picker v-model="queue.appointment_date" no-title scrollable locale="th"
                                            :min="minDate" :events="appointmentDateBE(index)">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="selectAppointmentDate(index)">
                                                ตกลง
                                            </v-btn>
                                            <v-btn text color="primary" @click="queue.menu_appointment_date = false">
                                                ยกเลิก
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </div>
                                <div class="detail_title">
                                    <div>เวลา <span class="error--text">*</span></div>
                                    <v-select class="select_detail_title" v-model="queue.meeting_time"
                                        :rules="[v => !!v || 'กรุณาเลือกเวลา']" label="กรุณาเลือกเวลา" :items="time_list"
                                        item-text="time" item-value="time" solo required></v-select>
                                </div>
                                <div class="detail_title">
                                    <div>คลินิก <span class="error--text">*</span></div>
                                    <v-select class="select_detail_title" v-model="queue.clinic_name"
                                        :rules="[v => !!v || 'กรุณาเลือกคลินิก']" label="กรุณาเลือกคลินิก"
                                        :items="clinic_list" item-text="name" item-value="name" solo required></v-select>
                                </div>
                            </div>
                            <div class="detail_title_all layout_two_data_appointment">
                                <div class="detail_title">
                                    <div>แพทย์ผู้นัดหมาย <span class="error--text">*</span></div>
                                    <v-text-field class="input_detail_title" v-model="queue.medic"
                                        :rules="[v => !!v || 'กรุณากรอกแพทย์ผู้นัดหมาย']" label="กรุณากรอกแพทย์ผู้นัดหมาย"
                                        solo required></v-text-field>
                                </div>
                                <div class="detail_title">
                                    <div>ห้องตรวจ</div>
                                    <v-text-field class="input_detail_title" v-model="queue.room" label="กรุณากรอกห้องตรวจ"
                                        solo></v-text-field>
                                </div>
                            </div>
                            <div class="detail_title_all layout_three_data_appointment">
                                <div class="detail_title">
                                    <div>หมายเหตุ</div>
                                    <v-text-field class="input_detail_title" v-model="queue.note" label="กรุณากรอกหมายเหตุ"
                                        solo></v-text-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right mb-4">
                    <v-btn @click="addmulti_queue()" class="button_heading" height="62"><v-icon>mdi-plus</v-icon>
                        <div class="button_text_heading">เพิ่มช่องกรอก</div>
                    </v-btn>
                </div>
                <div class="text-center">
                    <v-btn class="white--text button_save" type="submit" height="43" width="40%"
                        color="#37AE46">บันทึกข้อมูลการแจ้งเตือน
                    </v-btn>
                </div>
            </v-form>
        </div>
        <v-dialog v-model="open_edit_success" persistent max-width="600px">
            <v-card class="page_confirm_success_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-icon size="200" color="success">mdi-check-circle</v-icon>
                            </div>
                            <div class="text_confirm_success">สำเร็จแก้ไขฟอร์มข้อมูลคิวนัดหมายเรียบร้อยแล้ว</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_success">
                    <v-btn class="button_text_confirm_success" @click="$router.push('/viewlistappointmentall')" height="43"
                        width="40%" color="#4EACDF">ตกลง</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { isNumberOnly } from '@/helpers/numberformats.js'
import { base64Decode } from '@/helpers/base64.js'
import { monthList } from '@/helpers/dateformats.js'
import appointmentall from '@/api/hospital/appointment/viewlistappointmentall/appointmentall.js'
export default {
    data() {
        return {
            loading_page: true,
            alert_error: false,
            alert_message_error: '',
            open_edit_success: false,
            minDate: null,
            time_list: [
                { id: 1, time: '8:30' },
                { id: 2, time: '9:00' },
                { id: 3, time: '9:30' },
                { id: 4, time: '10:00' },
                { id: 5, time: '10:30' },
                { id: 6, time: '11:00' },
                { id: 7, time: '11:30' },
                { id: 8, time: '13:00' },
                { id: 9, time: '13:30' },
                { id: 10, time: '14:00' },
                { id: 11, time: '14:30' },
                { id: 12, time: '15:00' },
                { id: 13, time: '15:30' },
                { id: 14, time: '16:00' },
            ],
            clinic_list: [
                { id: 1, name: 'ตรวจโรคทั่วไป' },
                { id: 2, name: 'COPD' },
                { id: 3, name: 'คลินิกความดัน' },
                { id: 4, name: 'คลินิกโรคเบาหวาน' },
                { id: 5, name: 'จิตเวช' },
                { id: 6, name: 'ถุงลมโป่งพอง' },
                { id: 7, name: 'ทันตกรรม' },
                { id: 8, name: 'ฝากครรภ์' },
                { id: 9, name: 'โรคซึมเศร้า' },
                { id: 10, name: 'โรคไต' },
                { id: 11, name: 'โรคหลอดเลือดสมอง' },
                { id: 12, name: 'โรคหัวใจล้มเหลว' },
                { id: 13, name: 'วางแผนครอบครัว' },
                { id: 14, name: 'สุขภาพเด็กดี' },
                { id: 15, name: 'หัวใจขาดเลือด' },
            ],
            no: 0,
            queueList: [],
        }
    },
    methods: {
        async isNumberOnly(evt) {
            return isNumberOnly(evt)
        },
        base64Decode(id) {
            return base64Decode(id)
        },
        async selectOPTInput(event) {
            event.target.select()
        },
        async focusHNInput(event, first, last, arr_current, index) {
            let evt = (event) ? event : window.event
            let charCode = (evt.which) ? evt.which : evt.keyCode

            if (charCode == 9) {
                return true
            }
            if (event.key >= 0 && event.key <= 9 && this.queueList[index].hn[arr_current] != '') {
                this.$refs[last][index].focus()
            }
            if (charCode == 8) {
                this.$refs[first][index].focus()
            }

            this.queueList[index].name = null
            this.queueList[index].tel = null
            this.queueList[index].menu_appointment_date = false,
                this.queueList[index].appointment_date_be = '',
                this.queueList[index].appointment_date = ''
            this.queueList[index].meeting_time = null
            this.queueList[index].clinic_name = null
            this.queueList[index].medic = ''
            this.queueList[index].room = ''
            this.queueList[index].note = ''
            this.queueList[index].coming_date = null
            if (this.queueList[index].hn.join('').length >= 9) {
                this.loading_page = true
                const data = {
                    hn: this.queueList[index].hn.join('')
                }
                appointmentall.searchCodeHN(data).then((response) => {
                    this.loading_page = false
                    if (response.data.successful === true) {
                        this.queueList[index].name = response.data.hn_code.name
                        this.queueList[index].tel = response.data.hn_code.tel
                        this.queueList[index].appointment_date = response.data.hn_code.appointment_date
                        this.queueList[index].meeting_time = response.data.hn_code.meeting_time
                        let meetingTime = response.data.hn_code.meeting_time.split(':')
                        if (meetingTime.length >= 3) {
                            this.queueList[index].meeting_time = `${meetingTime[0]}:${meetingTime[1]}`
                        }
                        this.queueList[index].clinic_name = response.data.hn_code.clinic_name
                        this.queueList[index].medic = response.data.hn_code.medic
                        this.queueList[index].room = response.data.hn_code.room
                        this.queueList[index].note = response.data.hn_code.note
                        this.queueList[index].coming_date = response.data.hn_code.coming_date
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        },
        appointmentDateBE(key) {
            if (this.queueList[key].appointment_date == '') {
                this.queueList[key].appointment_date_be = ''
            }
            if (this.queueList[key].appointment_date) {
                let date_arr = this.queueList[key].appointment_date.split('-')
                let year = parseInt(date_arr[0]) + 543
                let month = parseInt(date_arr[1])
                month = monthList().find((detail) => { return detail.id == month }).name_short
                this.queueList[key].appointment_date_be = `${date_arr[2]} ${month} ${year}`
            }
        },
        async selectAppointmentDate(key) {
            this.$refs.menu_appointment_date[key].save(this.queueList[key].appointment_date)
            let date_arr = this.queueList[key].appointment_date.split('-')
            let year = parseInt(date_arr[0]) + 543
            let month = parseInt(date_arr[1])
            month = monthList().find((detail) => { return detail.id == month }).name_short
            this.queueList[key].appointment_date_be = `${date_arr[2]}/${month}/${year}`
            this.queueList[key].menu_appointment_date = false
        },
        async appointmentDetailForEdit() {
            this.loading_page = true
            this.queueList = []
            appointmentall.appointmentDetailForEdit(this.base64Decode(this.$route.params.id)).then((response) => {
                if (response.data.successful === true) {
                    let queueDetailAll_num = response.data.list.length
                    for (let index = 0; index < queueDetailAll_num; index++) {
                        let resultMeetingTime = response.data.list[index].meeting_time
                        let meetingTimeNum = response.data.list[index].meeting_time.split(':')
                        if (meetingTimeNum.length >= 3) {
                            resultMeetingTime = `${meetingTimeNum[0]}:${meetingTimeNum[1]}`
                        }
                        this.no += 1
                        this.queueList.push(
                            {
                                no: this.no,
                                id: response.data.list[index].id,
                                hn: response.data.list[index].hn.split(''),
                                name: response.data.list[index].name,
                                tel: response.data.list[index].tel,
                                menu_appointment_date: false,
                                appointment_date_be: '',
                                appointment_date: response.data.list[index].appointment_date,
                                meeting_time: resultMeetingTime,
                                clinic_name: response.data.list[index].clinic_name,
                                medic: response.data.list[index].medic,
                                room: response.data.list[index].room,
                                note: response.data.list[index].note,
                                round: response.data.list[index].round,
                                coming_date: response.data.list[index].coming_date,
                            },
                        )
                    }
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async deleteQueue(no) {
            this.queueList = this.queueList.filter((detail) => detail.no !== no)
        },
        async addmulti_queue() {
            this.no += 1
            this.queueList.push(
                {
                    no: this.no,
                    id: null,
                    hn: ['', '', '', '', '', '', '', '', ''],
                    name: null,
                    tel: null,
                    menu_appointment_date: false,
                    appointment_date_be: '',
                    appointment_date: '',
                    meeting_time: null,
                    clinic_name: null,
                    medic: '',
                    room: '',
                    note: '',
                    round: this.queueList[0].round,
                    coming_date: null,
                }
            )
        },
        async editAppointment() {
            if (this.$refs.form.validate()) {
                const num_queue = this.queueList.length
                let check_hn = true
                let dataQueue = []
                for (let index = 0; index < num_queue; index++) {
                    let result_hn = this.queueList[index].hn.join('')
                    if (result_hn.length < 9) {
                        check_hn = false
                        this.alert_message_error = 'กรุณากรอกเลขผู้ป่วยให้ครบ 9 หลัก'
                        this.alert_error = true
                        setTimeout(() => this.alert_error = false, 5000)
                        return
                    }
                    dataQueue.push({
                        id: this.queueList[index].id,
                        hn: result_hn,
                        name: this.queueList[index].name,
                        tel: this.queueList[index].tel,
                        appointment_date: this.queueList[index].appointment_date,
                        meeting_time: this.queueList[index].meeting_time,
                        clinic_name: this.queueList[index].clinic_name,
                        medic: this.queueList[index].medic,
                        room: this.queueList[index].room,
                        note: this.queueList[index].note,
                        round: this.queueList[index].round,
                        coming_date: this.queueList[index].coming_date,
                    })
                }

                if (check_hn) {
                    this.loading_page = true
                    const data = {
                        editListqueue: dataQueue
                    }
                    appointmentall.editAppointment(data).then((response) => {
                        if (response.data.successful === true) {
                            this.loading_page = false
                            this.open_edit_success = true
                        }
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            this.$store.commit('logOutSuccess')
                            this.$router.push('/login')
                        }
                    })
                }
            }
        }
    },

    mounted() {
        this.appointmentDetailForEdit()
        const today = new Date();
        this.minDate = today.toISOString().substr(0, 10);
    }
}
</script>

<style scoped>
.image_title {
    width: 50px;
}

.image_title>img {
    max-width: 100%;
}

.text_title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: center;
}

.title_detail_all {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
}

.layout_all {
    display: flex;
}

.layout_all>div:first-child {
    width: 40%;
    padding-left: 60px;
    border-right: 1px solid #A4A4A4;
}

.layout_all>div {
    width: 60%;
    padding-left: 30px;
}

.detail_title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #414141;
    margin-right: 20px;
}

.detail_title:last-child {
    margin-right: 0;
}

.detail_title>div {
    margin-bottom: 5px;
}

.select_detail_title {
    border-radius: 10px;
}

.input_detail_title {
    border-radius: 10px;
}

.otp_hn>div {
    margin-right: 6px;
}

.otp_hn>div:last-child {
    margin-right: 0;
}

.otp_hn>>>.v-text-field.v-text-field--solo .v-label,
.otp_hn>>>.v-input__control input {
    text-align: center !important;
    width: 100% !important;
}

.name_tel_data_patient {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #414141;
}

.detail_title_all {
    display: flex;
    align-items: center;
}

.layout_data_appointment>div:first-child {
    width: 40%;
}

.layout_data_appointment>div {
    width: 20%;
}

.layout_data_appointment>div:last-child {
    width: 40%;
}

.layout_two_data_appointment>div:first-child {
    width: 40%;
}

.layout_two_data_appointment>div {
    width: 60%;
}

.layout_three_data_appointment>div {
    width: 60%;
}

.button_heading {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 21px;
    color: #FFFFFF !important;
    background: #151B54 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    padding: 0 25px !important;
}

.button_text_heading {
    margin-left: 5px;
}

.button_save {
    border: 1px solid #37AE46;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}

@media only screen and (max-width: 1200px) {
    .layout_all {
        display: block;
    }

    .layout_all>div:first-child {
        width: 100%;
        padding-left: 0;
        border-right: unset;
    }

    .layout_all>div {
        width: 100%;
        padding-left: 0;
    }

    .detail_title_all {
        display: block;
    }

    .layout_data_appointment>div:first-child {
        width: 100%;
    }

    .layout_data_appointment>div {
        width: 100%;
    }

    .layout_data_appointment>div:last-child {
        width: 100%;
    }

    .layout_two_data_appointment>div:first-child {
        width: 100%;
    }

    .layout_two_data_appointment>div {
        width: 100%;
    }

    .layout_three_data_appointment>div {
        width: 100%;
    }

    .button_save {
        width: 80% !important;
    }
}
</style>