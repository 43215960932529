import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    permissionList: () => {
        return HTTP.get('/permission/list', {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
}