<template>
    <div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_heading_page_detail_data d-flex align-center">
            <div class="card_text_heading_data">แก้ไขข้อมูลประชาชน</div>
        </div>
        <div class="card_data_list">
            <v-form ref="form" @submit.prevent="editPeople()">
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="2" xl="1">
                        <div class="text_title">
                            <div class="image_title">
                                <img src="@/assets/images/user_fullname.jpg" alt="">
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="11" xl="11">
                        <div class="detail_title_all layout_number_otp">
                            <v-row>
                                <v-col xs="12" sm="12" md="12" lg="6" xl="6" cols="12">
                                    <div class="detail_title">
                                        <div>เลขผู้ป่วย ( hn ) <span class="error--text">*</span></div>
                                        <div class="d-flex otp_hn">
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.hn[0]" type="tel"
                                                ref="hn_1" @focus="selectHNInput"
                                                @keyup="focusHNInput($event, 'hn_1', 'hn_2')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.hn[1]" type="tel"
                                                ref="hn_2" @focus="selectHNInput"
                                                @keyup="focusHNInput($event, 'hn_1', 'hn_3')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.hn[2]" type="tel"
                                                ref="hn_3" @focus="selectHNInput"
                                                @keyup="focusHNInput($event, 'hn_2', 'hn_4')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.hn[3]" type="tel"
                                                ref="hn_4" @focus="selectHNInput"
                                                @keyup="focusHNInput($event, 'hn_3', 'hn_5')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.hn[4]" type="tel"
                                                ref="hn_5" @focus="selectHNInput"
                                                @keyup="focusHNInput($event, 'hn_4', 'hn_6')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.hn[5]" type="tel"
                                                ref="hn_6" @focus="selectHNInput"
                                                @keyup="focusHNInput($event, 'hn_5', 'hn_7')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.hn[6]" type="tel"
                                                ref="hn_7" @focus="selectHNInput"
                                                @keyup="focusHNInput($event, 'hn_6', 'hn_8')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.hn[7]" type="tel"
                                                ref="hn_8" @focus="selectHNInput"
                                                @keyup="focusHNInput($event, 'hn_7', 'hn_9')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.hn[8]" type="tel"
                                                ref="hn_9" @focus="selectHNInput"
                                                @keyup="focusHNInput($event, 'hn_8', 'hn_9')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col xs="12" sm="12" md="12" lg="6" xl="6" cols="12">
                                    <div class="detail_title">
                                        <div>เบอร์โทร</div>
                                        <div class="d-flex otp_tel">
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.tel[0]"
                                                type="tel" ref="tel_1" @focus="selectOPTInput"
                                                @keyup="focusOPTInput($event, 'tel_1', 'tel_2')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.tel[1]"
                                                type="tel" ref="tel_2" @focus="selectOPTInput"
                                                @keyup="focusOPTInput($event, 'tel_1', 'tel_3')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.tel[2]"
                                                type="tel" ref="tel_3" @focus="selectOPTInput"
                                                @keyup="focusOPTInput($event, 'tel_2', 'tel_4')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <div class="line_otp_tel">
                                                <v-icon>mdi-minus-thick</v-icon>
                                            </div>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.tel[3]"
                                                type="tel" ref="tel_4" @focus="selectOPTInput"
                                                @keyup="focusOPTInput($event, 'tel_3', 'tel_5')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.tel[4]"
                                                type="tel" ref="tel_5" @focus="selectOPTInput"
                                                @keyup="focusOPTInput($event, 'tel_4', 'tel_6')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.tel[5]"
                                                type="tel" ref="tel_6" @focus="selectOPTInput"
                                                @keyup="focusOPTInput($event, 'tel_5', 'tel_7')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.tel[6]"
                                                type="tel" ref="tel_7" @focus="selectOPTInput"
                                                @keyup="focusOPTInput($event, 'tel_6', 'tel_8')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.tel[7]"
                                                type="tel" ref="tel_8" @focus="selectOPTInput"
                                                @keyup="focusOPTInput($event, 'tel_7', 'tel_9')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.tel[8]"
                                                type="tel" ref="tel_9" @focus="selectOPTInput"
                                                @keyup="focusOPTInput($event, 'tel_8', 'tel_10')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                            <v-text-field class="input_detail_title" v-model="peopleDetail.tel[9]"
                                                type="tel" ref="tel_10" @focus="selectOPTInput"
                                                @keyup="focusOPTInput($event, 'tel_9', 'tel_10')"
                                                @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1"
                                                label="X" solo required></v-text-field>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="detail_title_all layout_fullname">
                            <div class="detail_title">
                                <div>คำนำหน้า <span class="error--text">*</span></div>
                                <v-select class="select_detail_title" v-model="peopleDetail.pnam"
                                    :rules="[v => !!v || 'กรุณาเลือกคำนำหน้า']" label="กรุณาเลือกคำนำหน้า"
                                    :items="title_list" item-text="title_name" item-value="title_name" solo
                                    required></v-select>
                            </div>
                            <div class="detail_title">
                                <div>ชื่อ <span class="error--text">*</span></div>
                                <v-text-field class="input_detail_title" v-model="peopleDetail.fname"
                                    :rules="[v => !!v || 'กรุณากรอกชื่อ']" label="กรุณากรอกชื่อ" solo
                                    required></v-text-field>
                            </div>
                            <div class="detail_title">
                                <div>นามสกุล <span class="error--text">*</span></div>
                                <v-text-field class="input_detail_title" v-model="peopleDetail.lname"
                                    :rules="[v => !!v || 'กรุณากรอกนามสกุล']" label="กรุณากรอกนามสกุล" solo
                                    required></v-text-field>
                            </div>
                            <div class="detail_title">
                                <div>วันเกิด <span class="error--text">*</span></div>
                                <v-menu ref="menu_birthday" v-model="peopleDetail.menu_birthday"
                                    :close-on-content-click="false" :return-value.sync="peopleDetail.birthday"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="input_detail_title" v-model="peopleDetail.birthday_be"
                                            :rules="[v => !!v || 'กรุณาเลือกวันเกิด']" label="กรุณาเลือกวันเกิด" readonly
                                            v-bind="attrs" v-on="on" solo required></v-text-field>
                                    </template>
                                    <v-date-picker v-model="peopleDetail.birthday" no-title scrollable locale="th"
                                        :events="birthdayBE()">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="selectBirthday()">
                                            ตกลง
                                        </v-btn>
                                        <v-btn text color="primary" @click="peopleDetail.menu_birthday = false">
                                            ยกเลิก
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="detail_title_all layout_address">
                            <div class="detail_title">
                                <div>ที่อยู่ </div>
                                <v-text-field class="input_detail_title" v-model="peopleDetail.addrpa"
                                    label="กรุณากรอกที่อยู่" solo></v-text-field>
                            </div>
                            <div class="detail_title">
                                <div>จังหวัด </div>
                                <v-select @change="select_province(peopleDetail.chwpa)" :items="peopleDetail.province_list"
                                    item-text="name_th" item-value="id" class="input_detail_title"
                                    v-model="peopleDetail.chwpa" label="กรุณาเลือกจังหวัด" solo></v-select>
                            </div>
                            <div class="detail_title">
                                <div>อำเภอ </div>
                                <v-select @change="select_amphures(peopleDetail.amppa)" :items="peopleDetail.amphures_list"
                                    item-text="name_th" item-value="id" class="input_detail_title"
                                    v-model="peopleDetail.amppa" label="กรุณาเลือกอำเภอ" solo></v-select>
                            </div>
                            <div class="detail_title">
                                <div>ตำบล </div>
                                <v-select :items="peopleDetail.districts_list" item-text="name_th" item-value="id"
                                    class="input_detail_title" v-model="peopleDetail.tmbpa" label="กรุณาเลือกตำบล"
                                    solo></v-select>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="text-center">
                            <v-btn class="white--text button_save" type="submit" height="43" width="40%"
                                color="#37AE46">บันทึกข้อมูลประชาชน
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </div>
        <v-dialog v-model="open_edit_success" persistent max-width="600px">
            <v-card class="page_confirm_success_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-icon size="200" color="success">mdi-check-circle</v-icon>
                            </div>
                            <div class="text_confirm_success">สำเร็จแก้ไขข้อมูลประชาชนเรียบร้อยแล้ว</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_success">
                    <v-btn class="button_text_confirm_success" @click="$router.push('/managepeople')" height="43"
                        width="40%" color="#4EACDF">ตกลง</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script src="@/helpers/previewimage.js"></script>
<script>
import { isNumberOnly } from '@/helpers/numberformats.js'
import { base64Decode } from '@/helpers/base64.js'
import { monthList } from '@/helpers/dateformats.js'
import title from '@/api/manage_users/title/title.js'
import province from '@/api/manage_people/manage_people/address.js'
import people from '@/api/manage_people/manage_people/people.js'
export default {
    data(){
        return{
            loading_page: true,
            alert_error: false,
            alert_message_error: '',
            open_edit_success: false,
            title_list: [],
            default_province_list:[],
            peopleDetail:{
                hn: ['','','','','','','','',''],
                tel:['','','','','','','','','',''],
                pnam:null,
                fname:'',
                lname:'',
                menu_birthday:false,
                birthday_be:'',
                birthday:'',
                addrpa:'',
                province_list:[],
                amphures_list:[],
                districts_list:[],
                chwpa:'',
                amppa:'',
                tmbpa:''
            },
        }
    },
    methods:{
        async isNumberOnly(evt){
            return isNumberOnly(evt)
        },
        base64Decode(id){
            return base64Decode(id)
        },
        async selectHNInput(event) {
            event.target.select()
        },
        async focusHNInput(event,first,last) {
            let evt = (event) ? event : window.event
            let charCode = (evt.which) ? evt.which : evt.keyCode

            if(charCode == 9){
                return true
            }
            if (event.key >= 0 && event.key <= 9 && this.peopleDetail.hn != '') {
                this.$refs[last].focus()
            }
            if(charCode == 8){
                this.$refs[first].focus()
            }

            let result_hn_default = this.peopleDetail.hn_default.join('')
            let result_hn = this.peopleDetail.hn.join('')
            if (result_hn.length >= 9 && result_hn_default != result_hn) {
                people.checkHNPeople(result_hn).then((response) => {
                    if (response.data.successful === false) {
                        this.peopleDetail.hn = ['','','','','','','','','']
                        this.$refs['hn_1'].focus()
                        this.alert_message_error = 'เลขผู้ป่วยนี้มีผู้ใช้งานแล้ว'
                        this.alert_error = true
                        console.log(this.alert_error);
                        setTimeout(() => this.alert_error = false, 5000)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        },
        async selectOPTInput(event) {
            event.target.select()
        },
        async focusOPTInput(event,first,last) {
            let evt = (event) ? event : window.event
            let charCode = (evt.which) ? evt.which : evt.keyCode
            if(charCode == 9){
                return true
            }
            if (event.key >= 0 && event.key <= 9 && this.peopleDetail.tel != '') {
                this.$refs[last].focus()
            }
            if(charCode == 8){
                this.$refs[first].focus()
            }
        },
        birthdayBE(){
            if (this.peopleDetail.birthday == '') {
                this.peopleDetail.birthday_be = ''
            }
            if (this.peopleDetail.birthday) {
                let date_arr = this.peopleDetail.birthday.split('-')
                let year = parseInt(date_arr[0]) + 543
                let month = parseInt(date_arr[1])
                month = monthList().find((detail) => { return detail.id == month }).name_short
                this.peopleDetail.birthday_be = `${date_arr[2]} ${month} ${year}`
            }
        },
        async selectBirthday(){
            this.$refs.menu_birthday.save(this.peopleDetail.birthday)
            let date_arr = this.peopleDetail.birthday.split('-')
            let year = parseInt(date_arr[0]) + 543
            let month = parseInt(date_arr[1])
            month = monthList().find((detail) => { return detail.id == month }).name_short
            this.peopleDetail.birthday_be = `${date_arr[2]}/${month}/${year}`
            this.peopleDetail.menu_birthday = false
        },
        async selectDataAllAndPeopleDetail(){
            this.loading_page = true
            this.title_list = []
            await title.titleList().then((response) => {
                if (response.data.successful === true) {
                    this.title_list = response.data.titleList
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
            this.default_province_list = []
            await province.provinceList().then((response) => {
                if (response.data.successful === true) {
                    this.default_province_list = response.data.provinceList
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })

            this.peopleDetail = {
                hn_default: ['','','','','','','','',''],
                hn: ['','','','','','','','',''],
                tel:['','','','','','','','','',''],
                pnam:null,
                fname:'',
                lname:'',
                menu_birthday:false,
                birthday_be:'',
                birthday:'',
                addrpa:'',
                province_list:this.default_province_list,
                amphures_list:[],
                districts_list:[],
                chwpa:'',
                amppa:'',
                tmbpa:''
            }
            await people.peopleDetail(this.base64Decode(this.$route.params.id)).then((response) => {
                if (response.data.successful === true) {
                    this.peopleDetail.hn_default = response.data.peopleList_edit.hn.split('')
                    this.peopleDetail.hn = response.data.peopleList_edit.hn.split('')
                    if (response.data.peopleList_edit.tel) {
                        this.peopleDetail.tel = response.data.peopleList_edit.tel.split('')
                    }
                    this.peopleDetail.pnam = response.data.peopleList_edit.pnam
                    this.peopleDetail.fname = response.data.peopleList_edit.fname
                    this.peopleDetail.lname = response.data.peopleList_edit.lname
                    this.peopleDetail.birthday = response.data.peopleList_edit.birthday
                    this.peopleDetail.addrpa = response.data.peopleList_edit.addrpa
                    this.peopleDetail.chwpa = response.data.peopleList_edit.province_id
                    this.select_province(response.data.peopleList_edit.province_id)
                    this.peopleDetail.amppa = response.data.peopleList_edit.amphures_id
                    this.select_amphures(response.data.peopleList_edit.amphures_id)
                    this.peopleDetail.tmbpa = response.data.peopleList_edit.district_id
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async select_province(evt) {
            this.peopleDetail.amphures_list = []
            this.peopleDetail.districts_list = []
            await province.amphuresList(evt).then((response) => {
                if (response.data.successful === true) {
                    this.peopleDetail.amphures_list = response.data.amphuresList
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async select_amphures(evt) {
            this.peopleDetail.districts_list = []
            await province.districtsList(evt).then((response) => {
                if (response.data.successful === true) {
                    this.peopleDetail.districts_list = response.data.districtsList
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async editPeople(){
           if (this.$refs.form.validate()){
                let result_hn = this.peopleDetail.hn.join('')
                let result_telephone = this.peopleDetail.tel.join('')
                // console.log(result_hn.length);
                if (result_hn.length < 9) {
                    this.alert_message_error = 'กรุณากรอกเลขผู้ป่วยให้ครบ 9 หลัก'
                    this.alert_error = true
                    setTimeout(() => this.alert_error = false, 5000)
                }else if(result_telephone.length > 0 && result_telephone.length < 10) {
                    this.alert_message_error = 'กรุณากรอกเบอร์โทรให้ครบ 10 หลัก'
                    this.alert_error = true
                    setTimeout(() => this.alert_error = false, 5000)
                } else {
                    this.loading_page = true
                    let dataPeople = {
                        hn: result_hn,
                        pnam: this.peopleDetail.pnam,
                        fname: this.peopleDetail.fname,
                        lname: this.peopleDetail.lname,
                        birthday: this.peopleDetail.birthday,
                        addrpa: this.peopleDetail.addrpa,
                        tmbpa: this.peopleDetail.tmbpa,
                        amppa: this.peopleDetail.amppa,
                        chwpa: this.peopleDetail.chwpa,
                        tel: result_telephone,
                    }
                    const data = {
                        insertListpeople: dataPeople
                    }
                    people.editPeople(this.base64Decode(this.$route.params.id),data).then((response) => {
                        if (response.data.successful === true) {
                            this.loading_page = false
                            this.open_edit_success = true
                        }
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            this.$store.commit('logOutSuccess')
                            this.$router.push('/login')
                        }
                    })
                }
           }
        },
    },
    mounted(){
        this.selectDataAllAndPeopleDetail()
    }
}
</script>

<style scoped>
.button_heading {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 21px;
    color: #FFFFFF !important;
    background: #151B54 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    padding: 0 25px !important;
}

.button_text_heading {
    margin-left: 5px;
}

.image_preview>.v-avatar {
    overflow: unset;
}

.upload_image {
    position: absolute;
    top: 0;
    right: 0;
}

.image_title {
    width: 34px;
}

.image_title>img {
    max-width: 100%;
}

.text_title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: center;
}

.detail_title_all {
    display: flex;
    align-items: center;
    padding-left: 60px;
}

.layout_fullname>div:first-child {
    width: 20%;
}

.layout_fullname>div {
    width: 40%;
}

.layout_address>div:first-child {
    width: 100%;
}

.layout_address>div {
    width: 42%;
}

.detail_title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #414141;
    margin-right: 20px;
}

.detail_title:last-child {
    margin-right: 0;
}

.detail_title>div {
    margin-bottom: 5px;
}

.select_detail_title {
    border-radius: 10px;
}

.input_detail_title {
    border-radius: 10px;
}

.layout_number_otp>div:first-child {
    width: 65%;
}

.layout_number_otp>div {
    width: 42%;
}

.otp_hn {
    margin-right: 40px !important;
}

.otp_hn>div {
    margin-right: 8px;
}

.otp_hn>div:last-child {
    margin-right: 0;
}

.otp_hn>>>.v-text-field.v-text-field--solo .v-label,
.otp_hn>>>.v-input__control input {
    text-align: center !important;
    width: 100% !important;
}

.otp_tel>div {
    margin-right: 6px;
}

.otp_tel>div:last-child {
    margin-right: 0;
}

.otp_tel>>>.v-text-field.v-text-field--solo .v-label,
.otp_tel>>>.v-input__control input {
    text-align: center !important;
    width: 100% !important;
}

.line_otp_tel {
    position: relative;
    top: 13px;
}

.layout_position>div:first-child {
    width: 40%;
}

.layout_position>div {
    width: 60%;
}

.button_save {
    border: 1px solid #37AE46;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}

@media only screen and (max-width: 1000px) {
    .button_save {
        width: 80% !important;
    }

    .detail_title_all {
        display: block;
        margin-right: 0;
        padding-left: 0;
    }

    .layout_fullname>div:first-child {
        width: 100%;
    }

    .layout_fullname>div {
        width: 100%;
    }

    .layout_address>div:first-child {
        width: 100%;
    }

    .layout_address>div {
        width: 100%;
    }

    .layout_number_otp>div:first-child {
        width: 100%;
    }

    .layout_number_otp>div {
        width: 100%;
    }

    .layout_position>div:first-child {
        width: 100%;
    }

    .layout_position>div {
        width: 100%;
    }

    .otp_hn {
        margin-right: 0px !important;
    }
}
</style>