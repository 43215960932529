<template>
  <div>
    <Heading heading="เพิ่มผู้ดูแลระบบ" sub_heading="จัดการผู้ดูแลระบบ" sub_secondary_heading="รายการผู้ดูแลระบบ"/>
    <AddAdminList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import AddAdminList from '@/components/manage_users/manage_admins/AddAdminList'
export default {
  name: 'addAdmin',
  components:{
    Heading,
    AddAdminList
  }
}
</script>