<template>
    <div class="pa-6">
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <div class="title_heading mb-5">แก้ไขข้อมูล</div>
        <div>
            <v-card class="card_line_oa card_all_edit_user_information">
                <v-form ref="form" @submit.prevent="editUserInformation()">
                    <div class="card_detail_edit_user_information">
                        <div class="mb-2">เบอร์โทร <span class="error--text">*</span></div>
                        <v-text-field class="input_line_oa" v-model="tel" type="tel" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="10" :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']" placeholder="กรุณากรอกเบอร์โทร" outlined required></v-text-field>
                    </div>
                    <v-btn @click="open_add_success = true" class="white--text button_edit_user_information" type="submit" height="43" width="100%">ยืนยันการแก้ไข</v-btn>
                </v-form>
            </v-card>
        </div>
        <v-dialog v-model="open_add_success" persistent max-width="600px">
            <v-card class="page_confirm_success_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                            <!-- <div class="image_confirm_success">
                                <img src="@/assets/images/trash_confirm_delete.jpg" alt="">
                            </div> -->
                            <div class="text-center">
                                <v-icon size="200" color="success">mdi-check-circle</v-icon>
                            </div>
                            <div class="text_confirm_success">สำเร็จแก้ไขข้อมูลผู้ใช้บริการเรียบร้อยแล้ว</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_success">
                    <v-btn class="button_text_confirm_success" @click="$router.push('/lineoa/userinformation')" height="43" width="40%" color="#4EACDF">ตกลง</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { base64Decode } from '@/helpers/base64.js'
import { isNumberOnly } from '@/helpers/numberformats.js'
import notify from '@/api/line_oa/notify/notify.js'
export default {
    data(){
        return{
            loading_page: true,
            open_add_success: false,
            tel: '',
        }
    },
    methods:{
        base64Decode(id){
            return base64Decode(id)
        },
        async isNumberOnly(evt){
            return isNumberOnly(evt)
        },
        async userInformationTel(){
            this.loading_page = true
            notify.userInformationTel(this.base64Decode(this.$route.params.id)).then((response) => {
                if (response.data.successful === true) {
                    this.tel = response.data.detail.tel
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async editUserInformation(){
            if (this.$refs.form.validate()){
                this.loading_page = true
                const data = {
                    id: this.base64Decode(this.$route.params.id),
                    tel: this.tel,
                }
                notify.editUserInformation(data).then((response) => {
                    if (response.data.successful === true) {
                        this.loading_page = false
                        this.open_add_success = true
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        },
    },
    mounted(){
        this.userInformationTel()
    }
}
</script>

<style scoped>
.title_heading{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #000000;
}

.card_all_edit_user_information{
    padding: 30px 15px;
}

.card_detail_edit_user_information{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #4D4D4D;
}

.button_edit_user_information{
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    background: linear-gradient(90deg, #151B54 0%, #4495C1 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px!important;
}

</style>