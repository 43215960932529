import About from '@/views/line_oa/about/About.vue'
import Healthy from '@/views/line_oa/about/Healthy.vue'
const main = '/lineoa'
export default [
    {
        path: main+'/about',
        name: 'lineoaAbout',
        component: About,
    },
    {
        path: main+'/Healthy',
        name: 'lineoaHealthy',
        component: Healthy,
    },
]

