<template>
  <div>
    <Heading heading="คิวนัดหมาย" sub_heading="นัดหมายผู้ป่วยมาตรวจที่โรงพยาบาล" dialog_link_name="เพิ่มข้อมูลคิวนัดหมาย" dialog_link_type="viewlistappointmentall" />
    <ViewListAppointmentAllList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import ViewListAppointmentAllList from '@/components/hospital/appointment/viewlistappointment/ViewListAppointmentAllList'
export default {
  name: 'viewListAppointmentAll',
  components:{
    Heading,
    ViewListAppointmentAllList
  }
}
</script>