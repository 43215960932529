<template>
    <v-app>
        <div class="login_page">
            <v-container>
                <div class="loading_all_page" v-if="loading_page === true">
                    <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
                    <h2>Loading...</h2>
                </div>
                <v-row class="justify-center">
                    <v-col cols="11" sm="10" md="11" lg="5" xl="6">
                        <v-card class="login" color="#FCFCFC">
                            <div class="logo">
                                <img src="@/assets/images/logo.png" alt="">
                            </div>
                            <div class="welcome_login mt-3">ยินดีต้อนรับ</div>
                            <hr class="mx-auto my-3 line_between_welcome">
                            <div class="text_login">เข้าสู่ระบบ</div>
                            <v-form ref="form" @submit.prevent="login()">
                                <div class="text_before_input_login mb-2">เบอร์โทร <span class="error--text">*</span></div>
                                <v-text-field class="input_login" v-model="telephone"
                                    :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']" @keypress="isNumberOnly($event)" type="tel"
                                    maxlength="10" onpaste="return false" label="เบอร์โทร" solo required></v-text-field>
                                <div class="text_before_input_login mb-2">รหัสผ่าน <span class="error--text">*</span></div>
                                <v-text-field class="input_login" v-model="password"
                                    :rules="[v => !!v || 'กรุณากรอกรหัสผ่าน']"
                                    :append-icon="show_password ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                    :type="show_password ? 'text' : 'password'" label="รหัสผ่าน"
                                    @click:append="show_password = !show_password" solo required></v-text-field>
                                <div class="d-flex align-center mb-5">
                                    <v-checkbox class="mt-0 pt-0" color="#2B3870" hide-details="auto"></v-checkbox>
                                    <div class="text_before_input_login">จำรหัสผ่าน</div>
                                </div>
                                <v-btn type="submit" class="white--text button_login" width="100%"
                                    color="#2B3870">เข้าสู่ระบบ</v-btn>
                            </v-form>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-dialog v-model="alert_error" persistent max-width="600">
            <v-card>
                <v-card-title>
                    คำเตือน !
                </v-card-title>

                <v-card-text>
                    เบอร์โทรและรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="white--text button_login" color="#2B3870" @click="confirmError()">
                        ตกลง
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import { isNumberOnly } from '@/helpers/numberformats.js'
import login from '@/api/user_profile/login.js'
import profile from '@/api/user_profile/profile.js'
export default {
    data() {
        return {
            loading_page: true,
            telephone: null,
            password: null,
            show_password: false,
            alert_error: false,
        }
    },
    methods: {
        async isNumberOnly(evt) {
            return isNumberOnly(evt)
        },
        async checkAccessToken() {
            this.loading_page = true
            if (this.$store.state.access_token) {
                this.$router.push('/contentknowledge')
            }
            this.loading_page = false
        },
        async login() {
            if (this.$refs.form.validate()) {
                this.loading_page = true
                const data = {
                    telephone: this.telephone,
                    password: this.password,
                }
                login.authLogin(data).then((response) => {
                    if (response.data.successful === true) {
                        const data_success = {
                            access_token: response.data.access_token
                        }
                        this.$store.commit('logInSuccess', data_success)
                        profile.profileDetail().then((response) => {
                            this.user_type = null
                            this.permission_id = null
                            if (response.data.successful === true) {
                                if (response.data.profileDetail.user_type === 1) {
                                    this.$router.push('/dashboard')
                                } else {
                                    if (response.data.profileDetail.permission_id === 1) {
                                        this.$router.push('/dashboard')
                                    }
                                    if (response.data.profileDetail.permission_id === 2) {
                                        this.$router.push('/ambulancequeuebookall')
                                    }
                                    if (response.data.profileDetail.permission_id === 3) {
                                        this.$router.push('/viewlistappointmentall')
                                    }
                                    if (response.data.profileDetail.permission_id === 4) {
                                        this.$router.push('/contentknowledge')
                                    }
                                }
                            }
                        }).catch((error) => {
                            if (error.response.status === 401) {
                                this.$store.commit('logOutSuccess')
                                this.$router.push('/login')
                            }
                        })
                    }
                    if (response.data.successful === false) {
                        this.loading_page = false
                        this.alert_error = true
                    }
                })
            }
        },
        async confirmError() {
            this.alert_error = false
            this.telephone = null
            this.password = null
        },
    },
    created() {
        this.checkAccessToken()
    },
}
</script>

<style scoped>
.login_page {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('~@/assets/images/smart_city_bg_login.png');
    background-size: cover;
}

.login {
    padding: 30px;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: auto;
}

.logo>img {
    max-width: 100%;
}

.welcome_login {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #414141;
}

.line_between_welcome {
    width: 70%;
    border: 1px solid #DADADA;
    border-radius: 10px;
}

.text_login {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #414141;
}

.text_before_input_login {
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #414141;
}

.input_login {
    border-radius: 10px;
}

.button_login {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
</style>