<template>
  <div>
    <Heading heading="แก้ไขสาระน่ารู้ใหม่" sub_heading="รายการสาระน่ารู้ทั้งหมด" sub_secondary_heading="แก้ไขสาระน่ารู้ใหม่"/>
    <EditContentKnowledgeList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import EditContentKnowledgeList from '@/components/hospital/contentknowledge/EditContentKnowledgeList'
export default {
  name: 'editContentKnowledgeList',
  components:{
    Heading,
    EditContentKnowledgeList
  }
}
</script>