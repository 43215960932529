<template>
  <div>
    <Heading heading="สาระน่ารู้" sub_heading="รายการสาระน่ารู้ทั้งหมด" button_link_url="addcontentknowledge" button_link_name="สาระน่ารู้" :button_link_redirect="true"/>
    <ContentKnowledgeList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import ContentKnowledgeList from '@/components/hospital/contentknowledge/ContentKnowledgeList'
export default {
  name: 'contentKnowledge',
  components:{
    Heading,
    ContentKnowledgeList
  }
}
</script>