<template>
    <div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_heading_page_detail_data d-flex align-center">
            <div class="card_text_heading_data">ฟอร์มทะเบียนผู้ดูแลระบบ</div>
        </div>
        <div class="card_data_list">
            <v-form ref="form" @submit.prevent="editAdmin()">
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                        <div class="image_preview text-center">
                            <v-avatar size="250">
                                <img v-if="imagePreviewURL" :src="imagePreviewURL">
                                <div class="upload_image">
                                    <v-btn v-if="!profile_picture" class="white--text" color="#FD8F16" depressed fab :loading="isSelectingImage" @click="handleFileImport"><v-icon>mdi-pencil</v-icon></v-btn>
                                    <v-btn v-if="profile_picture" class="white--text" color="error" depressed fab @click="onFileRemove"><v-icon>mdi-minus-thick</v-icon></v-btn>
                                    <v-file-input class="d-none" id="uploader" accept="image/*" v-model="profile_picture" @change="onFileChange"></v-file-input>
                                </div>
                            </v-avatar>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                        <div class="text_title">
                            <div class="image_title">
                                <img src="@/assets/images/user_fullname.jpg" alt="">
                            </div>
                            <div class="ml-2">ข้อมูลส่วนตัว</div>
                        </div>
                        <div class="detail_title_all layout_fullname">
                            <div class="detail_title">
                                <div>คำนำหน้า <span class="error--text">*</span></div>
                                <v-select class="select_detail_title" v-model="title_id" :rules="[v => !!v || 'กรุณาเลือกคำนำหน้า']" label="กรุณาเลือกคำนำหน้า" :items="title_list" item-text="title_name" item-value="id" solo required></v-select>
                            </div>
                            <div class="detail_title">
                                <div>ชื่อ <span class="error--text">*</span></div>
                                <v-text-field class="input_detail_title" v-model="first_name" :rules="[v => !!v || 'กรุณากรอกชื่อ']" label="กรุณากรอกชื่อ" solo required></v-text-field>
                            </div>
                            <div class="detail_title">
                                <div>นามสกุล <span class="error--text">*</span></div>
                                <v-text-field class="input_detail_title" v-model="last_name" :rules="[v => !!v || 'กรุณากรอกนามสกุล']" label="กรุณากรอกนามสกุล" solo required></v-text-field>
                            </div>
                        </div>
                        <div class="detail_title_all layout_contact">
                            <div class="detail_title">
                                <div>เบอร์โทร <span class="error--text">*</span></div>
                                <div class="d-flex otp_tel">
                                    <v-text-field class="input_detail_title" v-model="telephone[0]" type="tel" ref="tel_1" @focus="selectOPTInput" @keyup="focusOPTInput($event,'tel_1','tel_2',0)" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="telephone[1]" type="tel" ref="tel_2" @focus="selectOPTInput" @keyup="focusOPTInput($event,'tel_1','tel_3',1)" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="telephone[2]" type="tel" ref="tel_3" @focus="selectOPTInput" @keyup="focusOPTInput($event,'tel_2','tel_4',2)" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <div class="line_otp_tel">
                                        <v-icon>mdi-minus-thick</v-icon>
                                    </div>
                                    <v-text-field class="input_detail_title" v-model="telephone[3]" type="tel" ref="tel_4" @focus="selectOPTInput" @keyup="focusOPTInput($event,'tel_3','tel_5',3)" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="telephone[4]" type="tel" ref="tel_5" @focus="selectOPTInput" @keyup="focusOPTInput($event,'tel_4','tel_6',4)" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="telephone[5]" type="tel" ref="tel_6" @focus="selectOPTInput" @keyup="focusOPTInput($event,'tel_5','tel_7',5)" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="telephone[6]" type="tel" ref="tel_7" @focus="selectOPTInput" @keyup="focusOPTInput($event,'tel_6','tel_8',6)" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="telephone[7]" type="tel" ref="tel_8" @focus="selectOPTInput" @keyup="focusOPTInput($event,'tel_7','tel_9',7)" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="telephone[8]" type="tel" ref="tel_9" @focus="selectOPTInput" @keyup="focusOPTInput($event,'tel_8','tel_10',8)" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="telephone[9]" type="tel" ref="tel_10" @focus="selectOPTInput" @keyup="focusOPTInput($event,'tel_9','tel_10',9)" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                </div>
                            </div>
                            <div class="detail_title">
                                <div>E-mail <span class="error--text">*</span></div>
                                <v-text-field class="input_detail_title" v-model="email" type="email" :rules="[v => !!v || 'กรุณากรอก E-mail',rules.email]" label="กรุณากรอก E-mail" solo required></v-text-field>
                            </div>
                        </div>
                        <div class="text_title">
                            <div class="image_title">
                                <img src="@/assets/images/user_position.jpg" alt="">
                            </div>
                            <div class="ml-2">ตำแหน่ง</div>
                        </div>
                        <div class="detail_title_all layout_position">
                            <div class="detail_title">
                                <div>ตำแหน่ง <span class="error--text">*</span></div>
                                <v-text-field class="input_detail_title" v-model="position_name" :rules="[v => !!v || 'กรุณากรอกตำแหน่ง']" label="กรุณากรอกตำแหน่ง" solo required></v-text-field>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="text-center">
                            <v-btn class="white--text button_save" type="submit" height="43" width="15%" color="#37AE46">บันทึก</v-btn>
                        </div>
                        
                    </v-col>
                </v-row>
            </v-form>
        </div>
        <v-dialog v-model="open_edit_success" persistent max-width="600px">
            <v-card class="page_confirm_success_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                            <!-- <div class="image_confirm_success">
                                <img src="@/assets/images/trash_confirm_delete.jpg" alt="">
                            </div> -->
                            <div class="text-center">
                                <v-icon size="200" color="success">mdi-check-circle</v-icon>
                            </div>
                            <div class="text_confirm_success">สำเร็จแก้ไขผู้ดูแลระบบเรียบร้อยแล้ว</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_success">
                    <v-btn class="button_text_confirm_success" @click="$router.push('/manageadmins')" height="43" width="40%" color="#4EACDF">ตกลง</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script src="@/helpers/previewimage.js"></script>
<script>
import before_image from "@/assets/images/user_image_preview.png"
import { isNumberOnly } from '@/helpers/numberformats.js'
import { base64Decode } from '@/helpers/base64.js'
import title from '@/api/manage_users/title/title.js'
import office from '@/api/manage_users/office/office.js'
import admins from '@/api/manage_users/manage_admins/admins.js'
export default {
    data(){
        return{
            loading_page: true,
            alert_error: false,
            alert_message_error: '',
            open_edit_success: false,
            imagePreviewURL: before_image,
            oldImage: before_image,
            isSelectingImage: false,
            rules: {
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'E-mail ที่กรอกไม่ถูกต้อง'
                },
            },
            title_list: [],
            office_list: [],
            profile_picture: null,
            title_id: null,
            first_name: '',
            last_name: '',
            telephone: ['','','','','','','','','',''],
            email: '',
            position_name: '',
        }
    },
    methods:{
        base64Decode(id){
            return base64Decode(id)
        },
        async handleFileImport() {
            this.isSelectingImage = true
            window.addEventListener('focus', () => {
                this.isSelectingImage = false
            }, { once: true })
            document.getElementById('uploader').click()
        },
        async onFileChange(payload) {
            const file = payload
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
                // this.imagePreviewURL = before_image
                this.imagePreviewURL = this.oldImage
                this.profile_picture = null
                this.alert_message_error = 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG) เท่านั้น'
                this.alert_error = true
                setTimeout(() => this.alert_error = false, 5000)
            }else{
                if (file) {
                    this.imagePreviewURL = URL.createObjectURL(file)
                    URL.revokeObjectURL(file)
                } else {
                    // this.imagePreviewURL = before_image
                    this.imagePreviewURL = this.oldImage
                }
            }
        },
        async onFileRemove() {
            this.profile_picture = null
            // this.imagePreviewURL = before_image
            this.imagePreviewURL = this.oldImage
        },
        async isNumberOnly(evt){
            return isNumberOnly(evt)
        },
        async selectOPTInput(event) {
            event.target.select()
        },
        async focusOPTInput(event,first,last,arr_current) {
            let evt = (event) ? event : window.event
            let charCode = (evt.which) ? evt.which : evt.keyCode

            if(charCode == 9){
                return true
            }
            if (event.key >= 0 && event.key <= 9 && this.telephone[arr_current] != '') {
                this.$refs[last].focus()
            }
            if(charCode == 8){
                this.$refs[first].focus()
            }
        },
        async selectDataAllAndAdminDetail(){
            this.loading_page = true
            this.title_list = []
            await title.titleList().then((response) => {
                if (response.data.successful === true) {
                    this.title_list = response.data.titleList
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
            this.office_list = []
            await office.officeList().then((response) => {
                if (response.data.successful === true) {
                    this.office_list = response.data.officeList
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
            this.imagePreviewURL = before_image
            this.oldImage = before_image
            this.profile_picture = null
            this.title_id = null
            this.first_name = ''
            this.last_name = ''
            this.telephone = ['','','','','','','','','','']
            this.email = ''
            this.position_name = '' 
            await admins.adminDetail(this.base64Decode(this.$route.params.id)).then((response) => {
                if (response.data.successful === true) {
                    if (response.data.userDetail.picture) {
                        this.imagePreviewURL = response.data.userDetail.picture
                        this.oldImage = response.data.userDetail.picture
                    }
                    this.title_id = response.data.userDetail.title_id
                    this.first_name = response.data.userDetail.first_name 
                    this.last_name = response.data.userDetail.last_name 
                    this.telephone = response.data.userDetail.telephone.split('')
                    this.email = response.data.userDetail.email 
                    this.position_name = response.data.userDetail.position_name 
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async editAdmin(){
           if (this.$refs.form.validate()){
                let result_telephone = this.telephone.join('')
                if (result_telephone.length < 10) {
                    this.alert_message_error = 'กรุณากรอกเบอร์โทรให้ครบ 10 หลัก'
                    this.alert_error = true
                    setTimeout(() => this.alert_error = false, 5000)
                }
                if (result_telephone.length == 10) {
                    this.loading_page = true

                    let data = new FormData()
                    data.append('picture', this.profile_picture)
                    data.append('title_id', this.title_id)
                    data.append('first_name',this.first_name)
                    data.append('last_name', this.last_name)
                    data.append('telephone', result_telephone)
                    data.append('email', this.email)
                    data.append('position_name', this.position_name)
                    admins.editAdmin(this.base64Decode(this.$route.params.id),data).then((response) => {
                        if (response.data.successful === true) {
                            this.loading_page = false
                            this.open_edit_success = true
                        }
                        if (response.data.successful === false && response.data.message === 'Your phone number is the same as the user') {
                            this.loading_page = false
                            this.alert_message_error = 'เบอร์โทรนี้มีผู้ใช้งานแล้ว'
                            this.alert_error = true
                            setTimeout(() => this.alert_error = false, 5000)
                        }
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            this.$store.commit('logOutSuccess')
                            this.$router.push('/login')
                        }
                    })
                }
           }
        },
    },
    mounted(){
        this.selectDataAllAndAdminDetail()
    }
}
</script>

<style scoped>
.image_preview > .v-avatar{
    overflow:unset;
}

.upload_image{
    position: absolute;
    top: 0;
    right: 0;
}

.image_title{
    width: 50px;
}

.image_title > img{
    max-width: 100%;
}

.text_title{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: center;
}

.detail_title_all{
    display: flex;
    align-items: center;
    padding-left: 60px;
}

.layout_fullname > div:first-child{
    width: 20%;
}

.layout_fullname > div{
    width: 40%;
}

.detail_title{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #414141;
    margin-right: 20px;
}

.detail_title:last-child{
    margin-right: 0;
}

.detail_title > div{
    margin-bottom: 5px;
}

.select_detail_title{
    border-radius: 10px;
}

.input_detail_title{
    border-radius: 10px;
}

.layout_contact > div:first-child{
    width: 65%;
}

.layout_contact > div{
    width: 42%;
}

.otp_tel > div{
    margin-right:6px;
}

.otp_tel > div:last-child{
    margin-right:0;
}

.otp_tel >>> .v-text-field.v-text-field--solo .v-label,.otp_tel >>> .v-input__control input{
    text-align:center!important;
    width:100%!important;
}

.line_otp_tel{
    position: relative;
    top:13px;
}

.layout_position > div:first-child{
    width: 40%;
}

.layout_position > div{
    width: 60%;
}

.button_save{
    border: 1px solid #37AE46;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}

@media only screen and (max-width: 1000px) {
    .detail_title_all{
        display: block;
        margin-right: 0;
        padding-left: 0;
    }
    .layout_fullname > div:first-child{
        width: 100%;
    }
    .layout_fullname > div{
        width: 100%;
    }
    .layout_contact > div:first-child{
        width: 100%;
    }    
    .layout_contact > div{
        width: 100%;
    }
    .layout_position > div:first-child{
        width: 100%;
    }
    .layout_position > div{
        width: 100%;
    }
}

</style>