<template>
    <div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_header_create_notify">
            <div class="card_heading_page_detail_data d-flex align-center">
                <div class="card_text_heading_data">ข้อมูลการสร้างการแจ้งเตือน</div>
            </div>
            <div class="card_data_list">
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="5" xl="5">
                        <div class="layout_header_create_notify layout_w_left_header_create_notify d-flex">
                            <div>วันที่สร้างการแจ้งเตือน</div>
                            <div>{{ header_data.created_at }}</div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="7" xl="7">
                        <div class="layout_header_create_notify layout_w_right_header_create_notify d-flex">
                            <div>ผู้สร้างการแจ้งเตือน</div>
                            <div>{{ header_data.created_user }}</div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="5" xl="5">
                        <div class="layout_header_create_notify layout_w_left_header_create_notify d-flex">
                            <div>จำนวนผู้ป่วยที่นัดหมาย</div>
                            <div>{{ header_data.patient_count }}</div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="7" xl="7">
                        <div class="layout_header_create_notify layout_w_right_header_create_notify d-flex">
                            <div>สถานะการส่งการแจ้งเตือน</div>
                            <div>{{ header_data.status == 1 ? 'ยกเลิก' : header_data.status == 2 ?
                                'ส่งการแจ้งเตือนเรียบร้อย' : 'รอยืนยันการแจ้งเตือน' }}</div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div>
            <div class="card_heading_data d-flex justify-space-between align-center">
                <div class="card_text_heading_data">รายการแจ้งเตือน</div>
                <div class="card_search_heading_data">
                    <v-text-field class="input_search" v-model="search" @keyup="searchData()" label="ค้นหา"
                        prepend-inner-icon="mdi-magnify" hide-details="auto" solo required></v-text-field>
                </div>
            </div>
            <div class="card_data_list">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="title_table_data_list" style="width:5%;">
                                #
                            </th>
                            <th class="title_table_data_list" style="width:20%;">
                                ชื่อ - สกุล
                            </th>
                            <th class="title_table_data_list" style="width:10%;">
                                วันนัดหมาย
                            </th>
                            <th class="title_table_data_list" style="width:10%;">
                                เวลา
                            </th>
                            <th class="title_table_data_list" style="width:20%;">
                                แพทย์ผู้นัด
                            </th>
                            <th class="title_table_data_list" style="width:20%;">
                                หมายเหตุ
                            </th>
                            <th class="title_table_data_list" style="width:15%;">
                                จัดการ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(detail, index) in data" :key="index">
                            <td class="detail_table_data_list">{{ detail.count_row }}</td>
                            <td class="detail_table_data_list">{{ detail.name }}</td>
                            <td class="detail_table_data_list">{{ detail.appointment_date_format }}</td>
                            <td class="detail_table_data_list">
                                {{ detail.meeting_time ?
                                    `${detail.meeting_time.split(':')[0]}:${detail.meeting_time.split(':')[1]}` : '-' }}
                            </td>
                            <td class="detail_table_data_list">{{ detail.medic }}</td>
                            <td class="detail_table_data_list">{{ detail.note }}</td>
                            <td class="detail_table_data_list">
                                <v-btn v-if="header_data.status == 0" class="button_tool_data_list my-2 mr-2"
                                    @click="openUserDetail(base64Encode(detail.queue_id))" height="43"
                                    color="#FD8F16"><v-icon>mdi-pencil</v-icon>
                                    <div>แก้ไข</div>
                                </v-btn>
                                <div v-if="header_data.status != 0">-</div>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-pagination class="pagination_data_list" v-model="page" color="#4EACDF" :length="pages"></v-pagination>
            </div>
            <div class="d-flex justify-center" v-if="header_data.status == 0">
                <v-btn class="my-2 mr-2 white--text button_status_confirm_notify" type="button"
                    @click="open_confirm_notification_all = true" height="43" color="#37AE46" depressed>
                    <v-icon>mdi-check-circle-outline</v-icon>
                    <div class="ml-1">ยืนยันการส่งการแจ้งเตือน</div>
                </v-btn>
                <v-btn class="my-2 mr-2 white--text button_status_confirm_notify" type="button"
                    @click="open_confirm_cancel_notification_all = true" height="43" color="#E60023" depressed>
                    <v-icon>mdi-close-circle-outline</v-icon>
                    <div>ยกเลิกการแจ้งเตือนทั้งหมด</div>
                </v-btn>
            </div>
        </div>
        <v-dialog v-model="open_detail" persistent max-width="1000px">
            <div v-if="loading_detail_page === true">
                <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-sheet>
            </div>
            <v-card class="page_detail_all" v-if="loading_detail_page === false">
                <v-card-title class="justify-space-between">
                    <div class="d-flex align-center">
                        <div class="image_title_open_detail">
                            <img src="@/assets/images/user_fullname.jpg" alt="">
                        </div>
                        <div class="title_open_detail ml-2">แก้ไขการนัดหมาย</div>
                    </div>
                    <div>
                        <v-btn icon @click="open_detail = false">
                            <v-icon color="#000000" size="30">mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-card-title>
                <v-form ref="form" @submit.prevent="editAppointmentPeople()">
                    <v-card-text class="detail_all">
                        <div class="title_detail_all mb-3">ข้อมูลผู้ป่วย</div>
                        <div class="detail_title_all layout_data_patient">
                            <div class="detail_title">
                                <div>เลขผู้ป่วย <span class="error--text">*</span></div>
                                <div class="d-flex otp_hn">
                                    <v-text-field class="input_detail_title" v-model="user_detail.hn[0]" type="tel"
                                        ref="tel_1" @focus="selectOPTInput"
                                        @keyup="focusOPTInput($event, 'tel_1', 'tel_2', 0)" @keypress="isNumberOnly($event)"
                                        onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="user_detail.hn[1]" type="tel"
                                        ref="tel_2" @focus="selectOPTInput"
                                        @keyup="focusOPTInput($event, 'tel_1', 'tel_3', 1)" @keypress="isNumberOnly($event)"
                                        onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="user_detail.hn[2]" type="tel"
                                        ref="tel_3" @focus="selectOPTInput"
                                        @keyup="focusOPTInput($event, 'tel_2', 'tel_4', 2)" @keypress="isNumberOnly($event)"
                                        onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="user_detail.hn[3]" type="tel"
                                        ref="tel_4" @focus="selectOPTInput"
                                        @keyup="focusOPTInput($event, 'tel_3', 'tel_5', 3)" @keypress="isNumberOnly($event)"
                                        onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="user_detail.hn[4]" type="tel"
                                        ref="tel_5" @focus="selectOPTInput"
                                        @keyup="focusOPTInput($event, 'tel_4', 'tel_6', 4)" @keypress="isNumberOnly($event)"
                                        onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="user_detail.hn[5]" type="tel"
                                        ref="tel_6" @focus="selectOPTInput"
                                        @keyup="focusOPTInput($event, 'tel_5', 'tel_7', 5)" @keypress="isNumberOnly($event)"
                                        onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="user_detail.hn[6]" type="tel"
                                        ref="tel_7" @focus="selectOPTInput"
                                        @keyup="focusOPTInput($event, 'tel_6', 'tel_8', 6)" @keypress="isNumberOnly($event)"
                                        onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="user_detail.hn[7]" type="tel"
                                        ref="tel_8" @focus="selectOPTInput"
                                        @keyup="focusOPTInput($event, 'tel_7', 'tel_9', 7)" @keypress="isNumberOnly($event)"
                                        onpaste="return false" maxlength="1" label="X" solo required></v-text-field>
                                    <v-text-field class="input_detail_title" v-model="user_detail.hn[8]" type="tel"
                                        ref="tel_9" @focus="selectOPTInput"
                                        @keyup="focusOPTInput($event, 'tel_8', 'tel_10', 8)"
                                        @keypress="isNumberOnly($event)" onpaste="return false" maxlength="1" label="X" solo
                                        required></v-text-field>
                                </div>
                            </div>
                            <div class="detail_title">
                                <div class="d-flex align-center mb-4">
                                    <div class="mr-2">ชื่อ-สกุล</div>
                                    <div class="name_tel_data_patient">{{ user_detail.name ? user_detail.name : '-' }}</div>
                                </div>
                                <div class="d-flex align-center">
                                    <div class="mr-2">เบอร์โทร</div>
                                    <div class="name_tel_data_patient">{{ user_detail.tel ? user_detail.tel : '-' }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="title_detail_all mb-3">ข้อมูลการนัดหมาย</div>
                        <div class="detail_title_all layout_data_appointment">
                            <div class="detail_title">
                                <div>วันที่ <span class="error--text">*</span></div>
                                <v-menu ref="menu_appointment_date" v-model="user_detail.menu_appointment_date"
                                    :close-on-content-click="false" :return-value.sync="user_detail.appointment_date"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="input_detail_title" v-model="user_detail.appointment_date_be"
                                            :rules="[v => !!v || 'กรุณาเลือกวันที่']" label="กรุณาเลือกวันที่"
                                            v-bind="attrs" v-on="on" solo required></v-text-field>
                                    </template>
                                    <v-date-picker v-model="user_detail.appointment_date" no-title scrollable locale="th"
                                        :events="appointmentDateBE()">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="selectAppointmentDate()">
                                            ตกลง
                                        </v-btn>
                                        <v-btn text color="primary" @click="user_detail.menu_appointment_date = false">
                                            ยกเลิก
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </div>
                            <div class="detail_title">
                                <div>เวลา <span class="error--text">*</span></div>
                                <v-select class="select_detail_title" v-model="user_detail.meeting_time"
                                    :rules="[v => !!v || 'กรุณาเลือกเวลา']" label="กรุณาเลือกเวลา" :items="time_list"
                                    item-text="time" item-value="time" solo required></v-select>

                            </div>
                            <div class="detail_title">
                                <div>คลินิก <span class="error--text">*</span></div>
                                <v-select class="select_detail_title" v-model="user_detail.clinic_name"
                                    :rules="[v => !!v || 'กรุณาเลือกคลินิก']" label="กรุณาเลือกคลินิก" :items="clinic_list"
                                    item-text="name" item-value="name" solo required></v-select>
                            </div>
                        </div>
                        <div class="detail_title_all layout_two_data_appointment">
                            <div class="detail_title">
                                <div>แพทย์ผู้นัดหมาย <span class="error--text">*</span></div>
                                <v-text-field class="input_detail_title" v-model="user_detail.medic"
                                    :rules="[v => !!v || 'กรุณากรอกแพทย์ผู้นัดหมาย']" label="กรุณากรอกแพทย์ผู้นัดหมาย" solo
                                    required></v-text-field>
                            </div>
                            <div class="detail_title">
                                <div>ห้องตรวจ</div>
                                <v-text-field class="input_detail_title" v-model="user_detail.room"
                                    label="กรุณากรอกห้องตรวจ" solo></v-text-field>
                            </div>
                        </div>
                        <div class="detail_title_all layout_three_data_appointment">
                            <div class="detail_title">
                                <div>หมายเหตุ</div>
                                <v-text-field class="input_detail_title" v-model="user_detail.note"
                                    label="กรุณากรอกหมายเหตุ" solo></v-text-field>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn class="white--text button_edit_appointment" type="submit" height="43" width="50%"
                            color="#37AE46">บันทึกการแก้ไข</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="open_confirm_notification_all" persistent max-width="600px">
            <v-card class="page_confirm_delete_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-icon size="200" color="success">mdi-help-circle</v-icon>
                            </div>
                            <div class="text_confirm_delete">คุณแน่ใจใช่ไหมที่จะยืนยันการส่งการแจ้งเตือน?</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_delete">
                    <v-btn class="button_text_confirm_delete" @click="notificationAll(1)" height="43" width="40%"
                        color="#4EACDF">ใช่ ฉันต้องการ !</v-btn>
                    <v-btn class="button_text_confirm_delete" @click="notificationAll(0)" height="43" width="40%"
                        color="#414141">ยกเลิก</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="open_notification_all_success" persistent max-width="600px">
            <v-card class="page_confirm_success_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-icon size="200" color="success">mdi-check-circle</v-icon>
                            </div>
                            <div class="text_confirm_success">สำเร็จยืนยันการส่งการแจ้งเตือนเรียบร้อยแล้ว</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_success">
                    <v-btn class="button_text_confirm_success" @click="$router.push('/viewlistappointmentall')" height="43"
                        width="40%" color="#4EACDF">ตกลง</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="open_confirm_cancel_notification_all" persistent max-width="600px">
            <v-card class="page_confirm_delete_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-icon size="200" color="error">mdi-help-circle</v-icon>
                            </div>
                            <div class="text_confirm_delete">คุณแน่ใจใช่ไหมที่จะยกเลิกการแจ้งเตือนทั้งหมด?</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_delete">
                    <v-btn class="button_text_confirm_delete" @click="cancelNotificationAll(1)" height="43" width="40%"
                        color="#4EACDF">ใช่ ฉันต้องการ !</v-btn>
                    <v-btn class="button_text_confirm_delete" @click="cancelNotificationAll(0)" height="43" width="40%"
                        color="#414141">ยกเลิก</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="open_cancel_notification_all_success" persistent max-width="600px">
            <v-card class="page_confirm_success_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-icon size="200" color="success">mdi-check-circle</v-icon>
                            </div>
                            <div class="text_confirm_success">สำเร็จยกเลิกการแจ้งเตือนทั้งหมดเรียบร้อยแล้ว</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_success">
                    <v-btn class="button_text_confirm_success" @click="$router.push('/viewlistappointmentall')" height="43"
                        width="40%" color="#4EACDF">ตกลง</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { base64Encode, base64Decode } from '@/helpers/base64.js'
import { isNumberOnly } from '@/helpers/numberformats.js'
import { monthList } from '@/helpers/dateformats.js'
import appointmentall from '@/api/hospital/appointment/viewlistappointmentall/appointmentall.js'
export default {
    inject: {
        theme: {
            default: {
                isDark: false
            },
        },
    },
    data() {
        return {
            loading_page: true,
            loading_detail_page: true,
            alert_success: false,
            alert_message_success: '',
            open_detail: false,
            open_confirm_notification_all: false,
            open_notification_all_success: false,
            open_confirm_cancel_notification_all: false,
            open_cancel_notification_all_success: false,
            header_data: {
                id: null,
                created_at: null,
                created_user: null,
                patient_count: null,
                status: null,
            },
            search: '',
            data: [],
            page: 1,
            size: 15,
            pages: 1,
            time_list: [
                { id: 1, time: '8:30' },
                { id: 2, time: '9:00' },
                { id: 3, time: '9:30' },
                { id: 4, time: '10:00' },
                { id: 5, time: '10:30' },
                { id: 6, time: '11:00' },
                { id: 7, time: '11:30' },
                { id: 8, time: '13:00' },
                { id: 9, time: '13:30' },
                { id: 10, time: '14:00' },
                { id: 11, time: '14:30' },
                { id: 12, time: '15:00' },
                { id: 13, time: '15:30' },
                { id: 14, time: '16:00' },
            ],
            clinic_list: [
                { id: 1, name: 'ตรวจโรคทั่วไป' },
                { id: 2, name: 'COPD' },
                { id: 3, name: 'คลินิกความดัน' },
                { id: 4, name: 'คลินิกโรคเบาหวาน' },
                { id: 5, name: 'จิตเวช' },
                { id: 6, name: 'ถุงลมโป่งพอง' },
                { id: 7, name: 'ทันตกรรม' },
                { id: 8, name: 'ฝากครรภ์' },
                { id: 9, name: 'โรคซึมเศร้า' },
                { id: 10, name: 'โรคไต' },
                { id: 11, name: 'โรคหลอดเลือดสมอง' },
                { id: 12, name: 'โรคหัวใจล้มเหลว' },
                { id: 13, name: 'วางแผนครอบครัว' },
                { id: 14, name: 'สุขภาพเด็กดี' },
                { id: 15, name: 'หัวใจขาดเลือด' },
            ],
            user_detail_id: null,
            user_detail: {
                hn: ['', '', '', '', '', '', '', '', ''],
                name: null,
                tel: null,
                menu_appointment_date: false,
                appointment_date_be: '',
                appointment_date: '',
                meeting_time: null,
                clinic_name: null,
                medic: '',
                room: '',
                note: '',
                coming_date: null,
            },
        }
    },
    methods: {
        base64Encode(id) {
            return base64Encode(id)
        },
        base64Decode(id) {
            return base64Decode(id)
        },
        async isNumberOnly(evt) {
            return isNumberOnly(evt)
        },
        async selectOPTInput(event) {
            event.target.select()
        },
        async focusOPTInput(event, first, last, arr_current) {
            let evt = (event) ? event : window.event
            let charCode = (evt.which) ? evt.which : evt.keyCode

            if (charCode == 9) {
                return true
            }
            if (event.key >= 0 && event.key <= 9 && this.telephone[arr_current] != '') {
                this.$refs[last].focus()
            }
            if (charCode == 8) {
                this.$refs[first].focus()
            }
        },
        appointmentDateBE() {
            if (this.user_detail.appointment_date == '') {
                this.user_detail.appointment_date_be = ''
            }
            if (this.user_detail.appointment_date) {
                let date_arr = this.user_detail.appointment_date.split('-')
                let year = parseInt(date_arr[0]) + 543
                let month = parseInt(date_arr[1])
                month = monthList().find((detail) => { return detail.id == month }).name_short
                this.user_detail.appointment_date_be = `${date_arr[2]} ${month} ${year}`
            }
        },
        async selectAppointmentDate() {
            this.$refs.menu_appointment_date.save(this.user_detail.appointment_date)
            let date_arr = this.user_detail.appointment_date.split('-')
            let year = parseInt(date_arr[0]) + 543
            let month = parseInt(date_arr[1])
            month = monthList().find((detail) => { return detail.id == month }).name_short
            this.user_detail.appointment_date_be = `${date_arr[2]}/${month}/${year}`
            this.user_detail.menu_appointment_date = false
        },
        async appointmentDetail() {
            this.loading_page = true
            this.header_data = {
                id: null,
                created_at: null,
                created_user: null,
                patient_count: null,
                status: null,
            }
            this.data = []
            appointmentall.appointmentDetail(this.base64Decode(this.$route.params.id), this.page, this.size, this.search).then((response) => {
                if (response.data.successful === true) {
                    this.header_data.id = response.data.detail.id
                    this.header_data.created_at = response.data.detail.create_at
                    this.header_data.created_user = `${response.data.detail.first_name} ${response.data.detail.last_name}`
                    this.header_data.patient_count = response.data.detail.patient_count
                    this.header_data.status = response.data.detail.status

                    this.data = response.data.queue_List_dtail
                    this.pages = response.data.pages
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async openUserDetail(id) {
            this.loading_detail_page = true
            this.open_detail = true
            this.user_detail = {
                hn: ['', '', '', '', '', '', '', '', ''],
                name: null,
                tel: null,
                menu_appointment_date: false,
                appointment_date_be: '',
                appointment_date: '',
                meeting_time: null,
                clinic_name: null,
                medic: '',
                room: '',
                note: '',
                coming_date: null,
            }
            this.user_detail_id = id
            const queue_id = base64Decode(this.user_detail_id)

            appointmentall.appointmentPeopleDetail(queue_id).then((response) => {
                if (response.data.successful === true) {
                    console.log(response.data.list_detail_edit.meeting_time);
                    this.user_detail.hn = response.data.list_detail_edit.hn.split('')
                    this.user_detail.name = response.data.list_detail_edit.name
                    this.user_detail.tel = response.data.list_detail_edit.tel
                    this.user_detail.appointment_date = response.data.list_detail_edit.appointment_date

                    if (response.data.list_detail_edit.meeting_time != null) {
                        let meetingTime = response.data.list_detail_edit.meeting_time.split(':')
                        if (meetingTime.length >= 3) {
                            this.user_detail.meeting_time = `${meetingTime[0]}:${meetingTime[1]}`
                        }else{
                            this.user_detail.meeting_time = response.data.list_detail_edit.meeting_time
                        }
                    } else {
                        this.user_detail.meeting_time = response.data.list_detail_edit.meeting_time
                    }

                    this.user_detail.clinic_name = response.data.list_detail_edit.clinic_name
                    this.user_detail.medic = response.data.list_detail_edit.medic
                    this.user_detail.room = response.data.list_detail_edit.room
                    this.user_detail.note = response.data.list_detail_edit.note
                    this.user_detail.coming_date = response.data.list_detail_edit.coming_date
                    this.loading_detail_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async editAppointmentPeople() {
            if (this.$refs.form.validate()) {
                let result_hn = this.user_detail.hn.join('')
                if (result_hn.length < 9) {
                    this.alert_message_error = 'กรุณากรอกเลขผู้ป่วยให้ครบ 9 หลัก'
                    this.alert_error = true
                    setTimeout(() => this.alert_error = false, 5000)
                }
                if (result_hn.length == 9) {
                    this.user_detail.hn = result_hn
                    this.loading_detail_page = true
                    appointmentall.editAppointmentPeople(base64Decode(this.user_detail_id), this.user_detail).then((response) => {
                        if (response.data.successful === true) {
                            this.user_detail = {
                                hn: ['', '', '', '', '', '', '', '', ''],
                                name: null,
                                tel: null,
                                menu_appointment_date: false,
                                appointment_date_be: '',
                                appointment_date: '',
                                meeting_time: null,
                                clinic_name: null,
                                medic: '',
                                room: '',
                                note: '',
                            }
                            this.user_detail_id = null
                            this.open_detail = false
                            this.loading_page = true
                            this.appointmentDetail()
                            this.alert_message_success = 'แก้ไขการนัดหมายเรียบร้อยแล้ว'
                            this.alert_success = true
                            setTimeout(() => this.alert_success = false, 5000)
                        }
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            this.$store.commit('logOutSuccess')
                            this.$router.push('/login')
                        }
                    })
                }
            }
        },
        async notificationAll(type) {
            this.open_confirm_notification_all = false
            if (type == 1) {
                this.open_confirm_cancel_notification_all = false
                this.loading_page = true
                appointmentall.sendNotification(this.header_data.id).then((response) => {
                    if (response.data.successful === true) {
                        this.loading_page = false
                        this.open_confirm_notification_all = false
                        this.open_notification_all_success = true
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        },
        async cancelNotificationAll(type) {
            this.open_confirm_cancel_notification_all = false
            if (type == 1) {
                this.loading_page = true
                appointmentall.deleteAppointment(this.header_data.id).then((response) => {
                    if (response.data.successful === true) {
                        this.loading_page = false
                        this.open_confirm_cancel_notification_all = false
                        this.open_cancel_notification_all_success = true
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        },
        async searchData() {
            this.data = []
            this.page = 1
            appointmentall.appointmentDetail(this.base64Decode(this.$route.params.id), this.page, this.size, this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.queue_List_dtail
                    this.pages = response.data.pages
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        }
    },
    mounted() {
        this.appointmentDetail()
    },
    watch: {
        page() {
            this.appointmentDetail()
        }
    }
}
</script>

<style scoped>
.card_header_create_notify {
    margin-bottom: 20px;
}

.layout_header_create_notify>div:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #585B6B;
}

.layout_header_create_notify>div:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #414141;
}

.layout_w_left_header_create_notify>div:first-child {
    width: 40%;
}

.layout_w_left_header_create_notify>div:last-child {
    width: 60%;
}

.layout_w_right_header_create_notify>div:first-child {
    width: 30%;
}

.layout_w_right_header_create_notify>div:last-child {
    width: 70%;
}

.button_status_confirm_notify {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding: 0 12px !important;
    border-radius: 10px;
}

.page_detail_all {
    padding-bottom: 15px;
    border-radius: 10px !important;
}

.image_title_open_detail {
    width: 50px;
}

.image_title_open_detail>img {
    max-width: 100%;
}

.title_open_detail {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #414141;
}

.detail_all {
    padding: 0 70px 20px !important;
}

.title_detail_all {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
}

.detail_title_all {
    display: flex;
    align-items: center;
}

.layout_data_patient>div:first-child {
    width: 60%;
}

.layout_data_patient>div {
    width: 40%;
}

.detail_title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #414141;
    margin-right: 20px;
}

.detail_title:last-child {
    margin-right: 0;
}

.detail_title>div {
    margin-bottom: 5px;
}

.select_detail_title {
    border-radius: 10px;
}

.input_detail_title {
    border-radius: 10px;
}

.otp_hn>div {
    margin-right: 6px;
}

.otp_hn>div:last-child {
    margin-right: 0;
}

.otp_hn>>>.v-text-field.v-text-field--solo .v-label,
.otp_hn>>>.v-input__control input {
    text-align: center !important;
    width: 100% !important;
}

.name_tel_data_patient {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #414141;
}

.layout_data_appointment>div:first-child {
    width: 40%;
}

.layout_data_appointment>div {
    width: 20%;
}

.layout_data_appointment>div:last-child {
    width: 40%;
}

.layout_two_data_appointment>div:first-child {
    width: 40%;
}

.layout_two_data_appointment>div {
    width: 60%;
}

.layout_three_data_appointment>div {
    width: 60%;
}

.button_edit_appointment {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
}


@media only screen and (max-width: 1265px) {
    .layout_w_left_header_create_notify>div:first-child {
        width: 50%;
    }

    .layout_w_left_header_create_notify>div:last-child {
        width: 50%;
    }

    .layout_w_right_header_create_notify>div:first-child {
        width: 50%;
    }

    .layout_w_right_header_create_notify>div:last-child {
        width: 50%;
    }
}

@media only screen and (max-width: 1000px) {
    .detail_title_all {
        display: block;
    }

    .layout_data_patient>div:first-child {
        width: 100%;
    }

    .layout_data_patient>div {
        width: 100%;
    }

    .layout_data_appointment>div:first-child {
        width: 100%;
    }

    .layout_data_appointment>div {
        width: 100%;
    }

    .layout_data_appointment>div:last-child {
        width: 100%;
    }

    .layout_two_data_appointment>div:first-child {
        width: 100%;
    }

    .layout_two_data_appointment>div {
        width: 100%;
    }

    .layout_three_data_appointment>div {
        width: 100%;
    }
}


@media only screen and (max-width: 550px) {
    .layout_w_left_header_create_notify>div:first-child {
        width: auto;
        margin-right: 10px;
    }

    .layout_w_left_header_create_notify>div:last-child {
        width: auto;
    }

    .layout_w_right_header_create_notify>div:first-child {
        width: auto;
        margin-right: 10px;
    }

    .layout_w_right_header_create_notify>div:last-child {
        width: auto;
    }
}
</style>