<template>
    <div class="pa-6">
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <div class="title_heading mb-5">ลงทะเบียนเปิดรับการแจ้งเตือน</div>
        <div>
            <v-card class="card_line_oa card_all_register_open_notify">
                <v-form ref="form" @submit.prevent="registerOpenNotify()">
                    <div class="card_detail_register_open_notify">
                        <div class="mb-2">รหัสผู้ป่วย HN <span class="error--text">*</span></div>
                        <v-text-field class="input_line_oa" v-model="register.hn" type="tel" @keypress="isNumberOnly($event)" onpaste="return false" maxlength="9" :rules="[v => !!v || 'กรุณากรอกรหัสประจำตัวผู้ป่วย']" placeholder="กรุณากรอกรหัสประจำตัวผู้ป่วย" outlined required></v-text-field>
                    </div>
                    <div class="card_detail_register_open_notify">
                        <div class="mb-2">วันเกิด <span class="error--text">*</span></div>
                        <v-menu ref="menu_birthday" v-model="register.menu_birthday" :close-on-content-click="false" :return-value.sync="register.birthday" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field class="input_line_oa" v-model="register.birthday_be" :rules="[v => !!v || 'กรุณาเลือกวันเดือนปีเกิด']" placeholder="กรุณาเลือกวันเดือนปีเกิด" append-icon="mdi-calendar-month-outline" readonly v-bind="attrs" v-on="on" outlined required></v-text-field>
                            </template>
                            <v-date-picker v-model="register.birthday" no-title scrollable locale="th" :events="birthdayBE()">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="selectBirthday()">
                                    ตกลง
                                </v-btn>
                                <v-btn text color="primary" @click="register.menu_birthday = false">
                                    ยกเลิก
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </div>
                    <v-btn class="white--text button_register_open_notify" type="submit" height="43" width="100%">ลงทะเบียนรับการแจ้งเตือน</v-btn>
                </v-form>
            </v-card>
        </div>
        <v-dialog v-model="open_add_error" persistent max-width="600px">
            <v-card class="page_error_all">
                <v-card-title class="justify-end">
                    <div>
                        <v-btn icon @click="open_add_error = false">
                            <v-icon color="#000000" size="30">mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <div class="title_open_error">ขออภัยข้อมูลไม่ถูกต้อง</div>
                                <v-icon size="200" class="my-3" color="error">mdi-close-circle</v-icon>
                                <div class="text_detail_error mb-2">ข้อมูลที่ท่านกรอกไม่มีในระบบ กรุณาทำรายการใหม่อีกครั้ง</div>
                                <div class="text_detail_error">หรือติดต่อเจ้าหน้าที่โรงพยาบาล</div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import liff from '@line/liff'
import { isNumberOnly } from '@/helpers/numberformats.js'
import { monthList } from '@/helpers/dateformats.js'
import notify from '@/api/line_oa/notify/notify.js'
export default {
    data(){
        return{
            loading_page: false,
            open_add_error: false,
            register:{
                token_line: null,
                hn: '',
                menu_birthday: false,
                birthday_be: '',
                birthday: '',
            }
        }
    },
    methods:{
        async isNumberOnly(evt){
            return isNumberOnly(evt)
        },
        birthdayBE(){
            if (this.register.birthday == '') {
                this.register.birthday_be = ''
            }
            if (this.register.birthday) {
                let date_arr = this.register.birthday.split('-')
                let year = parseInt(date_arr[0]) + 543
                let month = parseInt(date_arr[1])
                month = monthList().find((detail) => { return detail.id == month }).name_short
                this.register.birthday_be = `${date_arr[2]} ${month} ${year}`
            }
        },
        async selectBirthday(){
            this.$refs.menu_birthday.save(this.register.birthday)
            let date_arr = this.register.birthday.split('-')
            let year = parseInt(date_arr[0]) + 543
            let month = parseInt(date_arr[1])
            month = monthList().find((detail) => { return detail.id == month }).name_short
            this.register.birthday_be = `${date_arr[2]}/${month}/${year}`
            this.register.menu_birthday = false
        },
        async getDetailLineUser(){
            this.loading_page = true
            this.register = {
                token_line: null,
                hn: '',
                menu_birthday: false,
                birthday_be: '',
                birthday: '',
            }
            liff.init({
                liffId: process.env.VUE_APP_BASE_LINE_LIFFID,
            }).then(() =>{
                if (liff.isLoggedIn()) {
                    liff.getProfile().then(profile =>{
                        this.register.token_line = profile.userId
                        this.loading_page = false
                    })
                }else{
                    liff.login()
                }
            })
        },
        async registerOpenNotify(){
            if (this.$refs.form.validate()){
                this.loading_page = true
                notify.registerOpenNotify(this.register).then((response) => {
                    if (response.data.successful === true) {
                        this.$router.push('/lineoa/userinformation')
                    }
                    if (response.data.successful === false) {
                        this.loading_page = false
                        this.open_add_error = true
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$store.commit('logOutSuccess')
                        this.$router.push('/login')
                    }
                })
            }
        },
    },
    mounted(){
        this.getDetailLineUser()
    }
}
</script>

<style scoped>
.title_heading{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #000000;
}

.card_all_register_open_notify{
    padding: 30px 15px;
}

.card_detail_register_open_notify{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #4D4D4D;
}

.button_register_open_notify{
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    background: linear-gradient(90deg, #151B54 0%, #4495C1 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px!important;
}

.page_error_all{
  padding-bottom: 15px;
  border-radius: 10px!important;
}

.image_title_open_error{
    width: 50px;
}

.image_title_open_error > img{
    max-width: 100%;
}

.title_open_error{
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    color: #414141;
}

.text_detail_error{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #414141;
}
</style>