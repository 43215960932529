import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    appointmentList: (page = '', size = '', search = '') => {
        return HTTP.get(`/queue/list_Queue?page=${page}&size=${size}&search=${search}` ,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    sendNotification: (id) => {
        return HTTP.get('/people/send/notofication/' + id, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    searchCodeHN: (data) => {
        return HTTP.post('/queue/code_hn', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },    
    addAppointment: (data) => {
        return HTTP.post('/queue/add_Queue', data ,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    importFileAppointment: (data) => {
        return HTTP.post('/queue/add_excel_Queue', data ,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    appointmentDetail: (id, page = '', size = '', search = '') => {
        return HTTP.get(`/queue/list_Queue_detail/${id}?page=${page}&size=${size}&search=${search}` , {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    appointmentDetailForEdit: (id) => {
        return HTTP.get(`/queue/list_detail_edit_poinment/${id}` , {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    editAppointment: (data) => {
        return HTTP.post('/queue/edit_Queue', data ,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    deleteAppointment: (id) => {
        return HTTP.get('/queue/Cancel_queue/'+ id ,{
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    appointmentPeopleDetail: (id) => {
        return HTTP.get(`/queue/list_Queue_detail_edit/${id}` , {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    editAppointmentPeople: (id, data) => {
        return HTTP.post(`/queue/list_Queue_edit_save/${id}` , data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
}