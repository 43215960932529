<template>
    <v-app>
      <Header/>
      <AmbulancequeueLise/>
    </v-app>
  </template>
  
  <script>
  import Header from '@/components/line_oa/menu/Header'
  import AmbulancequeueLise from '@/components/line_oa/ambulancequeue/Ambulancequeue'
  export default {
    name: 'lineoaAmbulancequeue',
    components:{
      Header,
      AmbulancequeueLise
    }
  }
  </script>