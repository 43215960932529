import AppointmentAll from '@/views/hospital/appointment/AppointmentAll.vue'
import ViewListAppointmentAll from '@/views/hospital/appointment/viewlistappointment/ViewListAppointmentAll.vue'
import AddAppointment from '@/views/hospital/appointment/viewlistappointment/AddAppointment.vue'
import AddAppointmentExcel from '@/views/hospital/appointment/viewlistappointment/AddAppointmentExcel.vue'
import DetailAppointment from '@/views/hospital/appointment/viewlistappointment/DetailAppointment.vue'
import EditAppointment from '@/views/hospital/appointment/viewlistappointment/EditAppointment.vue'

import ContentKnowledge from '@/views/hospital/contentknowledge/ContentKnowledge.vue'
import AddContentKnowledge from '@/views/hospital/contentknowledge/AddContentKnowledge.vue'
import EditContentKnowledge from '@/views/hospital/contentknowledge/EditContentKnowledge.vue'
import DetailContentKnowledge from '@/views/hospital/contentknowledge/DetailContentKnowledge.vue'
import SettingHospital from '@/views/hospital/settinghospital/SettingHospital.vue'
import Ambulancequeueall from '@/views/hospital/ambulancequeue/Ambulancequeueall.vue'
import Ambulancecheckqueue from '@/views/hospital/ambulancequeue/Ambulancecheckqueue.vue'

import Destinationall from '@/views/hospital/destination/Destinationall.vue'

import Ambulancequeuebook from '@/views/hospital/ambulancequeue/Ambulancequeuebook.vue'
import Ambulancequeuebookadd from '@/views/hospital/ambulancequeue/Ambulancequeuebookadd.vue'
import Ambulancequeuebookedit from '@/views/hospital/ambulancequeue/Ambulancequeuebookedit.vue'


export default [
    // คิวนัดหมาย
    {
        path: '/appointmentall',
        name: 'appointmentAll',
        component: AppointmentAll,
    },
    {
        path: '/viewlistappointmentall',
        name: 'viewListAppointmentAll',
        component: ViewListAppointmentAll,
    },
    {
        path: '/addappointment',
        name: 'addAppointment',
        component: AddAppointment,
    },
    {
        path: '/addappointmentexcel',
        name: 'addAppointmentExcel',
        component: AddAppointmentExcel,
    },
    {
        path: '/detailappointment/:id',
        name: 'detailAppointment',
        component: DetailAppointment,
    },
    {
        path: '/editappointment/:id',
        name: 'editAppointment',
        component: EditAppointment,
    },

    // สาระน่ารู้
    {
        path: '/contentknowledge',
        name: 'contentKnowledge',
        component: ContentKnowledge,
    },
    {
        path: '/addcontentknowledge',
        name: 'addContentKnowledge',
        component: AddContentKnowledge,
    },
    {
        path: '/editcontentknowledge/:id',
        name: 'editContentKnowledge',
        component: EditContentKnowledge,
    },
    {
        path: '/detailcontentknowledge/:id',
        name: 'detailContentKnowledge',
        component: DetailContentKnowledge,
    },
    // ตั้งค่าเกี่ยวกับโรงพยาบาล
    {
        path: '/settinghospital',
        name: 'settinghospital',
        component: SettingHospital,
    },
    {
        path: '/ambulancequeueall',
        name: 'ambulancequeueall',
        component: Ambulancequeueall,
    },
    {
        path: '/destinationall',
        name: 'destinationall',
        component: Destinationall,
    },
    // จองคิวรถพยาบาล
    {
        path: '/ambulancequeuebookall',
        name: 'ambulancequeuebookall',
        component: Ambulancequeuebook,
    },
    {
        path: '/ambulancequeuebookadd',
        name: 'ambulancequeuebookadd',
        component: Ambulancequeuebookadd,
    },
    {
        path: '/ambulancecheckqueue',
        name: 'ambulancecheckqueue',
        component: Ambulancecheckqueue,
    },
    {
        path: '/ambulancequeuebookedit/:id',
        name: 'ambulancequeuebookedit',
        component: Ambulancequeuebookedit,
    },
]

