import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    getDestination() {
        return new Promise((resolve, reject) => {
            HTTP.get(`/dropoff/dropoffList`, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    getDetinationDetail(id) {
        return new Promise((resolve, reject) => {
            HTTP.get(`/dropoff/dropoffList_edit/${id}`, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    saveData(data) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/dropoff/dropoffSave`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    updateData(id, data) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/dropoff/dropoffUpdate/${id}`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    deleteDestination(id, data = {}) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/dropoff/dropoffDelete/${id}`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
}