<template>
    <div class="pa-6">
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <div class="d-flex align-center mb-3">
            <div class="heading">สาระน่ารู้</div>
            <div>
                <v-icon size="24">mdi-chevron-right</v-icon>
            </div>
            <div class="sub_heading" v-if="base64Decode($route.params.category_content_id) == 1">
                งานเบาหวานและความดันโลหิต
            </div>
            <div class="sub_heading" v-if="base64Decode($route.params.category_content_id) == 2">
                งานวางแผนครอบครัว
            </div>
            <div class="sub_heading" v-if="base64Decode($route.params.category_content_id) == 3">
                งานฝากครรภ์
            </div>
            <div class="sub_heading" v-if="base64Decode($route.params.category_content_id) == 4">
                งานสุขภาพเด็กดี
            </div>
            <div class="sub_heading" v-if="base64Decode($route.params.category_content_id) == 5">
                งานทันตกรรม
            </div>
            <div class="sub_heading" v-if="base64Decode($route.params.category_content_id) == 6">
                งานส่งเสริมสุขภาพป้องกันโรค
            </div>
            <div class="sub_heading" v-if="base64Decode($route.params.category_content_id) == 7">
                งานเภสัชกรรม
            </div>
        </div>
        <div class="title_heading mb-3" v-if="base64Decode($route.params.category_content_id) == 1">
            งานเบาหวานและความดันโลหิต
        </div>
        <div class="title_heading mb-3" v-if="base64Decode($route.params.category_content_id) == 2">
            งานวางแผนครอบครัว
        </div>
        <div class="title_heading mb-3" v-if="base64Decode($route.params.category_content_id) == 3">
            งานฝากครรภ์
        </div>
        <div class="title_heading mb-3" v-if="base64Decode($route.params.category_content_id) == 4">
            งานสุขภาพเด็กดี
        </div>
        <div class="title_heading mb-3" v-if="base64Decode($route.params.category_content_id) == 5">
            งานทันตกรรม
        </div>
        <div class="title_heading mb-3" v-if="base64Decode($route.params.category_content_id) == 6">
            งานส่งเสริมสุขภาพป้องกันโรค
        </div>
        <div class="title_heading mb-3" v-if="base64Decode($route.params.category_content_id) == 7">
            งานเภสัชกรรม
        </div>
        <div class="mb-3">
            <v-text-field class="input_search_line_oa" v-model="search" @keyup="searchData()" placeholder="ค้นหาหัวข้อที่ท่านสนใจ" append-icon="mdi-magnify" hide-details="auto" outlined required></v-text-field>
        </div>
        <div>
            <v-card class="card_line_oa card_all_contentknowledge d-flex mb-5" v-for="(detail,index) in data" :key="index">
                <div class="image_contentknowledge mr-1">
                    <img v-if="detail.content_picture" :src="detail.content_picture">
                    <img v-else :src="imagePreviewURL">
                </div>
                <div class="card_detail_contentknowledge d-flex flex-column justify-space-around align-center ml-1">
                    <div class="mb-3">
                        {{ detail.content_name }}
                    </div>
                    <v-btn class="white--text button_contentknowledge" @click="$router.push('/lineoa/detailcontentknowledge/'+base64Encode(detail.id))" type="button" height="43" width="100%">อ่านต่อ</v-btn>
                </div>
            </v-card>
        </div>
        <v-pagination class="pagination_data_list_line_oa" prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="page" color="#4EACDF" :length="pages"></v-pagination>
    </div>
</template>

<script>
import { base64Encode,base64Decode } from '@/helpers/base64.js'
import contentknowledge from '@/api/line_oa/contentknowledge/contentknowledge.js'
export default {
    data(){
        return{
            loading_page: true,
            search: '',
            data: [],
            page: 1,
            size: 3,
            pages:1,
        }
    },
    methods:{
        base64Encode(id){
            return base64Encode(id)
        },
        base64Decode(id){
            return base64Decode(id)
        },
        async contentKnowledgeList(){
            this.loading_page = true
            this.data = []
            contentknowledge.contentKnowledgeList(this.page,this.size,this.base64Decode(this.$route.params.category_content_id),this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.contentList
                    this.pages = response.data.pages
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async searchData(){
            this.data = []
            this.page = 1
            contentknowledge.contentKnowledgeList(this.page,this.size,this.base64Decode(this.$route.params.category_content_id),this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.contentList
                    this.pages = response.data.pages
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        }
    },
    mounted(){
        this.contentKnowledgeList()
    },
    watch:{
        page(){
            this.contentKnowledgeList()
        },
        $route() {
            this.contentKnowledgeList()
        },
    }
}
</script>

<style scoped>
.heading{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #656565;
}

.sub_heading{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #656565;
}

.title_heading{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #0F2248;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card_all_contentknowledge{
    padding: 30px 15px;
}

.image_contentknowledge{
    width: 50%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
    overflow: hidden;
}

.image_contentknowledge > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.card_detail_contentknowledge{
    width: 50%;
}

.card_detail_contentknowledge > div:first-child{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #0F2248;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.button_contentknowledge{
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    background: linear-gradient(90deg, #151B54 0%, #4495C1 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px!important;
}
</style>