<template>
  <div>
    <Heading heading="รายละเอียดข้อมูลประชาชน" sub_heading="ประชาชน" sub_secondary_heading="รายการประชาชน"/>
    <DetailPeopleList/>
  </div>
</template>
  
<script>
  import Heading from '@/components/heading/Heading'
  import DetailPeopleList from '@/components/manage_people/manage_people/DetailPeopleList'
  export default {
    name: 'detailPeople',
    components:{
      Heading,
      DetailPeopleList
    }
  }
  </script>