<template>
    <div>
      <Heading heading="ตั้งค่าจุดหมาย" sub_heading="ตั้งค่าจุดหมาย" />
      <DestinationList />
    </div>
  </template>
    
  <script>
  import Heading from '@/components/heading/Heading'
  import DestinationList from '@/components/hospital/destination/Destinationall'
  export default {
    name: 'destinationList',
    components: {
      Heading,
      DestinationList
    }
  }
  </script>