<template>
    <div>
        
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        
        <div class="card_heading_page_detail_data d-flex align-center">
            <div class="card_text_heading_data">จุดหมายที่รถพยาบาลไปส่ง</div>
        </div>
        <div class="card_data_list">
            <v-row>
                <v-col cols="12" xs="12" md="12" lg="3" xl="3" style="border-right: 1px solid #DADADA;" v-if="status == 1">
                    <div class="text_title mb-8">
                        <div class="image_title">
                            <img src="@/assets/images/destination/day-off 1.png">
                        </div>
                        <div class="ml-2 mr-10">เพิ่มจุดหมาย</div>
                    </div>
                    <v-form ref="form" @submit.prevent="saveData()">
                        <div class="detail_title">
                            <div>ชื่อจุดหมาย <span class="error--text">*</span></div>
                            <v-text-field class="input_detail_title" :rules="[v => !!v || 'กรุณากรอกชื่อจุดหมาย']" v-model="name_drop_off"
                                label="กรุณากรอกชื่อจุดหมาย" solo required></v-text-field>
                        </div>
                        <div class="text-center">
                            <v-btn class="white--text button_save" type="submit" height="43" width="50%"
                                color="#37AE46">บันทึก</v-btn>
                        </div>
                    </v-form>
                </v-col>
                <v-col cols="12" xs="12" md="12" lg="3" xl="3" style="border-right: 1px solid #DADADA;" v-else>
                    <div class="text_title mb-8">
                        <div class="image_title">
                            <img src="@/assets/images/destination/day-off 1.png">
                        </div>
                        <div class="ml-2 mr-10">แก้ไขจุดหมาย</div>
                    </div>
                    <v-form ref="form1" @submit.prevent="updateData()">
                        <div class="detail_title">
                            <div>ชื่อจุดหมาย <span class="error--text">*</span></div>
                            <v-text-field class="input_detail_title" :rules="[v => !!v || 'กรุณากรอกชื่อรอบ']" v-model="dropoffList_edit.name_drop_off"
                                label="กรุณากรอกชื่อรอบ" solo required></v-text-field>
                        </div>
                        <div class="text-center">
                            <v-btn class="white--text button_save" type="submit" height="43" width="50%"
                                color="#37AE46">บันทึก</v-btn>
                        </div>
                    </v-form>
                </v-col>
                <v-col cols="12" xs="12" md="12" lg="9" xl="9">
                    <div class="text_title mb-8">
                        <div class="ml-2 mr-10">รายการจุดหมาย</div>
                    </div>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="title_table_data_list text-center">
                                    ลำดับ
                                </th>
                                <th class="title_table_data_list">
                                    ชื่อจุดหมาย
                                </th>
                                <th class="title_table_data_list text-center">
                                    ผู้ดำเนินการ
                                </th>
                                <th class="title_table_data_list">
                                    จัดการ
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in dropoffList" :key="index">
                                <td class="detail_table_data_list text-center">{{index+1}}</td>
                                <td class="detail_table_data_list">{{item.name_drop_off}}</td>
                                <td class="detail_table_data_list text-center">{{item.first_name}} {{item.last_name}}</td>
                                <td>
                                    <v-btn class="button_tool_data_list mr-2" height="43" @click="getDetinationDetail(item.id)"
                                        color="#FD8F16"><v-icon>mdi-pencil</v-icon>
                                        <div>แก้ไข</div>
                                    </v-btn>
                                    <v-btn class="button_tool_data_list" @click="openConfirmDelete(item.id)" height="43"
                                        color="#B3001B"><v-icon>mdi-delete</v-icon>
                                        <div>ลบ</div>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-pagination class="pagination_data_list" v-model="page" color="#4EACDF"
                        :length="pages"></v-pagination>
                </v-col>
            </v-row>
            <v-dialog v-model="open_confirm_delete" persistent max-width="600px">
                <v-card class="page_confirm_delete_all">
                    <v-card-title></v-card-title>
                    <v-card-text>

                        <v-row>
                            <v-col cols="12">

                                <div class="image_confirm_delete">
                                    <img src="@/assets/images/trash_confirm_delete.jpg" alt="">
                                </div>
                                <div class="text_confirm_delete">คุณแน่ใจใช่ไหมที่จะทำการลบข้อมูล?</div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions class="layout_button_text_confirm_delete">
                        <v-btn class="button_text_confirm_delete" height="43" width="40%" color="#4EACDF" @click="deleteDestination()">ใช่ ฉันต้องการลบ
                            !</v-btn>
                        <v-btn class="button_text_confirm_delete" @click="cancelDeleteUser()" height="43" width="40%"
                            color="#414141">ยกเลิก</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { base64Encode, base64Decode } from '@/helpers/base64.js'
import destination from '@/api/hospital/destination/destination.js'
export default {
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            open_confirm_notification_all: false,
            notification_id: null,
            open_confirm_delete: false,
            delete_id: null,
            search: '',
            data: [],
            page: 1,
            size: 15,
            pages: 1,
            dropoffList:[],
            dropoffList_edit:[],
            name_drop_off: null,
            status: 1
        }
    },
    methods: {
        base64Encode(id) {
            return base64Encode(id)
        },
        base64Decode(id) {
            return base64Decode(id)
        },
        async openConfirmDelete(id) {
            this.delete_id = id
            this.open_confirm_delete = true
        },
        async cancelDeleteUser() {
            this.delete_id = null
            this.open_confirm_delete = false
        },
        async getDestination(){
             await destination.getDestination().then((response) => {
                    if (response.data.successful === true) {
                        this.dropoffList = response.data.dropoffList
                    }
             })
        },
        async getDetinationDetail(id){
             await destination.getDetinationDetail(id).then((response) => {
                    if (response.data.successful === true) {
                        this.dropoffList_edit = response.data.dropoffList_edit
                        this.status = 2
                    }
             })
        },
async saveData(){
            if(this.$refs.form.validate()){
            let data = {
                name_drop_off: this.name_drop_off,
            }
             await destination.saveData(data).then((response) => {
                    if (response.data.successful === true) {
                        this.name_drop_off = null
                        this.alert_success = true;
                         this.alert_message_success = 'เพิ่มข้อมูลเรียบร้อย';
                         setTimeout(() => this.alert_success = false, 3000)
                       this.getDestination()
                    }
             })
            }            
        },
       async updateData(){
            if(this.$refs.form1.validate()){
                 let id = this.dropoffList_edit.id;
            let data = {
                name_drop_off: this.dropoffList_edit.name_drop_off,
            }
             await destination.updateData(id, data).then((response) => {
                    if (response.data.successful === true) {
                        this.name_drop_off = null
                        this.status = 1
                        this.alert_success = true;
                         this.alert_message_success = 'แก้ไขข้อมูลเรียบร้อย';
                         setTimeout(() => this.alert_success = false, 3000)
                       this.getDestination()
                    }
             })
            }            
        },
       async deleteDestination(){
             await destination.deleteDestination(this.delete_id).then((response) => {
                    if (response.data.successful === true) {
                        this.alert_success = true;
                         this.alert_message_success = 'ลบข้อมูลเรียบร้อย';
                         setTimeout(() => this.alert_success = false, 3000)
                       this.getDestination()
                       this.cancelDeleteUser()
                    }
             })         
        }
    },
    mounted() {
        this.getDestination()
    },
    // watch: {
    //     page() {
    //         this.userList()
    //     }
    // }
}
</script>

<style scoped>
.card_data_list {
    background: #FFFFFF !important;
}

.text_title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: center;
}

.image_title {
    width: 34px;
}

.image_title>img {
    max-width: 100%;
}</style>