import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
  getDashboard() {
    return new Promise((resolve, reject) => {
      HTTP.get(`/dashboard`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getcountnotification(year, month) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/dashboard/count/noti?year=${year}&month=${month}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getcountambulance(year, month) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/dashboard/count/ambulance?year=${year}&month=${month}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },
  getcontent(year, month) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/dashboard/content?year=${year}&month=${month}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  },

  getdetailnotification(page, size, search) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/dashboard/list/people/send/noti?page=${page}&size=${size}&search=${search}`, {
        headers: {
          Authorization: `Bearer ${store.state.access_token}`,
        },
      })
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }
}