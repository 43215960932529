<template>
    <div class="pa-6">
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <div v-else>
            <div class="title_heading mb-5">ข้อมูลผู้ใช้บริการ</div>
            <div class="mb-6">
                <v-card class="card_line_oa card_all_user_information mb-3" v-for="(detail, index) in data" :key="index">
                    <div class="number_user_information text-right">{{ detail.hn }}</div>
                    <div class="name_user_information mb-3">{{ detail.people_name }}</div>
                    <div class="d-flex align-start mb-3">
                        <v-icon color="#4EACDF" size="28">mdi-home-account</v-icon>
                        <div class="address_tel_user_information ml-3">{{ `${detail.addrpa} ต.${detail.district_name}
                                                อ.${detail.amphures_name} จ.${detail.province_name}` }}</div>
                    </div>
                    <div class="d-flex align-start mb-3">
                        <v-icon color="#4EACDF" size="28">mdi-phone</v-icon>
                        <div class="address_tel_user_information ml-3">{{ detail.tel }}</div>
                    </div>
                    <div class="text-right">
                        <v-btn class="white--text button_edit_user_information" type="button"
                            @click="$router.push(`/lineoa/edituserinformation/${base64Encode(detail.id)}`)"
                            height="43">แก้ไขเบอร์โทร</v-btn>
                    </div>
                </v-card>
            </div>
            <div class="text-center">
                <v-btn class="white--text button_register_open_notify_user_information" type="button"
                    @click="$router.push('/lineoa/registeropennotify')" color="#4EACDF" height="43">
                    <v-icon>mdi-plus</v-icon>
                    <div>เพิ่มผู้ใช้บริการ</div>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import liff from '@line/liff'
import { base64Encode } from '@/helpers/base64.js'
import notify from '@/api/line_oa/notify/notify.js'
export default {
    data() {
        return {
            loading_page: true,
            token_line: null,
            data: [],
        }
    },
    methods: {
        checkUser() {
            this.loading_page = true
            this.token_line = null
            this.data = []
            liff.init({
                liffId: process.env.VUE_APP_BASE_LINE_LIFFID,
            }).then(() => {
                if (liff.isLoggedIn()) {
                    liff.getProfile().then(profile => {
                        this.token_line = profile.userId
                        const data = {
                            token_line: this.token_line
                        }
                        notify.checkPeopledetail(data).then((response) => {
                            if (response.data.successful === true) {
                                this.loading_page = false
                            } else {
                                this.$router.push('/lineoa/registeropennotify')
                            }
                        }).catch((error) => {
                            if (error.response.status === 401) {
                                this.$store.commit('logOutSuccess')
                                this.$router.push('/login')
                            }
                        })
                    })
                } else {
                    liff.login()
                }
            })
        },
        base64Encode(id) {
            return base64Encode(id)
        },
        async getDetailLineUserAndMemberList() {
            this.loading_page = true
            this.token_line = null
            this.data = []
            liff.init({
                liffId: process.env.VUE_APP_BASE_LINE_LIFFID,
            }).then(() => {
                if (liff.isLoggedIn()) {
                    liff.getProfile().then(profile => {
                        this.token_line = profile.userId
                        const data = {
                            token_line: this.token_line
                        }
                        notify.userInformationList(data).then((response) => {
                            if (response.data.successful === true) {
                                this.data = response.data.data
                                this.loading_page = false
                            }
                        }).catch((error) => {
                            if (error.response.status === 401) {
                                this.$store.commit('logOutSuccess')
                                this.$router.push('/login')
                            }
                        })
                    })
                } else {
                    liff.login()
                }
            })
        },

    },
    mounted() {
        this.checkUser()
        this.getDetailLineUserAndMemberList()
    }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Sarabun&display=swap");

.title_heading {
    font-family: 'Sarabun', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #000000;
}

.card_all_user_information {
    font-family: 'Sarabun', sans-serif;
    padding: 30px 15px;
}

.number_user_information {
    font-family: 'Sarabun', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #000000;
}

.name_user_information {
    font-family: 'Sarabun', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #4D4D4D;
}

.address_tel_user_information {
    font-family: 'Sarabun', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #4D4D4D;
}

.button_edit_user_information {
    font-family: 'Sarabun', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    background: linear-gradient(90deg, #151B54 0%, #4495C1 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px !important;
    padding: 0 25px !important;
}

.button_register_open_notify_user_information {
    font-family: 'Sarabun', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px !important;
    padding: 0 25px !important;
}
</style>