<template>
    <div>        
        <div class="card_heading_page_detail_data d-flex align-center">
            <div class="card_text_heading_data">ฟอร์มแก้ไขข้อมูลสาระน่ารู้</div>
        </div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_data_list new_card_data_list">
            <v-form ref="form" @submit.prevent="editContentKnowledge()">
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="4" xl="4">
                        <div class="image_preview">
                            <v-img v-if="imagePreviewURL" :src="imagePreviewURL" max-height="100%" max-width="100%"></v-img>
                            <div class="upload_image">
                                <v-btn v-if="!content_picture" class="white--text" color="#FD8F16" depressed fab :loading="isSelectingImage" @click="handleFileImport"><v-icon>mdi-pencil</v-icon></v-btn>
                                <v-btn v-if="content_picture" class="white--text" color="error" depressed fab @click="onFileRemove"><v-icon>mdi-minus-thick</v-icon></v-btn>
                                <v-file-input class="d-none" id="uploader" accept="image/*" v-model="content_picture" @change="onFileChange"></v-file-input>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="8" xl="8">
                        <div class="layout_detail">
                            <div class="detail_title">
                                <div>หัวข้อบทความ <span class="error--text">*</span></div>
                                <v-text-field class="input_detail_title" v-model="content_name" :rules="[v => !!v || 'กรุณากรอกหัวข้อบทความ']" label="กรุณากรอกหัวข้อบทความ" solo required></v-text-field>
                            </div>
                            <div class="detail_title">
                                <div>รายละเอียดโดยย่อ <span class="error--text">*</span></div>
                                <v-text-field class="input_detail_title" v-model="content_shot_detail" :rules="[v => !!v || 'กรุณากรอกรายละเอียดโดยย่อ',v => v.length <= 150 || 'ห้ามกรอกเกิน 150 ตัวอักษร']" :counter="150" label="กรุณากรอกรายละเอียดโดยย่อ" solo required></v-text-field>
                            </div>
                            <div class="d-flex date_between_detail">
                                <div class="detail_title">
                                    <div>วันที่เริ่มเผยแพร่ (วัน เดือน ปี) <span class="error--text">*</span></div>
                                    <v-menu ref="menu_content_start_date" v-model="menu_content_start_date" :close-on-content-click="false" :return-value.sync="content_start_date" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="input_detail_title" v-model="content_start_date_be" :rules="[v => !!v || 'กรุณาเลือกวันที่เริ่มเผยแพร่']" label="กรุณาเลือกวันที่เริ่มเผยแพร่" readonly v-bind="attrs" v-on="on" solo required></v-text-field>
                                        </template>
                                        <v-date-picker v-model="content_start_date" :max="content_end_date" no-title scrollable locale="th" :events="dateStartEndBE('content_start_date')">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="selectDate('content_start_date')">
                                                ตกลง
                                            </v-btn>
                                            <v-btn text color="primary" @click="menu_content_start_date = false">
                                                ยกเลิก
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </div>
                                <div class="detail_title d-flex justify-center align-center">
                                    <v-icon>mdi-minus-thick</v-icon>
                                </div>
                                <div class="detail_title">
                                    <div>วันที่สิ้นสุดเผยแพร่ (วัน เดือน ปี) <span class="error--text">*</span></div>
                                    <v-menu ref="menu_content_end_date" v-model="menu_content_end_date" :close-on-content-click="false" :return-value.sync="content_end_date" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="input_detail_title" v-model="content_end_date_be" :rules="[v => !!v || 'กรุณาเลือกวันที่สิ้นสุดเผยแพร่']" label="กรุณาเลือกวันที่สิ้นสุดเผยแพร่" readonly v-bind="attrs" v-on="on" solo required></v-text-field>
                                        </template>
                                        <v-date-picker v-model="content_end_date" :min="content_start_date" no-title scrollable locale="th" :events="dateStartEndBE('content_end_date')">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="selectDate('content_end_date')">
                                                ตกลง
                                            </v-btn>
                                            <v-btn text color="primary" @click="menu_content_end_date = false">
                                                ยกเลิก
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </div>
                            </div>
                            <div class="detail_title">
                                <div>หมวดสาระน่ารู้ <span class="error--text">*</span></div>
                                <v-select class="select_detail_title" v-model="category_content_id" :rules="[v => !!v || 'กรุณาเลือกหมวดสาระน่ารู้']" label="กรุณาเลือกหมวดสาระน่ารู้" :items="category_list" item-text="category_content_name" item-value="id" solo required></v-select>
                            </div>
                        </div>

                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="layout_description">
                            <div class="detail_title">
                                <div>รายละเอียด <span class="error--text">*</span></div>
                                <textarea id="summernote" v-model="content_detail"></textarea>
                                <!-- var number = document.getElementById("summernote").value; -->
                            </div>
                        </div>
                        <div class="text-center">
                            <v-btn class="white--text button_save" type="submit" height="43" width="15%" color="#37AE46">บันทึก</v-btn>
                        </div>
                        
                    </v-col>
                </v-row>
            </v-form>
        </div>
        <v-dialog v-model="open_edit_success" persistent max-width="600px">
            <v-card class="page_confirm_success_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                            <!-- <div class="image_confirm_success">
                                <img src="@/assets/images/trash_confirm_delete.jpg" alt="">
                            </div> -->
                            <div class="text-center">
                                <v-icon size="200" color="success">mdi-check-circle</v-icon>
                            </div>
                            <div class="text_confirm_success">สำเร็จแก้ไขข้อมูลสาระน่ารู้เรียบร้อยแล้ว</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_success">
                    <v-btn class="button_text_confirm_success" @click="$router.push('/contentknowledge')" height="43" width="40%" color="#4EACDF">ตกลง</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import before_image from "@/assets/images/contentknowledge/image_preview_content_knowledge.png"
import { base64Decode } from '@/helpers/base64.js'
import { monthList } from '@/helpers/dateformats.js'
import contentknowledge from '@/api/hospital/contentknowledge/contentknowledge/contentknowledge.js'
import category from '@/api/hospital/contentknowledge/category/category.js'
export default {
    data(){
        return{
            loading_page: true,
            alert_error: false,
            alert_message_error: '',
            open_edit_success: false,
            imagePreviewURL: before_image,
            oldImage: before_image,
            isSelectingImage: false,
            category_list: [],
            content_picture: null,
            content_name: '',
            content_shot_detail: '',
            menu_content_start_date: false,
            content_start_date_be: '',
            content_start_date: '',
            menu_content_end_date: false,
            content_end_date_be: '',
            content_end_date: '',
            category_content_id: null, 
            content_detail: ''
        }
    },
    methods:{
        base64Decode(id){
            return base64Decode(id)
        },
        async handleFileImport() {
            this.isSelectingImage = true
            window.addEventListener('focus', () => {
                this.isSelectingImage = false
            }, { once: true })
            document.getElementById('uploader').click()
        },
        async onFileChange(payload) {
            const file = payload
            let extall ="png,PNG,jpeg,JPEG,jpg,JPG";
            let file_ext = file.name;
            let ext = file_ext.split('.').pop().toLowerCase();
            if (parseInt(extall.indexOf(ext)) < 0) {
                // this.imagePreviewURL = before_image
                this.imagePreviewURL = this.oldImage
                this.content_picture = null
                this.alert_message_error = 'อัพโหลดได้เฉพาะไฟล์ รูป หรือ (.png, .PNG, .jpeg, .JPEG, .jpg, .JPG) เท่านั้น'
                this.alert_error = true
                setTimeout(() => this.alert_error = false, 5000)
            }else{
                if (file) {
                    this.imagePreviewURL = URL.createObjectURL(file)
                    URL.revokeObjectURL(file)
                } else {
                    // this.imagePreviewURL = before_image
                    this.imagePreviewURL = this.oldImage
                }
            }
        },
        async onFileRemove() {
            this.content_picture = null
            // this.imagePreviewURL = before_image
            this.imagePreviewURL = this.oldImage
        },
        dateStartEndBE(type) {
            if (type == 'content_start_date') {
                if (this.content_start_date == '') {
                    this.content_start_date_be = ''
                }
                if (this.content_start_date) {
                    let date_arr = this.content_start_date.split('-')
                    let year = parseInt(date_arr[0]) + 543
                    let month = parseInt(date_arr[1])
                    month = monthList().find((detail) => { return detail.id == month }).name_short
                    this.content_start_date_be = `${date_arr[2]} ${month} ${year}`
                }
            }
            if (type == 'content_end_date') {
                if (this.content_end_date == '') {
                    this.content_end_date_be = ''
                }
                if (this.content_end_date) {
                    let date_arr = this.content_end_date.split('-')
                    let year = parseInt(date_arr[0]) + 543
                    let month = parseInt(date_arr[1])
                    month = monthList().find((detail) => { return detail.id == month }).name_short
                    this.content_end_date_be = `${date_arr[2]} ${month} ${year}`
                }
            }
        },
        async selectDate(type) {
            if (type == 'content_start_date') {
                this.$refs.menu_content_start_date.save(this.content_start_date)
                let date_arr = this.content_start_date.split('-')
                let year = parseInt(date_arr[0]) + 543
                let month = parseInt(date_arr[1])
                month = monthList().find((detail) => { return detail.id == month }).name_short
                this.content_start_date_be = `${date_arr[2]}/${month}/${year}`
                this.menu_content_start_date = false
            }
            if (type == 'content_end_date') {
                this.$refs.menu_content_end_date.save(this.content_end_date)
                let date_arr = this.content_end_date.split('-')
                let year = parseInt(date_arr[0]) + 543
                let month = parseInt(date_arr[1])
                month = monthList().find((detail) => { return detail.id == month }).name_short
                this.content_end_date_be = `${date_arr[2]}/${month}/${year}`
                this.menu_content_end_date = false
            }
        },
        async selectDataAllAndContentKnowledgeDetail(){
            this.loading_page = true
            this.category_list = []
            await category.categoryList().then((response) => {
                if (response.data.successful === true) {
                    this.category_list = response.data.contentCatgoryList
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
            this.imagePreviewURL = before_image
            this.oldImage = before_image
            this.content_picture = null
            this.content_name = ''
            this.content_shot_detail = ''
            this.content_start_date = ''
            this.content_end_date = ''
            this.category_content_id = null
            this.content_detail = ''
            await contentknowledge.contentKnowledgeDetail(this.base64Decode(this.$route.params.id)).then((response) => {
                if (response.data.successful === true) {
                    if (response.data.contentDetail.content_picture) {
                        this.imagePreviewURL = response.data.contentDetail.content_picture
                        this.oldImage = response.data.contentDetail.content_picture
                    }
                    this.content_name = response.data.contentDetail.content_name
                    this.content_shot_detail = response.data.contentDetail.content_shot_detail
                    this.content_start_date = response.data.contentDetail.content_start_date
                    this.content_end_date = response.data.contentDetail.content_end_date
                    this.category_content_id = response.data.contentDetail.category_content_id
                    document.getElementsByClassName("note-editable")[0].innerHTML = response.data.contentDetail.content_detail
                    this.content_detail = response.data.contentDetail.content_detail
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async editContentKnowledge(){
            if (this.$refs.form.validate()){
                if (!document.getElementById("summernote").value) {
                    this.alert_message_error = 'กรุณากรอกรายละเอียด'
                    this.alert_error = true
                    setTimeout(() => this.alert_error = false, 5000)
                }
                if (document.getElementById("summernote").value) {
                    this.loading_page = true

                    let data = new FormData()
                    data.append('content_picture', this.content_picture)
                    data.append('content_name', this.content_name)
                    data.append('content_shot_detail', this.content_shot_detail)
                    data.append('content_start_date', this.content_start_date)
                    data.append('content_end_date', this.content_end_date)
                    data.append('category_content_id', this.category_content_id)
                    data.append('content_detail', document.getElementById("summernote").value)
                    contentknowledge.editContentKnowledge(this.base64Decode(this.$route.params.id),data).then((response) => {
                        if (response.data.successful === true) {
                            this.loading_page = false
                            this.open_edit_success = true
                        }
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            this.$store.commit('logOutSuccess')
                            this.$router.push('/login')
                        }
                    })
                }
           }
        },
    },
    mounted(){
        this.selectDataAllAndContentKnowledgeDetail()
    }
}
</script>

<style scoped>
.new_card_data_list{
    padding: 60px 30px 30px;
}

.image_preview{
    margin-top: 15px;
    position: relative;
    width: 100%;
}

.upload_image{
    position: absolute;
    top: -25px;
    right: -20px;
}

.detail_title{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #414141;
}

.detail_title > div:first-child{
    margin-bottom: 10px;
}

.input_detail_title{
    border-radius: 10px;
}

.select_detail_title{
    border-radius: 10px;
}

.layout_detail{
    padding-left: 15px;
}

.layout_detail > div{
    width: 100%;
}

.layout_detail > div:last-child{
    width: 40%;
}

.date_between_detail > div{
    width: 40%;
}

.date_between_detail > div:nth-child(2){
    width: 10%;
}

.layout_description > div{
    width: 100%;
}

.button_save{
    border: 1px solid #37AE46;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}

@media only screen and (max-width: 1000px) {
    .image_preview{
        margin-top: 0;
    }

    .layout_detail{
        padding-left: 0;
    }

    .layout_detail > div:last-child{
        width: 100%;
    }

    .date_between_detail > div{
        width: 100%;
    }

    .date_between_detail > div:nth-child(2){
        width: 100%;
    }
}
</style>