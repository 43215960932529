<template>
    <div>
        <div class="card_heading_data d-flex justify-space-between align-center">
            <div class="card_text_heading_data mtb-10">ตารางการจองรถพยาบาล</div>
            <div class="card_search_heading_data">
                <!-- <v-text-field class="input_search" v-model="search" @keyup="searchData()" label="ค้นหา"
                    prepend-inner-icon="mdi-magnify" hide-details="auto" solo required></v-text-field> -->
            </div>
        </div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success
            }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_data_list">
            <v-row>
                <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="d-flex align-baseline" style="padding: 0px 16px;">
                        <div class="image_title mr-3">
                            <img src="@/assets/images/ambulancequeue/day-off 2.png">
                        </div>
                        <v-select class="input_detail_title" v-model="round_id" :items="round_list" item-text="round"
                            @change="s = 0, e = 0, getAmbulanceQueueList()" item-value="id" solo required>
                        </v-select>
                        <div class="d-flex align-center ml-3" v-if="round_id == 2">
                            <v-btn icon color="depressed" @click="s = s - 1, e = e - 1, getAmbulanceQueueList()">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <div class="text_title">{{ date_data.day }} {{ getMonth(date_data.month) }} {{ date_data.year + 543 }} -
                                {{ date_data.day1 }} {{ getMonth(date_data.month1) }} {{ date_data.year1 + 543 }}</div>
                            <v-btn icon color="depressed" @click="s = s + 1, e = e + 1, getAmbulanceQueueList()">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                        <div class="d-flex align-center ml-3" v-if="round_id == 1">
                            <v-btn icon color="depressed" @click="s = s - 7, e = e - 7, getAmbulanceQueueList()">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <div class="text_title">{{ date_data.day }} {{ getMonth(date_data.month) }} {{ date_data.year + 543 }} -
                                {{ date_data.day1 }} {{ getMonth(date_data.month1) }} {{ date_data.year1 + 543 }}</div>
                            <v-btn icon color="depressed" @click="s = s + 7, e = e + 7, getAmbulanceQueueList()">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-col>
                <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="text-right">
                        <v-btn @click="printPDF()" class="button_heading" height="62"><v-icon>mdi-printer-outline</v-icon>
                            <div class="button_text_heading">พิมพ์ตารางคิว</div>
                        </v-btn>
                    </div>
                </v-col>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="title_table_data_list">
                                    วันที่
                                </th>
                                <th class="title_table_data_list" v-for="(item, index) in header" :key="index">
                                    {{ item.name_round }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in dataList" :key="index">
                                <td class="detail_table_data_list"><b>{{ item.day_th }}</b></td>
                                <td class="detail_table_data_list" v-for="(sub, x) in item.que_list" :key="x">
                                    <v-row v-if="sub.que_list.length != 0">
                                        <v-col cols="12">
                                            <v-card class="mb-3" max-width="500"
                                                :color="item.day_th.split(' ')[0] == 'จันทร์' ? '#FFF6DE' : item.day_th.split(' ')[0] == 'อังคาร' ? '#FFECFF' : item.day_th.split(' ')[0] == 'พุธ' ? '#EBFFE2' : item.day_th.split(' ')[0] == 'พฤหัสบดี' ? '#FFDBC7' : item.day_th.split(' ')[0] == 'ศุกร์' ? '#EFF9FF' : item.day_th.split(' ')[0] == 'เสาร์' ? '#F9EFFF' : '#FFD4D4'"
                                                style="border-radius:20px" v-for="(detail, i) in sub.que_list" :key="i">
                                                <v-card-text>
                                                    <div style="float: right;">
                                                        <v-menu offset-y>
                                                            <template v-slot:activator="{ attrs, on }">
                                                                <v-btn icon v-bind="attrs" v-on="on">
                                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                                </v-btn>
                                                            </template>

                                                            <v-list>
                                                                <v-list-item link
                                                                    @click="link_redirect('/ambulancequeuebookedit/' + detail.id)">
                                                                    <v-list-item-title><v-icon>mdi-pencil-outline</v-icon>
                                                                        แก้ไขคิว</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item link @click="openConfirmDelete(detail.id);">
                                                                    <v-list-item-title><v-icon>mdi-close-circle-outline</v-icon>
                                                                        ยกเลิกคิว</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </div>
                                                    <div class="text--primary" style="font-size:16px;">
                                                        <b>
                                                            {{ detail.full_name }} ({{ detail.patient == '0' ?
                                                                'ผู้ป่วยติดเตียง' : 'ผู้ป่วยปกติ' }})<br>
                                                            สถานที่ไปรับ: {{ detail.pick_up_at == null ?
                                                                'โรงพยาบาลหนองป่าครั่ง' : detail.pick_up_at }} <br>
                                                            เบอร์โทร: {{ detail.tel }}<br>
                                                            จุดหมาย: {{ detail.name_drop_off }}<br>
                                                            หมายเหตุ: {{ detail.note == null ? '-' : detail.note }}
                                                        </b>
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12">
                                            <v-card class="mb-3" max-width="500" color="#CCCCCC" style="border-radius:20px">
                                                <v-card-text>
                                                    <div class="text--primary" style="font-size:16px;">
                                                        <b>
                                                            ว่าง
                                                        </b>
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <!-- <v-pagination class="pagination_data_list" v-model="page" color="#4EACDF"
                        :length="pages"></v-pagination> -->
                </v-col>
            </v-row>

        </div>
        <v-dialog v-model="open_confirm_delete" persistent max-width="600px">
            <v-card class="page_confirm_delete_all">
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <div class="image_confirm_delete mb-5">
                                <img src="@/assets/images/ambulancequeue/image 206.png" alt="">
                            </div>
                            <div class="text_confirm_delete">คุณแน่ใจใช่ไหมที่จะยกเลิกการจองคิวรถพยาบาล??</div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_delete">
                    <v-btn class="button_text_confirm_delete" @click="getDelete()" height="43" width="40%"
                        color="#4EACDF">ใช่ ฉันต้องการยกเลิก !</v-btn>
                    <v-btn class="button_text_confirm_delete" @click="open_confirm_delete = false" height="43" width="40%"
                        color="#414141">ยกเลิก</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { base64Encode, base64Decode } from '@/helpers/base64.js'
import ambulancequeue from '@/api/hospital/ambulancequeue/ambulancequeue.js'
export default {
    data() {
        return {
            loading_page: false,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            open_confirm_delete: false,
            delete_id: null,
            search: '',
            data: [],
            page: 1,
            size: 15,
            pages: 1,
            setMax: new Date().toLocaleString(),
            round_list: [
                { id: 1, round: 'รายสัปดาห์' },
                { id: 2, round: 'รายวัน' },
            ],
            round_id: 1,
            header: [],
            dataList: [],
            date_data: {
                day: null,
                month: null,
                year: null,
                day1: null,
                month1: null,
                year1: null,
            },
            firstRound: 1,
            s: 0,
            e: 0,
            start_date: null,
            end_date: null,
        }
    },
    methods: {
        printPDF() {
            window.open(`ambulancecheckqueue?start_date= ${this.start_date}&&end_date= ${this.end_date}`, '_blank')
        },
        getMonth(i) {
            switch (i) {
                case '01': return 'มกราคม';
                case '02': return 'กุมภาพันธ์';
                case '03': return 'มีนาคม';
                case '04': return 'เมษายน';
                case '05': return 'พฤษภาคม';
                case '06': return 'มิถุนายน';
                case '07': return 'กรกฎาคม';
                case '08': return 'สิงหาคม';
                case '09': return 'กันยายน';
                case '10': return 'ตุลาคม';
                case '11': return 'พฤศจิกายน';
                case '12': return 'ธันวาคม';
            }
        },
        base64Encode(id) {
            return base64Encode(id)
        },
        base64Decode(id) {
            return base64Decode(id)
        },
        async link_redirect(link) {
            window.location.href = link
        },

        async openConfirmDelete(id) {
            this.delete_id = id
            this.open_confirm_delete = true
        },
        async getDelete() {
            await ambulancequeue.getDelete(this.delete_id).then((response) => {
                if (response.data.successful === true) {
                    this.alert_success = true;
                    this.alert_message_success = 'ลบข้อมูลเรียบร้อย';
                    setTimeout(() => { this.alert_success = false, window.location.reload() }, 3000)

                }
            })
        },
        async getAmbulanceQueueList() {
            let start_date = null
            let end_date = null
            if (this.round_id == 1) {
                var currentDate = new Date();

                // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
                var currentDayOfWeek = currentDate.getDay();

                // Calculate the difference in days to the first day of the week (Sunday)
                var daysUntilFirstDayOfWeek = currentDayOfWeek === 0 ? 0 : -currentDayOfWeek + 1;

                // Calculate the date of the first day of the week
                var firstDayOfWeek = new Date(currentDate);
                firstDayOfWeek.setDate(currentDate.getDate() + daysUntilFirstDayOfWeek + this.s);
                let fristDay = firstDayOfWeek.getDate().toString().padStart(2, '0');
                let firstyear = firstDayOfWeek.getFullYear();
                let firstMonth = (firstDayOfWeek.getMonth() + 1).toString().padStart(2, '0');
                this.date_data.day = fristDay
                this.date_data.month = firstMonth
                this.date_data.year = firstyear
                start_date = `${firstyear}-${firstMonth}-${fristDay}`;
                //end
                var firstDayOfWeek1 = new Date(currentDate);
                firstDayOfWeek1.setDate(currentDate.getDate() + 4 + this.e);
                let endDay = firstDayOfWeek1.getDate().toString().padStart(2, '0');
                let endyear = firstDayOfWeek1.getFullYear();
                let endMonth = (firstDayOfWeek1.getMonth() + 1).toString().padStart(2, '0');
                this.date_data.day1 = endDay
                this.date_data.month1 = endMonth
                this.date_data.year1 = endyear
                end_date = `${endyear}-${endMonth}-${endDay}`;
            } else {
                var currentDate1 = new Date();

                // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
                var currentDayOfWeek1 = currentDate1.getDay();

                // Calculate the difference in days to the first day of the week (Sunday)
                var daysUntilFirstDayOfWeek1 = currentDayOfWeek1 === 0 ? 0 : -currentDayOfWeek1 + 1;

                // Calculate the date of the first day of the week
                var firstDayOfWeek11 = new Date(currentDate1);
                firstDayOfWeek11.setDate(currentDate1.getDate() + daysUntilFirstDayOfWeek1 + this.s);
                let fristDay11 = firstDayOfWeek11.getDate().toString().padStart(2, '0');
                let firstyear11 = firstDayOfWeek11.getFullYear();
                let firstMonth11 = (firstDayOfWeek11.getMonth() + 1).toString().padStart(2, '0');
                this.date_data.day = fristDay11
                this.date_data.month = firstMonth11
                this.date_data.year = firstyear11
                start_date = `${firstyear11}-${firstMonth11}-${fristDay11}`;
                //end
                var firstDayOfWeek111 = new Date(currentDate1);
                firstDayOfWeek111.setDate(currentDate1.getDate() + daysUntilFirstDayOfWeek1 + this.e);
                let endDay11 = firstDayOfWeek111.getDate().toString().padStart(2, '0');
                let endyear11 = firstDayOfWeek111.getFullYear();
                let endMonth11 = (firstDayOfWeek111.getMonth() + 1).toString().padStart(2, '0');
                this.date_data.day1 = endDay11
                this.date_data.month1 = endMonth11
                this.date_data.year1 = endyear11
                end_date = `${endyear11}-${endMonth11}-${endDay11}`;
            }
            this.start_date = start_date
            this.end_date = end_date
            let data = {
                start_date: start_date,
                end_date: end_date
            }
            await ambulancequeue.getAmbulanceQueueList(data).then((response) => {
                if (response.data.successful === true) {
                    this.header = response.data.data.roundList
                    this.dataList = response.data.data.dayList
                    this.loading_page = false;

                }
            })
        },

    },
    mounted() {
        this.getAmbulanceQueueList()
    },
    watch: {
        page() {

        }
    }
}
</script>
<style scoped>
.button_heading {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 21px;
    color: #FFFFFF !important;
    background: linear-gradient(90deg, #4597C3 0%, #49A1D0 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    padding: 0 25px !important;
}

.button_text_heading {
    margin-left: 5px;
}

.card_data_list {
    background: #FFFFFF !important;
}

.text_title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #151B54;
    display: flex;
    align-items: center;
}

.image_title {
    width: 34px;
}

.image_title>img {
    max-width: 100%;
}

.input_detail_title {
    border-radius: 10px;
    max-width: 30% !important;
}

@media only screen and (max-width: 800px) {
    .button_heading {
        width: 100%;
    }
}</style>