<template>
    <div class="pa-6">
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <div class="d-flex align-center mb-3">
            <div class="heading">สาระน่ารู้</div>
            <div>
                <v-icon size="24">mdi-chevron-right</v-icon>
            </div>
            <div class="sub_heading">งานเภสัชกรรม</div>
        </div>
        <div class="title_heading mb-3">
            {{ content_name }}
        </div>
        <div class="image_detail_contentknowledge mb-3">
           <img v-if="imagePreviewURL" :src="imagePreviewURL">
        </div>
        <div class="short_description_detail_contentknowledge">
            {{ content_shot_detail }}
        </div>
        <div class="description_detail_contentknowledge" v-html="content_detail"></div>
        <div class="text-center mt-3">
            <v-btn @click="$router.go(-1)" class="white--text button_detail_contentknowledge" type="button" height="43" width="70%">ย้อนกลับหน้าหลัก</v-btn>
        </div>
        
    </div>
</template>

<script>
import before_image from "@/assets/images/contentknowledge/image_preview_content_knowledge.png"
import { base64Decode } from '@/helpers/base64.js'
import contentknowledge from '@/api/line_oa/contentknowledge/contentknowledge.js'
export default {
    data(){
        return{
            loading_page: true,
            imagePreviewURL: before_image,
            content_name: null,
            content_shot_detail: null,
            content_detail: null
        }
    },
    methods:{
        base64Decode(id){
            return base64Decode(id)
        },
        async contentKnowledgeDetail(){
            this.loading_page = true
            this.imagePreviewURL = before_image
            this.content_name = null
            this.content_shot_detail = null
            this.content_detail = null
            contentknowledge.contentKnowledgeDetail(this.base64Decode(this.$route.params.id)).then((response) => {
                if (response.data.successful === true) {
                    if (response.data.contentDetail.content_picture) {
                      this.imagePreviewURL = response.data.contentDetail.content_picture
                    }
                    this.content_name = response.data.contentDetail.content_name
                    this.content_shot_detail = response.data.contentDetail.content_shot_detail
                    this.content_detail = response.data.contentDetail.content_detail
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        }
    },
    mounted(){
        this.contentKnowledgeDetail()
    },
}
</script>

<style scoped>
.heading{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #656565;
}

.sub_heading{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #084D8C;
}

.title_heading{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #0F2248;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.image_detail_contentknowledge{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
    overflow: hidden;
}

.image_detail_contentknowledge > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.short_description_detail_contentknowledge{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #414141;
  text-indent: 10%;
  margin-bottom: 30px;
}

.description_detail_contentknowledge{
  margin-bottom: 30px;
}

.button_detail_contentknowledge{
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    background: linear-gradient(90deg, #151B54 0%, #4495C1 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px!important;
}
</style>