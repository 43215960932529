import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    userList: (page='',size='',search='') => {
        return HTTP.get(`/users/userList?page=${page}&size=${size}&search=${search}`, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    changeStatusUser: (id) => {
        return HTTP.post('/users/status/user/' + id, null, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    deleteUser: (id) => {
        return HTTP.post('/users/user/delete/' + id, null, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    addUser: (data) => {
        return HTTP.post('/users/user/add', data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    userDetail: (id) => {
        return HTTP.get('/users/user/detail/'+ id , {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
    editUser: (id,data) => {
        return HTTP.post('/users/user/edit/'+ id, data, {
            headers: {
                "Authorization": `Bearer ${store.state.access_token}`
            }
        })
        .then(response => response)
    },
}