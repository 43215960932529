<template>
  <div>
    <Heading heading="รายละเอียดสาระน่ารู้" sub_heading="รายการสาระน่ารู้ทั้งหมด" sub_secondary_heading="รายละเอียดสาระน่ารู้"/>
    <DetailContentKnowledgeList/>
  </div>
</template>

<script>
import Heading from '@/components/heading/Heading'
import DetailContentKnowledgeList from '@/components/hospital/contentknowledge/DetailContentKnowledgeList'
export default {
  name: 'detailContentKnowledgeList',
  components:{
    Heading,
    DetailContentKnowledgeList
  }
}
</script>