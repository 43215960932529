import { HTTP } from "@/axios.js"
import store from '@/store/index.js'
export default {
    getAmbulance() {
        return new Promise((resolve, reject) => {
            HTTP.get(`/ambulance/ambulanceList`, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    getPickUp() {
        return new Promise((resolve, reject) => {
            HTTP.get(`/pickupround/pickuproundList`, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    getPickUpDetail(id) {
        return new Promise((resolve, reject) => {
            HTTP.get(`/pickupround/pickuproundList_edit/${id}`, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    getDelete(id) {
        return new Promise((resolve, reject) => {
            HTTP.get(`/queueambulance/cancel/${id}`, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    getAmbulanceQueueList(data) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/queueambulance`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    updateAmbulance(id, data) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/ambulance/ambulanceUpdate/${id}`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    saveData(data) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/pickupround/pickuproundSave`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    updateData(id, data) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/pickupround/pickuproundUpdate/${id}`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
    deletePickUp(id, data = {}) {
        return new Promise((resolve, reject) => {
            HTTP.post(`/pickupround/pickuproundDelete/${id}`, data, {
              headers: {
                Authorization: `Bearer ${store.state.access_token}`,
              },
            })
              .then((response) => {
                const result = response;
                return resolve(result);
              })
              .catch((error) => {
                return reject(error);
              });
          });
    },
}