import Ambulancecheckqueue from '@/views/hospital/ambulancequeue/Ambulancecheckqueue.vue'


export default [
    {
        path: '/ambulancecheckqueue',
        name: 'ambulancecheckqueue',
        component: Ambulancecheckqueue,
    },
]

