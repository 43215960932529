<template>
    <div>        
        <div class="card_heading_data d-flex justify-space-between align-center">
            <div class="card_text_heading_data">รายการผู้ดูแลระบบ</div>
            <div class="card_search_heading_data">
                <v-text-field class="input_search" v-model="search" @keyup="searchData()" label="ค้นหา" prepend-inner-icon="mdi-magnify" hide-details="auto" solo required></v-text-field>
            </div>
        </div>
        <div class="loading_all_page" v-if="loading_page === true">
            <v-progress-circular indeterminate size="80" :width="8" color="#4EACDF"></v-progress-circular>
            <h2>Loading...</h2>
        </div>
        <v-slide-x-reverse-transition>
            <v-alert type="success" class="alert_all_page" v-if="alert_success === true">{{ alert_message_success }}</v-alert>
            <v-alert type="error" class="alert_all_page" v-if="alert_error === true">{{ alert_message_error }}</v-alert>
        </v-slide-x-reverse-transition>
        <div class="card_data_list">
             <v-simple-table>
                <thead>
                    <tr>
                        <th class="title_table_data_list">
                            รหัส
                        </th>
                        <th class="title_table_data_list">
                            ชื่อ - สกุล
                        </th>
                        <th class="title_table_data_list">
                            ตำแหน่ง
                        </th>
                        <th class="title_table_data_list" >
                            สถานะ
                        </th>
                        <th class="title_table_data_list">
                            จัดการ
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(detail,index) in data" :key="index">
                        <td class="detail_table_data_list">{{ detail.code_id }}</td>
                        <td class="detail_table_data_list">{{ detail.full_name }}</td>
                        <td class="detail_table_data_list">{{ detail.position_name }}</td>
                        <td>
                            <v-switch class="d-inline-block" color="#4CAF50" v-model="detail.status" @click="changeStatus(base64Encode(detail.id))" inset></v-switch>
                        </td>
                        <td>
                            <v-btn class="button_tool_data_list mr-2" @click="$router.push('/editadmin/'+base64Encode(detail.id))" height="43" color="#FD8F16"><v-icon>mdi-pencil</v-icon><div>แก้ไข</div></v-btn>
                            <v-btn class="button_tool_data_list"  @click="openConfirmDelete(base64Encode(detail.id))" height="43" color="#B3001B"><v-icon>mdi-delete</v-icon><div>ลบ</div></v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-pagination class="pagination_data_list" v-model="page" color="#4EACDF" :length="pages"></v-pagination>
        </div>
        <v-dialog v-model="open_confirm_delete" persistent max-width="600px">
            <v-card class="page_confirm_delete_all">
                <v-card-title></v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col cols="12">
                        
                            <div class="image_confirm_delete">
                                <img src="@/assets/images/trash_confirm_delete.jpg" alt="">
                            </div>
                            <div class="text_confirm_delete">คุณแน่ใจใช่ไหมที่จะทำการลบข้อมูล?</div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="layout_button_text_confirm_delete">
                    <v-btn class="button_text_confirm_delete" @click="deleteAdmin()" height="43" width="40%" color="#4EACDF">ใช่ ฉันต้องการลบ !</v-btn>
                    <v-btn class="button_text_confirm_delete" @click="cancelDeleteAdmin()" height="43" width="40%" color="#414141">ยกเลิก</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>      

    </div>
</template>

<script>
import { base64Encode, base64Decode } from '@/helpers/base64.js'
import admins from '@/api/manage_users/manage_admins/admins.js'
export default {
    data(){
        return{
            loading_page: true,
            alert_success: false,
            alert_message_success: '',
            alert_error: false,
            alert_message_error: '',
            open_confirm_delete: false,
            delete_id: null,
            search: '',
            data: [],
            page:1,
            size: 15,
            pages:1,
        }
    },
    methods:{
        base64Encode(id){
            return base64Encode(id)
        },
        base64Decode(id){
            return base64Decode(id)
        },
        async adminList(){
            this.loading_page = true
            this.data = []
            admins.adminList(this.page,this.size,this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.adminList
                    this.pages = response.data.pages
                    this.loading_page = false
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async changeStatus(id){
            const status_id = this.base64Decode(id)
            admins.changeStatusAdmin(status_id).then((response) => {
                if (response.data.successful === true) {
                    this.alert_message_success = 'เปลี่ยนสถานะเรียบร้อยแล้ว'
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async openConfirmDelete(id){
            this.delete_id = id
            this.open_confirm_delete = true
        },
        async deleteAdmin(){
            this.open_confirm_delete = false
            this.loading_page = true
            admins.deleteAdmin(this.base64Decode(this.delete_id)).then((response) => {
                if (response.data.successful === true) {
                    this.page = 1
                    this.size = 4
                    this.pages = 1
                    this.adminList()
                    this.alert_message_success = 'ลบผู้ดูแลเรียบร้อยแล้ว'
                    this.alert_success = true
                    setTimeout(() => this.alert_success = false, 5000)
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        },
        async cancelDeleteAdmin(){
            this.delete_id = null
            this.open_confirm_delete = false
        },
        async searchData(){
            this.data = []
            this.page = 1
            admins.adminList(this.page,this.size,this.search).then((response) => {
                if (response.data.successful === true) {
                    this.data = response.data.adminList
                    this.pages = response.data.pages
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$store.commit('logOutSuccess')
                    this.$router.push('/login')
                }
            })
        }
    },
    mounted(){
        this.adminList()
    },
    watch:{
        page(){
            this.adminList()
        }
    }
}
</script>